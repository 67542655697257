import React from 'react';
import SCSS from '../Rentals.module.scss';

const Building47 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'disable':
				classes = SCSS.Disable;
				break;
			default:
				classes = SCSS.Availabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-47'>
			<g id='UNIT-349_1_'>
				<rect
					x='1155.29'
					y='168.63'
					className={SCSS.UnitBase}
					width='28.26'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('349 B')}
					id='B-349_1_'>
					<text
						transform='matrix(1 0 0 1 1167.2743 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1161.1833 218.8953)'
						className={SCSS.UnitNumber}>
						349
					</text>
					<circle cx='1169.42' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('349 Suite')}
					id='SUITE-349'>
					<text
						transform='matrix(1 0 0 1 1159.1824 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1169.42' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-350'>
				<rect
					x='1183.55'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.54'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('350 B')}
					id='B-350'>
					<text
						transform='matrix(1 0 0 1 1194.6786 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1188.5876 218.8953)'
						className={SCSS.UnitNumber}>
						350
					</text>
					<circle cx='1196.82' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('350 Suite')}
					id='SUITE-350'>
					<text
						transform='matrix(1 0 0 1 1186.5865 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1196.82' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-351'>
				<rect
					x='1210.09'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.91'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('351 B')}
					id='B-351'>
					<text
						transform='matrix(1 0 0 1 1221.4034 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1215.3125 218.8953)'
						className={SCSS.UnitNumber}>
						351
					</text>
					<circle cx='1223.55' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('351 Suite')}
					id='SUITE-351'>
					<text
						transform='matrix(1 0 0 1 1213.3114 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1223.55' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-352'>
				<rect
					x='1237'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.91'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('352 B')}
					id='B-352'>
					<text
						transform='matrix(1 0 0 1 1248.3091 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1242.2183 218.8953)'
						className={SCSS.UnitNumber}>
						352
					</text>
					<circle cx='1250.45' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('352 Suite')}
					id='SUITE-352'>
					<text
						transform='matrix(1 0 0 1 1240.2172 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1250.45' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-353'>
				<rect
					x='1263.91'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.91'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('353 B')}
					id='B-353'>
					<text
						transform='matrix(1 0 0 1 1275.2148 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1269.1239 218.8953)'
						className={SCSS.UnitNumber}>
						353
					</text>
					<circle cx='1277.36' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('353 Suite')}
					id='SUITE-353'>
					<text
						transform='matrix(1 0 0 1 1267.1228 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1277.36' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='RENTAL-BUILDING-7-OUTLINE'>
				<path
					d='M1290.81,168.63v66.85h-135.53v-66.85H1290.81 M1292.81,166.63h-2h-135.53h-2v2v66.85v2h2h135.53h2v-2v-66.85V166.63
				L1292.81,166.63z'
				/>
			</g>
			<g id='BUILDING-LABEL-47' className={SCSS.BuildingLabel}>
				<rect x='1195.87' y='239.78' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1201.208 246.9107)'>BUILDING 47</text>
			</g>
		</g>
	);
};

export default Building47;
