import React from 'react';
import SCSS from '../Sales.module.scss';

const Building15 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-15'>
			<g
				onClick={() => click('A2')}
				className={checkHandler('174')}
				id='A2-174'>
				<polygon
					className={SCSS.UnitBase}
					points='1051.21,892.79 1023.31,892.79 1023.31,914.63 1021.35,914.63 1021.35,927.03 1023.31,927.03 
				1023.31,943.41 1025.22,943.41 1025.22,945.6 1039.75,945.6 1039.75,943.41 1051.21,943.41 			'
				/>
				<text
					transform='matrix(1 0 0 1 1033.3104 909.9083)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 1029.5277 921.2372)'
					className={SCSS.UnitNumber}>
					174
				</text>
				<circle cx='1037.76' cy='929.39' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('175')} id='A-175'>
				<polygon
					className={SCSS.UnitBase}
					points='1077.49,892.79 1051.21,892.79 1051.21,943.41 1062.08,943.41 1062.08,945.6 1075.61,945.6 
				1075.61,943.41 1077.49,943.41 			'
				/>
				<text
					transform='matrix(1 0 0 1 1061.9269 909.9083)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1056.1163 921.2372)'
					className={SCSS.UnitNumber}>
					175
				</text>
				<circle cx='1064.35' cy='929.39' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('176')} id='A-176'>
				<polygon
					className={SCSS.UnitBase}
					points='1104.49,892.79 1077.49,892.79 1077.49,943.41 1089.16,943.41 1089.16,945.6 1102.7,945.6 
				1102.7,943.41 1104.49,943.41 			'
				/>
				<text
					transform='matrix(1 0 0 1 1088.5659 909.9083)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1082.7554 921.2372)'
					className={SCSS.UnitNumber}>
					176
				</text>
				<circle cx='1090.99' cy='929.39' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('177')} id='A-177'>
				<polygon
					className={SCSS.UnitBase}
					points='1131.43,892.79 1104.49,892.79 1104.49,943.41 1106.34,943.41 1106.34,945.6 1119.87,945.6 
				1119.87,943.41 1131.43,943.41 			'
				/>
				<text
					transform='matrix(1 0 0 1 1115.5374 909.9083)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1109.7268 921.2372)'
					className={SCSS.UnitNumber}>
					177
				</text>
				<circle cx='1117.96' cy='929.39' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('178')} id='A-178'>
				<polygon
					className={SCSS.UnitBase}
					points='1158.38,892.79 1131.43,892.79 1131.43,943.41 1133.28,943.41 1133.28,945.6 1146.81,945.6 
				1146.81,943.41 1158.38,943.41 			'
				/>
				<text
					transform='matrix(1 0 0 1 1142.4805 909.9083)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1136.6699 921.2372)'
					className={SCSS.UnitNumber}>
					178
				</text>
				<circle cx='1144.9' cy='929.39' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A2')}
				className={checkHandler('179')}
				id='A2-179'>
				<polygon
					className={SCSS.UnitBase}
					points='1188.32,913.63 1185.48,913.63 1185.48,892.79 1158.38,892.79 1158.38,943.41 1169.59,943.41 
				1169.59,945.6 1183.13,945.6 1183.13,943.41 1185.48,943.41 1185.48,927.03 1188.32,927.03 			'
				/>
				<text
					transform='matrix(1 0 0 1 1167.4778 909.9083)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 1163.6951 921.2372)'
					className={SCSS.UnitNumber}>
					179
				</text>
				<circle cx='1171.93' cy='929.39' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-15-OUTLINE'>
				<path
					d='M1185.48,892.79v20.84h2.83v13.4h-2.83v16.38h-2.36v2.19h-13.53v-2.19h-22.78v2.19h-13.65v-2.19h-13.3v2.19h-13.53v-2.19
				h-3.64v2.19h-13.53v-2.19h-13.56v2.19h-13.53v-2.19h-22.32v2.19h-14.53v-2.19h-1.91v-16.38h-1.97v-12.4h1.97v-21.84H1185.48
				 M1187.48,890.79h-2h-162.17h-2v2v19.84h-1.97v2v12.4v2h1.97v14.38v2h1.91v0.19v2h2h14.53h2v-2v-0.19h18.32v0.19v2h2h13.53h2v-2
				v-0.19h9.56v0.19v2h2h13.53h2h1.64h13.53h2v-2v-0.19h9.3v0.19v2h2h13.65h2v-2v-0.19h18.78v0.19v2h2h13.53h2v-2v-0.19h0.36h2v-2
				v-14.38h0.83h2v-2v-13.4v-2h-2h-0.83v-18.84V890.79L1187.48,890.79z'
				/>
			</g>
			<g id='BUILDING-LABEL-15' className={SCSS.BuildingLabel}>
				<rect x='1077.12' y='876.29' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1082.4642 883.4219)'>BUILDING 15</text>
			</g>
		</g>
	);
};

export default Building15;
