import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import './sass/application.sass';
import SiteMap from './components/SiteMap';

const theme = createTheme({
	palette: {
		primary: {
			main: '#87b141',
		},
	},
});

function App() {
	return (
		<ThemeProvider theme={theme}>
			<div className='SiteMapContainer'>
				<SiteMap />
			</div>
		</ThemeProvider>
	);
}

export default App;
