import React from 'react';
import SCSS from '../Sales.module.scss';

const Building34 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-34_1_'>
			<g
				onClick={() => click('E1')}
				className={checkHandler('446')}
				id='E1-446'>
				<polygon
					className={SCSS.UnitBase}
					points='2184.9,284.43 2182.62,280.69 2170.31,288.21 2179.63,303.45 2176.19,305.55 2186.89,323.06 
			2190.32,320.96 2203.75,342.93 2217.26,334.68 2215.03,331.03 2219.21,328.48 2190.29,281.14 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2186.2019 304.0381)'
					className={SCSS.PlanName}>
					E1
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2187.6934 314.5663)'
					className={SCSS.UnitNumber}>
					446
				</text>
				<circle cx='2202.42' cy='320.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('447')} id='D-447'>
				<polygon
					className={SCSS.UnitBase}
					points='2193.03,274.34 2195.31,278.07 2190.29,281.14 2219.21,328.48 2223.38,325.93 2225.61,329.57 
			2238.49,321.71 2205.05,266.99 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2205.6262 292.0765)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2205.9844 303.3046)'
					className={SCSS.UnitNumber}>
					447
				</text>
				<circle cx='2220.71' cy='309.11' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('448')} id='E-448'>
				<polygon
					className={SCSS.UnitBase}
					points='2219.39,263.36 2217.11,259.62 2205.05,266.99 2238.49,321.71 2251.39,313.82 2249.17,310.18 
			2253.34,307.62 2224.42,260.29 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2223.3284 281.7169)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2223.0942 293.3109)'
					className={SCSS.UnitNumber}>
					448
				</text>
				<circle cx='2237.82' cy='299.12' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('449')} id='D-449'>
				<polygon
					className={SCSS.UnitBase}
					points='2227.51,253.26 2229.8,257 2224.42,260.29 2253.34,307.62 2257.86,304.86 2260.09,308.51 
			2273.03,300.6 2239.6,245.88 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2239.9983 271.3367)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2240.3564 282.5648)'
					className={SCSS.UnitNumber}>
					449
				</text>
				<circle cx='2255.08' cy='288.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('450')} id='E-450'>
				<polygon
					className={SCSS.UnitBase}
					points='2254.19,242.1 2251.9,238.36 2239.6,245.88 2273.03,300.6 2285.89,292.74 2283.66,289.1 
			2287.91,286.5 2258.99,239.17 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2257.7766 260.6554)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2257.5425 272.2494)'
					className={SCSS.UnitNumber}>
					450
				</text>
				<circle cx='2272.27' cy='278.06' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('451')} id='D-451'>
				<polygon
					className={SCSS.UnitBase}
					points='2262.31,232 2264.59,235.74 2258.99,239.17 2287.91,286.5 2292.36,283.78 2294.59,287.43 
			2307.34,279.64 2273.9,224.92 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2274.8181 250.3895)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2275.1763 261.6176)'
					className={SCSS.UnitNumber}>
					451
				</text>
				<circle cx='2289.9' cy='267.43' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('452')} id='E-452'>
				<polygon
					className={SCSS.UnitBase}
					points='2288.53,221.11 2286.25,217.37 2273.9,224.92 2307.34,279.64 2320.15,271.81 2317.92,268.17 
			2322.27,265.51 2293.35,218.17 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2292.749 239.4997)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2292.5149 251.0937)'
					className={SCSS.UnitNumber}>
					452
				</text>
				<circle cx='2307.24' cy='256.9' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('453')} id='D-453'>
				<polygon
					className={SCSS.UnitBase}
					points='2328.85,266.5 2341.83,258.56 2308.4,203.84 2296.66,211.02 2298.94,214.76 2293.35,218.17 
			2322.27,265.51 2326.62,262.85 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2309.0378 229.577)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2309.396 240.8051)'
					className={SCSS.UnitNumber}>
					453
				</text>
				<circle cx='2324.12' cy='246.61' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('454')} id='E-454'>
				<polygon
					className={SCSS.UnitBase}
					points='2355,250.51 2352.82,246.93 2356.35,244.77 2327.39,197.37 2322.68,200.25 2320.39,196.51 
			2308.4,203.84 2341.83,258.56 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2326.6257 218.9294)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2326.3916 230.5233)'
					className={SCSS.UnitNumber}>
					454
				</text>
				<circle cx='2341.12' cy='236.33' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('D1')}
				className={checkHandler('455')}
				id='D1-455'>
				<polygon
					className={SCSS.UnitBase}
					points='2360.87,242.01 2363.06,245.59 2376.29,237.5 2362.87,215.53 2369.9,211.24 2359.21,193.73 
			2352.17,198.03 2342.86,182.78 2330.1,190.58 2332.39,194.32 2327.39,197.37 2356.35,244.77 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2342.333 209.7668)'
					className={SCSS.PlanName}>
					D1
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2344.416 219.9296)'
					className={SCSS.UnitNumber}>
					455
				</text>
				<circle cx='2359.14' cy='225.74' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-34-OUTLINE_1_'>
				<path
					d='M2342.86,182.78l9.31,15.24l7.03-4.3l10.7,17.51l-7.03,4.3l13.42,21.97l-13.24,8.09l-2.19-3.58l-4.52,2.76l-3.53,2.16
			l2.19,3.58l-13.17,8.05l-12.99,7.94l-2.23-3.64l-4.35,2.66l-4.35,2.66l2.23,3.64l-12.81,7.83l-12.75,7.79l-2.23-3.64l-4.45,2.72
			l-4.25,2.59l2.23,3.64l-12.86,7.86l-12.94,7.91l-2.23-3.64l-4.52,2.76l-4.17,2.55l2.23,3.64l-12.91,7.89l-12.88,7.87l-2.23-3.64
			l-4.17,2.55l-4.17,2.55l2.23,3.64l-13.51,8.26l-13.42-21.97l-3.43,2.1l-10.7-17.51l3.43-2.1l-9.31-15.24l12.31-7.52l2.28,3.74
			l5.38-3.29l5.02-3.07l-2.28-3.74l12.03-7.35l12.05-7.36l2.28,3.74l5.03-3.07l5.38-3.29l-2.28-3.74l12.09-7.39l12.31-7.52
			l2.28,3.74l4.8-2.93l5.61-3.43l-2.28-3.74l11.59-7.08l12.35-7.54l2.28,3.74l4.81-2.94l5.59-3.42l-2.28-3.74l11.74-7.18
			l11.99-7.33l2.28,3.74l4.71-2.88l5-3.06l-2.28-3.74L2342.86,182.78 M2343.52,180.03l-1.71,1.04l-12.76,7.79l-1.71,1.04l1.04,1.71
			l1.24,2.03l-3.29,2.01l-3,1.84l-1.24-2.03l-1.04-1.71l-1.71,1.04l-11.99,7.33l-11.74,7.18l-1.71,1.04l1.04,1.71l1.24,2.03
			l-3.89,2.37l-3.11,1.9l-1.24-2.03l-1.04-1.71l-1.71,1.04l-12.35,7.54l-11.59,7.08l-1.71,1.04l1.04,1.71l1.24,2.03l-3.9,2.38
			l-3.09,1.89l-1.24-2.03l-1.04-1.71l-1.71,1.04l-12.31,7.52l-12.09,7.39l-1.71,1.04l1.04,1.71l1.24,2.03l-3.67,2.24l-3.32,2.03
			l-1.24-2.03l-1.04-1.71l-1.71,1.04l-12.05,7.36l-12.03,7.35l-1.71,1.04l1.04,1.71l1.24,2.03l-3.32,2.03l-3.67,2.25l-1.24-2.03
			l-1.04-1.71l-1.71,1.04l-12.31,7.52l-1.71,1.04l1.04,1.71l8.27,13.54l-1.73,1.05l-1.71,1.04l1.04,1.71l10.7,17.51l1.04,1.71
			l1.71-1.04l1.73-1.05l12.38,20.26l1.04,1.71l1.71-1.04l13.51-8.26l1.71-1.04l-1.04-1.71l-1.18-1.94l2.47-1.51l2.47-1.51
			l1.18,1.94l1.04,1.71l1.71-1.04l12.88-7.87l12.91-7.89l1.71-1.04l-1.04-1.71l-1.18-1.94l2.47-1.51l2.82-1.72l1.18,1.94l1.04,1.71
			l1.71-1.04l12.94-7.91l12.86-7.86l1.71-1.04l-1.04-1.71l-1.18-1.94l2.54-1.55l2.74-1.68l1.18,1.94l1.04,1.71l1.71-1.04
			l12.75-7.79l12.81-7.83l1.71-1.04l-1.04-1.71l-1.18-1.94l2.64-1.61l2.64-1.61l1.18,1.94l1.04,1.71l1.71-1.04l12.99-7.94
			l13.17-8.05l1.71-1.04l-1.04-1.71l-1.15-1.87l1.83-1.12l2.81-1.72l1.15,1.87l1.04,1.71l1.71-1.04l13.24-8.09l1.71-1.04
			l-1.04-1.71l-12.38-20.26l5.33-3.25l1.71-1.04l-1.04-1.71l-10.7-17.51l-1.04-1.71l-1.71,1.04l-5.33,3.25l-8.27-13.54
			L2343.52,180.03L2343.52,180.03z'
				/>
			</g>
			<g id='BUILDING-LABEL-34' className={SCSS.BuildingLabel}>
				<rect
					x='2214.22'
					y='227.01'
					transform='matrix(0.8575 -0.5145 0.5145 0.8575 200.1856 1186.443)'
					width='55.42'
					height='9.64'
				/>
				<text transform='matrix(0.8575 -0.5145 0.5145 0.8575 2223.9504 245.3464)'>
					BUILDING 34
				</text>
			</g>
		</g>
	);
};

export default Building34;
