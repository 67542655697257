import React from 'react';
import SCSS from '../Sales.module.scss';

const Building17 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-17'>
			<g
				onClick={() => click('A1')}
				className={checkHandler('185')}
				id='A1-185'>
				<polygon
					className={SCSS.UnitBase}
					points='492.57,1036.41 464.67,1036.41 464.67,1013.57 462.71,1013.57 462.71,1001.16 464.67,1001.16 
				464.67,984.79 467.58,984.79 467.58,981.6 480.11,981.6 480.11,984.79 492.57,984.79 			'
				/>
				<text
					transform='matrix(1 0 0 1 474.6714 999.7185)'
					className={SCSS.PlanName}>
					A1
				</text>
				<text
					transform='matrix(1 0 0 1 470.8888 1011.0474)'
					className={SCSS.UnitNumber}>
					185
				</text>
				<circle cx='479.12' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('186')} id='A-186'>
				<polygon
					className={SCSS.UnitBase}
					points='518.85,1036.41 492.57,1036.41 492.57,984.79 504.44,984.79 504.44,981.6 516.97,981.6 
				516.97,984.79 518.85,984.79 			'
				/>
				<text
					transform='matrix(1 0 0 1 503.2879 999.7185)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 497.4773 1011.0474)'
					className={SCSS.UnitNumber}>
					186
				</text>
				<circle cx='505.71' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('187')} id='A-187'>
				<polygon
					className={SCSS.UnitBase}
					points='545.85,1036.41 518.85,1036.41 518.85,984.79 531.53,984.79 531.53,981.6 544.06,981.6 
				544.06,984.79 545.85,984.79 			'
				/>
				<text
					transform='matrix(1 0 0 1 529.927 999.7185)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 524.1165 1011.0474)'
					className={SCSS.UnitNumber}>
					187
				</text>
				<circle cx='532.35' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('188')} id='A-188'>
				<polygon
					className={SCSS.UnitBase}
					points='572.79,1036.41 545.85,1036.41 545.85,984.79 547.7,984.79 547.7,981.6 560.23,981.6 
				560.23,984.79 572.79,984.79 			'
				/>
				<text
					transform='matrix(1 0 0 1 556.8984 999.7185)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 551.0879 1011.0474)'
					className={SCSS.UnitNumber}>
					188
				</text>
				<circle cx='559.32' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('189')} id='A-189'>
				<polygon
					className={SCSS.UnitBase}
					points='599.9,1036.41 572.79,1036.41 572.79,984.79 574.61,984.79 574.61,981.6 587.14,981.6 
				587.14,984.79 599.9,984.79 			'
				/>
				<text
					transform='matrix(1 0 0 1 583.9237 999.7185)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 578.1132 1011.0474)'
					className={SCSS.UnitNumber}>
					189
				</text>
				<circle cx='586.35' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A2')}
				className={checkHandler('190')}
				id='A2-190'>
				<polygon
					className={SCSS.UnitBase}
					points='628.84,1013.57 627.01,1013.57 627.01,1036.41 599.9,1036.41 599.9,984.79 612.12,984.79 
				612.12,981.6 624.65,981.6 624.65,984.79 627.01,984.79 627.01,1001.16 628.84,1001.16 			'
				/>
				<text
					transform='matrix(1 0 0 1 609.0034 999.7185)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 605.2207 1011.0474)'
					className={SCSS.UnitNumber}>
					190
				</text>
				<circle cx='613.46' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-17-OUTLINE'>
				<path
					d='M624.65,981.6v3.19h2.36v16.38h1.83v12.4h-1.83v22.84H464.67v-22.84h-1.97v-12.4h1.97v-16.38h2.91v-3.19h12.53v3.19
				h24.32v-3.19h12.53v3.19h14.56v-3.19h12.53v3.19h3.64v-3.19h12.53v3.19h14.38v-3.19h12.53v3.19h24.98v-3.19H624.65 M626.65,979.6
				h-2h-12.53h-2v2v1.19h-20.98v-1.19v-2h-2h-12.53h-2v2v1.19h-10.38v-1.19v-2h-2H547.7h-1.64h-2h-12.53h-2v2v1.19h-10.56v-1.19v-2
				h-2h-12.53h-2v2v1.19h-20.32v-1.19v-2h-2h-12.53h-2v2v1.19h-0.91h-2v2v14.38h-1.97v2v12.4v2h1.97v20.84v2h2h162.34h2v-2v-20.84
				h1.83v-2v-12.4v-2h-1.83v-14.38v-2h-2h-0.36v-1.19V979.6L626.65,979.6z'
				/>
			</g>
			<g id='BUILDING-LABEL-17' className={SCSS.BuildingLabel}>
				<rect x='518.06' y='1040.95' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 523.4076 1048.0767)'>BUILDING 17</text>
			</g>
		</g>
	);
};

export default Building17;
