import React from 'react';
import SCSS from '../Sales.module.scss';

const Building18 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-18'>
			<g
				onClick={() => click('A2')}
				className={checkHandler('191')}
				id='A2-191'>
				<polygon
					className={SCSS.UnitBase}
					points='673.47,1036.41 645.57,1036.41 645.57,1013.57 643.61,1013.57 643.61,1001.16 645.57,1001.16 
				645.57,984.79 648.48,984.79 648.48,981.6 661.01,981.6 661.01,984.79 673.47,984.79 			'
				/>
				<text
					transform='matrix(1 0 0 1 655.5706 999.7185)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 651.7878 1011.0474)'
					className={SCSS.UnitNumber}>
					191
				</text>
				<circle cx='660.02' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('192')} id='A-192'>
				<polygon
					className={SCSS.UnitBase}
					points='699.75,1036.41 673.47,1036.41 673.47,984.79 685.34,984.79 685.34,981.6 697.87,981.6 
				697.87,984.79 699.75,984.79 			'
				/>
				<text
					transform='matrix(1 0 0 1 684.187 999.7185)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 678.3765 1011.0474)'
					className={SCSS.UnitNumber}>
					192
				</text>
				<circle cx='686.61' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('193')} id='A-193'>
				<polygon
					className={SCSS.UnitBase}
					points='726.75,1036.41 699.75,1036.41 699.75,984.79 712.42,984.79 712.42,981.6 724.96,981.6 
				724.96,984.79 726.75,984.79 			'
				/>
				<text
					transform='matrix(1 0 0 1 710.8261 999.7185)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 705.0156 1011.0474)'
					className={SCSS.UnitNumber}>
					193
				</text>
				<circle cx='713.25' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('194')} id='A-194'>
				<polygon
					className={SCSS.UnitBase}
					points='753.69,1036.41 726.75,1036.41 726.75,984.79 728.6,984.79 728.6,981.6 741.13,981.6 
				741.13,984.79 753.69,984.79 			'
				/>
				<text
					transform='matrix(1 0 0 1 737.7975 999.7185)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 731.987 1011.0474)'
					className={SCSS.UnitNumber}>
					194
				</text>
				<circle cx='740.22' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('195')} id='A-195'>
				<polygon
					className={SCSS.UnitBase}
					points='780.8,1036.41 753.69,1036.41 753.69,984.79 755.51,984.79 755.51,981.6 768.04,981.6 
				768.04,984.79 780.8,984.79 			'
				/>
				<text
					transform='matrix(1 0 0 1 764.8228 999.7185)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 759.0122 1011.0474)'
					className={SCSS.UnitNumber}>
					195
				</text>
				<circle cx='767.25' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A1')}
				className={checkHandler('196')}
				id='A1-196'>
				<polygon
					className={SCSS.UnitBase}
					points='809.74,1013.57 807.91,1013.57 807.91,1036.41 780.8,1036.41 780.8,984.79 793.02,984.79 
				793.02,981.6 805.55,981.6 805.55,984.79 807.91,984.79 807.91,1001.16 809.74,1001.16 			'
				/>
				<text
					transform='matrix(1 0 0 1 789.9026 999.7185)'
					className={SCSS.PlanName}>
					A1
				</text>
				<text
					transform='matrix(1 0 0 1 786.1199 1011.0474)'
					className={SCSS.UnitNumber}>
					196
				</text>
				<circle cx='794.35' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-18-OUTLINE'>
				<path
					d='M805.55,981.6v3.19h2.36v16.38h1.83v12.4h-1.83v22.84H645.57v-22.84h-1.97v-12.4h1.97v-16.38h2.91v-3.19h12.53v3.19
				h24.32v-3.19h12.53v3.19h14.56v-3.19h12.53v3.19h3.64v-3.19h12.53v3.19h14.38v-3.19h12.53v3.19h24.98v-3.19H805.55 M807.55,979.6
				h-2h-12.53h-2v2v1.19h-20.98v-1.19v-2h-2h-12.53h-2v2v1.19h-10.38v-1.19v-2h-2H728.6h-1.64h-2h-12.53h-2v2v1.19h-10.56v-1.19v-2
				h-2h-12.53h-2v2v1.19h-20.32v-1.19v-2h-2h-12.53h-2v2v1.19h-0.91h-2v2v14.38h-1.97v2v12.4v2h1.97v20.84v2h2h162.34h2v-2v-20.84
				h1.83v-2v-12.4v-2h-1.83v-14.38v-2h-2h-0.36v-1.19V979.6L807.55,979.6z'
				/>
			</g>
			<g id='BUILDING-LABEL-18' className={SCSS.BuildingLabel}>
				<rect x='698.96' y='1040.95' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 704.3067 1048.0767)'>BUILDING 18</text>
			</g>
		</g>
	);
};

export default Building18;
