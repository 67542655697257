import React from 'react';
import SCSS from '../Rentals.module.scss';

const Building43 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'disable':
				classes = SCSS.Disable;
				break;
			default:
				classes = SCSS.Availabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-43'>
			<g id='UNIT-319'>
				<polygon
					className={SCSS.UnitBase}
					points='1293.67,500.76 1331.16,500.76 1331.16,463.77 1325.62,463.77 1325.62,456.12 1297.02,456.12 
				1297.02,477.23 1293.67,477.23 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('319 C')}
					id='C-319'>
					<text
						transform='matrix(1 0 0 1 1310.1211 471.7315)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1304.1821 481.234)'
						className={SCSS.UnitNumber}>
						319
					</text>
					<circle cx='1312.42' cy='488.4' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-320'>
				<polygon
					className={SCSS.UnitBase}
					points='1331.16,463.77 1336.7,463.77 1336.7,456.12 1364.03,456.12 1364.03,463.77 1366.68,463.77 
				1366.68,500.76 1331.16,500.76 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('320 C')}
					id='C-320'>
					<text
						transform='matrix(1 0 0 1 1346.6267 471.7315)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1340.6876 481.234)'
						className={SCSS.UnitNumber}>
						320
					</text>
					<circle cx='1348.92' cy='488.4' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-321'>
				<polygon
					className={SCSS.UnitBase}
					points='1366.68,463.77 1370.14,463.77 1370.14,456.12 1397.83,456.12 1397.83,500.76 1366.68,500.76 			
				'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('321 C')}
					id='C-321'>
					<text
						transform='matrix(1 0 0 1 1379.9595 471.7315)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1374.0204 481.234)'
						className={SCSS.UnitNumber}>
						321
					</text>
					<circle cx='1382.26' cy='488.4' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-322'>
				<polygon
					className={SCSS.UnitBase}
					points='1397.83,456.12 1425.16,456.12 1425.16,463.77 1430.58,463.77 1430.58,500.76 1397.83,500.76 			
				'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('322 C')}
					id='C-322'>
					<text
						transform='matrix(1 0 0 1 1411.9088 471.7315)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1405.9697 481.234)'
						className={SCSS.UnitNumber}>
						322
					</text>
					<circle cx='1414.2' cy='488.4' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-323'>
				<polygon
					className={SCSS.UnitBase}
					points='1430.58,463.77 1436.41,463.77 1436.41,456.12 1463.92,456.12 1463.92,478.04 1466.8,478.04 
				1466.8,500.76 1430.58,500.76 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('323 C')}
					id='C-323'>
					<text
						transform='matrix(1 0 0 1 1446.3958 471.7315)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1440.4567 481.234)'
						className={SCSS.UnitNumber}>
						323
					</text>
					<circle cx='1448.69' cy='488.4' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-309'>
				<polygon
					className={SCSS.UnitBase}
					points='1293.67,500.76 1331.16,500.76 1331.16,537.74 1325.62,537.74 1325.62,545.39 1297.02,545.39 
				1297.02,524.29 1293.67,524.29 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('309 C')}
					id='C-309'>
					<text
						transform='matrix(1 0 0 1 1310.1211 516.3679)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1304.1821 525.8705)'
						className={SCSS.UnitNumber}>
						309
					</text>
					<circle cx='1312.42' cy='533.03' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-310'>
				<polygon
					className={SCSS.UnitBase}
					points='1331.16,537.74 1336.7,537.74 1336.7,545.39 1364.03,545.39 1364.03,537.74 1366.68,537.74 
				1366.68,500.76 1331.16,500.76 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('310 C')}
					id='C-310'>
					<text
						transform='matrix(1 0 0 1 1346.6267 516.3679)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1340.6876 525.8705)'
						className={SCSS.UnitNumber}>
						310
					</text>
					<circle cx='1348.92' cy='533.03' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-311'>
				<polygon
					className={SCSS.UnitBase}
					points='1366.68,537.74 1370.14,537.74 1370.14,545.39 1397.83,545.39 1397.83,500.76 1366.68,500.76 			
				'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('311 C')}
					id='C-311'>
					<text
						transform='matrix(1 0 0 1 1379.9595 516.3679)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1374.0204 525.8705)'
						className={SCSS.UnitNumber}>
						311
					</text>
					<circle cx='1382.26' cy='533.03' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-312'>
				<polygon
					className={SCSS.UnitBase}
					points='1397.83,545.39 1425.16,545.39 1425.16,537.74 1430.58,537.74 1430.58,500.76 1397.83,500.76 			
				'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('312 C')}
					id='C-312'>
					<text
						transform='matrix(1 0 0 1 1411.9088 516.3679)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1405.9697 525.8705)'
						className={SCSS.UnitNumber}>
						312
					</text>
					<circle cx='1414.2' cy='533.03' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-313'>
				<polygon
					className={SCSS.UnitBase}
					points='1430.58,537.74 1436.41,537.74 1436.41,545.39 1463.92,545.39 1463.92,523.48 1466.8,523.48 
				1466.8,500.76 1430.58,500.76 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('313 C')}
					id='C-313'>
					<text
						transform='matrix(1 0 0 1 1446.3958 516.3679)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1440.4567 525.8705)'
						className={SCSS.UnitNumber}>
						313
					</text>
					<circle cx='1448.69' cy='533.03' r='3.45' className="dot" />
				</g>
			</g>
			<g id='RENTAL-BUILDING-3-OUTLINE'>
				<path
					d='M1463.92,456.12v21.91h2.88v45.44h-2.88v21.91h-27.5v-7.65h-11.25v7.65h-55.02v-7.65h-5.94v7.65h-27.51v-7.65h-11.23
				v7.65h-28.45v-21.11h-3.34v-47.06h3.34v-21.11h28.6v7.65h11.07v-7.65h27.34v7.65h6.11v-7.65h55.02v7.65h11.25v-7.65H1463.92
				 M1465.92,454.12h-2h-27.5h-2v2v5.65h-7.25v-5.65v-2h-2h-55.02h-2v2v5.65h-2.11v-5.65v-2h-2h-27.34h-2v2v5.65h-7.07v-5.65v-2h-2
				h-28.6h-2v2v19.11h-1.34h-2v2v47.06v2h2h1.34v19.11v2h2h28.45h2v-2v-5.65h7.23v5.65v2h2h27.51h2v-2v-5.65h1.94v5.65v2h2h55.02h2
				v-2v-5.65h7.25v5.65v2h2h27.5h2v-2v-19.91h0.88h2v-2v-45.44v-2h-2h-0.88v-19.91V454.12L1465.92,454.12z'
				/>
			</g>
			<g id='BUILDING-LABEL-43' className={SCSS.BuildingLabel}>
				<rect x='1354.57' y='549.77' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1359.9158 556.905)'>BUILDING 43</text>
			</g>
		</g>
	);
};

export default Building43;
