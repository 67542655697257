import React from 'react';
import SCSS from '../Rentals.module.scss';

const Building50 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'disable':
				classes = SCSS.Disable;
				break;
			default:
				classes = SCSS.Availabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-50'>
			<g id='UNIT-363'>
				<rect
					x='1589.69'
					y='168.63'
					className={SCSS.UnitBase}
					width='28.26'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('363 B')}
					id='B-363'>
					<text
						transform='matrix(1 0 0 1 1601.6821 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1595.5913 218.8953)'
						className={SCSS.UnitNumber}>
						363
					</text>
					<circle cx='1603.83' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('363 Suite')}
					id='SUITE-363'>
					<text
						transform='matrix(1 0 0 1 1593.5902 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1603.83' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-364'>
				<rect
					x='1617.96'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.54'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('364 B')}
					id='B-364'>
					<text
						transform='matrix(1 0 0 1 1629.0864 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1622.9956 218.8953)'
						className={SCSS.UnitNumber}>
						364
					</text>
					<circle cx='1631.23' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('364 Suite')}
					id='SUITE-364'>
					<text
						transform='matrix(1 0 0 1 1620.9945 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1631.23' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-365'>
				<rect
					x='1644.5'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.91'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('365 B')}
					id='B-365'>
					<text
						transform='matrix(1 0 0 1 1655.8113 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1649.7205 218.8953)'
						className={SCSS.UnitNumber}>
						365
					</text>
					<circle cx='1657.96' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('365 Suite')}
					id='SUITE-365'>
					<text
						transform='matrix(1 0 0 1 1647.7194 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1657.96' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-366'>
				<rect
					x='1671.41'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.91'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('366 B')}
					id='B-366'>
					<text
						transform='matrix(1 0 0 1 1682.717 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1676.6261 218.8953)'
						className={SCSS.UnitNumber}>
						366
					</text>
					<circle cx='1684.86' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('366 Suite')}
					id='SUITE-366'>
					<text
						transform='matrix(1 0 0 1 1674.625 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1684.86' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-367'>
				<rect
					x='1698.31'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.91'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('367 B')}
					id='B-367'>
					<text
						transform='matrix(1 0 0 1 1709.5739 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1703.4829 218.8953)'
						className={SCSS.UnitNumber}>
						367
					</text>
					<circle cx='1711.72' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('367 Suite')}
					id='SUITE-367'>
					<text
						transform='matrix(1 0 0 1 1701.4819 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1711.72' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='RENTAL-BUILDING-10-OUTLINE'>
				<path
					d='M1725.22,168.63v66.85h-135.53v-66.85H1725.22 M1727.22,166.63h-2h-135.53h-2v2v66.85v2h2h135.53h2v-2v-66.85V166.63
				L1727.22,166.63z'
				/>
			</g>
			<g id='BUILDING-LABEL-50' className={SCSS.BuildingLabel}>
				<rect x='1630.27' y='239.78' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1635.6158 246.9107)'>BUILDING 50</text>
			</g>
		</g>
	);
};

export default Building50;
