import React from 'react';
import SCSS from '../Sales.module.scss';

const Building13 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-13'>
			<g
				onClick={() => click('A2')}
				className={checkHandler('163')}
				id='A2-163'>
				<polygon
					className={SCSS.UnitBase}
					points='673.47,891.48 645.57,891.48 645.57,914.32 643.61,914.32 643.61,926.73 645.57,926.73 
				645.57,944.11 647.48,944.11 647.48,947.3 661.01,947.3 661.01,944.11 673.47,944.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 655.5706 909.6046)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 651.7878 920.9335)'
					className={SCSS.UnitNumber}>
					163
				</text>
				<circle cx='660.02' cy='929.09' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('164')} id='A-164'>
				<polygon
					className={SCSS.UnitBase}
					points='699.75,891.48 673.47,891.48 673.47,944.11 685.34,944.11 685.34,947.3 697.87,947.3 
				697.87,944.11 699.75,944.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 684.187 909.6046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 678.3765 920.9335)'
					className={SCSS.UnitNumber}>
					164
				</text>
				<circle cx='686.61' cy='929.09' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('165')} id='A-165'>
				<polygon
					className={SCSS.UnitBase}
					points='726.75,891.48 699.75,891.48 699.75,944.11 712.42,944.11 712.42,947.3 724.96,947.3 
				724.96,944.11 726.75,944.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 710.8261 909.6046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 705.0156 920.9335)'
					className={SCSS.UnitNumber}>
					165
				</text>
				<circle cx='713.25' cy='929.09' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('166')} id='A-166'>
				<polygon
					className={SCSS.UnitBase}
					points='753.69,891.48 726.75,891.48 726.75,944.11 728.6,944.11 728.6,947.3 741.13,947.3 741.13,944.11 
				753.69,944.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 737.7975 909.6046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 731.987 920.9335)'
					className={SCSS.UnitNumber}>
					166
				</text>
				<circle cx='740.22' cy='929.09' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('167')} id='A-167'>
				<polygon
					className={SCSS.UnitBase}
					points='780.8,891.48 753.69,891.48 753.69,944.11 755.51,944.11 755.51,947.3 768.04,947.3 768.04,944.11 
				780.8,944.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 764.8228 909.6046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 759.0122 920.9335)'
					className={SCSS.UnitNumber}>
					167
				</text>
				<circle cx='767.25' cy='929.09' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A1')}
				className={checkHandler('168')}
				id='A1-168'>
				<polygon
					className={SCSS.UnitBase}
					points='809.74,914.32 807.91,914.32 807.91,891.48 780.8,891.48 780.8,944.11 793.02,944.11 793.02,947.3 
				805.55,947.3 805.55,944.11 807.91,944.11 807.91,926.73 809.74,926.73 			'
				/>
				<text
					transform='matrix(1 0 0 1 789.9026 909.6046)'
					className={SCSS.PlanName}>
					A1
				</text>
				<text
					transform='matrix(1 0 0 1 786.1199 920.9335)'
					className={SCSS.UnitNumber}>
					168
				</text>
				<circle cx='794.35' cy='929.09' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-13-OUTLINE'>
				<path
					d='M807.91,891.48v22.84h1.83v12.4h-1.83v17.38h-2.36v3.19h-12.53v-3.19h-24.98v3.19h-12.53v-3.19h-14.38v3.19H728.6v-3.19
				h-3.64v3.19h-12.53v-3.19h-14.56v3.19h-12.53v-3.19h-24.32v3.19h-13.53v-3.19h-1.91v-17.38h-1.97v-12.4h1.97v-22.84H807.91
				 M809.91,889.48h-2H645.57h-2v2v20.84h-1.97v2v12.4v2h1.97v15.38v2h1.91v1.19v2h2h13.53h2v-2v-1.19h20.32v1.19v2h2h12.53h2v-2
				v-1.19h10.56v1.19v2h2h12.53h2h1.64h12.53h2v-2v-1.19h10.38v1.19v2h2h12.53h2v-2v-1.19h20.98v1.19v2h2h12.53h2v-2v-1.19h0.36h2
				v-2v-15.38h1.83v-2v-12.4v-2h-1.83v-20.84V889.48L809.91,889.48z'
				/>
			</g>
			<g id='BUILDING-LABEL-13' className={SCSS.BuildingLabel}>
				<rect x='698.96' y='876.29' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 704.3067 883.4219)'>BUILDING 13</text>
			</g>
		</g>
	);
};

export default Building13;
