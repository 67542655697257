import React from 'react';
import SCSS from './Label.module.scss';

const index = () => {
	return (
		<g id='LABELS'>
			<g id='EXISTING-APPARTMENT'>
				<rect x='300.22' y='365.05' width='106.27' height='37.23' />
				<text transform='matrix(1 0 0 1 318.1859 380.3586)'>
					<tspan x='0' y='0' className={SCSS.WhiteLabel}>
						EXISTING{' '}
					</tspan>
					<tspan x='-10.18' y='16' className={SCSS.WhiteLabel}>
						APARTMENT
					</tspan>
				</text>
			</g>

			<g id='APPARTMENT-SITE'>
				<rect x='163.49' y='936.76' width='103.76' height='37.23' />
				<text transform='matrix(1 0 0 1 168.3773 952.0676)'>
					<tspan x='0' y='0' className={SCSS.WhiteLabel}>
						APARTMENT{' '}
					</tspan>
					<tspan x='31.67' y='16' className={SCSS.WhiteLabel}>
						SITE
					</tspan>
				</text>
			</g>

			<text
				transform='matrix(0 -1 1 0 55.302 956.0225)'
				className={SCSS.BlackLabel}
			>
				THOMAS ROAD
			</text>
			<text
				transform='matrix(1 0 0 1 1499.7236 115.3608)'
				className={SCSS.BlackLabel}
			>
				PROMONTORY ROAD
			</text>
			<text
				transform='matrix(0.5162 0.8565 -0.8565 0.5162 2413.5564 172.4247)'
				className={SCSS.Sewage}
			>
				<tspan x='0' y='0'>
					SEWAGE
				</tspan>
				<tspan x='7.85' y='9'>
					LIFT
				</tspan>
				<tspan x='-0.6' y='18'>
					STATION
				</tspan>
			</text>
		</g>
	);
};

export default index;
