import React from 'react';
import SCSS from '../Rentals.module.scss';

const Building48 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'disable':
				classes = SCSS.Disable;
				break;
			default:
				classes = SCSS.Availabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-48'>
			<g id='UNIT-354'>
				<rect
					x='1310.49'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.53'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('354 B')}
					id='B-354'>
					<text
						transform='matrix(1 0 0 1 1321.614 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1315.5231 218.8953)'
						className={SCSS.UnitNumber}>
						354
					</text>
					<circle cx='1323.76' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('354 Suite')}
					id='SUITE-354'>
					<text
						transform='matrix(1 0 0 1 1313.522 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1323.76' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-355'>
				<rect
					x='1337.02'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.89'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('355 B')}
					id='B-355'>
					<text
						transform='matrix(1 0 0 1 1348.3214 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1342.2305 218.8953)'
						className={SCSS.UnitNumber}>
						355
					</text>
					<circle cx='1350.47' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('355 Suite')}
					id='SUITE-355'>
					<text
						transform='matrix(1 0 0 1 1340.2295 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1350.47' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-356'>
				<rect
					x='1363.91'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.89'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('356 B')}
					id='B-356'>
					<text
						transform='matrix(1 0 0 1 1375.2095 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1369.1187 218.8953)'
						className={SCSS.UnitNumber}>
						356
					</text>
					<circle cx='1377.35' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('356 Suite')}
					id='SUITE-356'>
					<text
						transform='matrix(1 0 0 1 1367.1176 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1377.35' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-357'>
				<rect
					x='1390.8'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.89'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('357 B')}
					id='B-357'>
					<text
						transform='matrix(1 0 0 1 1402.0977 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1396.0067 218.8953)'
						className={SCSS.UnitNumber}>
						357
					</text>
					<circle cx='1404.24' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('357 Suite')}
					id='SUITE-357'>
					<text
						transform='matrix(1 0 0 1 1394.0056 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1404.24' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='RENTAL-BUILDING-8-OUTLINE'>
				<path
					d='M1417.69,168.63v66.85h-107.19v-66.85H1417.69 M1419.69,166.63h-2h-107.19h-2v2v66.85v2h2h107.19h2v-2v-66.85V166.63
				L1419.69,166.63z'
				/>
			</g>
			<g id='BUILDING-LABEL-48' className={SCSS.BuildingLabel}>
				<rect x='1336.38' y='239.78' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1341.7207 246.9107)'>BUILDING 48</text>
			</g>
		</g>
	);
};

export default Building48;
