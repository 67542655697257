import React from 'react';
import SCSS from '../Sales.module.scss';

const Building2 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-2'>
			<g
				onClick={() => click('B1')}
				className={checkHandler('106')}
				id='B1-106'>
				<rect
					x='715.96'
					y='593.34'
					className={SCSS.UnitBase}
					width='27.28'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 725.4293 619.2469)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 721.3663 630.5758)'
					className={SCSS.UnitNumber}>
					106
				</text>
				<circle cx='729.6' cy='638.73' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('107')} id='B-107'>
				<rect
					x='743.24'
					y='593.34'
					className={SCSS.UnitBase}
					width='27'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 754.5966 619.2469)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 748.5057 630.5758)'
					className={SCSS.UnitNumber}>
					107
				</text>
				<circle cx='756.74' cy='638.73' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('108')} id='B-108'>
				<rect
					x='770.24'
					y='593.34'
					className={SCSS.UnitBase}
					width='26.94'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 781.5679 619.2469)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 775.4772 630.5758)'
					className={SCSS.UnitNumber}>
					108
				</text>
				<circle cx='783.71' cy='638.73' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('B1')}
				className={checkHandler('109')}
				id='B1-109'>
				<rect
					x='797.18'
					y='593.34'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 806.5654 619.2469)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 802.5024 630.5758)'
					className={SCSS.UnitNumber}>
					109
				</text>
				<circle cx='810.74' cy='638.73' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-2-OUTLINE'>
				<path
					d='M824.29,593.34v67.01H715.96v-67.01H824.29 M826.29,591.34h-2H715.96h-2v2v67.01v2h2h108.33h2v-2v-67.01V591.34
				L826.29,591.34z'
				/>
			</g>
			<g id='BUILDING-LABEL-2' className={SCSS.BuildingLabel}>
				<rect x='742.41' y='664.58' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 749.9208 671.7123)'>BUILDING 2</text>
			</g>
		</g>
	);
};

export default Building2;
