import React from 'react';
import SCSS from '../Sales.module.scss';

const Building19 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-19'>
			<g
				onClick={() => click('A1')}
				className={checkHandler('197')}
				id='A1-197'>
				<polygon
					className={SCSS.UnitBase}
					points='896.68,1035.41 868.78,1035.41 868.78,1013.57 866.82,1013.57 866.82,1001.16 868.78,1001.16 
				868.78,984.79 870.69,984.79 870.69,982.6 885.22,982.6 885.22,984.79 896.68,984.79 			'
				/>
				<text
					transform='matrix(1 0 0 1 878.7811 999.7185)'
					className={SCSS.PlanName}>
					A1
				</text>
				<text
					transform='matrix(1 0 0 1 874.9984 1011.0474)'
					className={SCSS.UnitNumber}>
					197
				</text>
				<circle cx='883.23' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('198')} id='A-198'>
				<polygon
					className={SCSS.UnitBase}
					points='922.96,1035.41 896.68,1035.41 896.68,984.79 907.55,984.79 907.55,982.6 921.08,982.6 
				921.08,984.79 922.96,984.79 			'
				/>
				<text
					transform='matrix(1 0 0 1 907.3976 999.7185)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 901.587 1011.0474)'
					className={SCSS.UnitNumber}>
					198
				</text>
				<circle cx='909.82' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('199')} id='A-199'>
				<polygon
					className={SCSS.UnitBase}
					points='922.96,1035.41 949.96,1035.41 949.96,984.79 938.29,984.79 938.29,982.6 924.76,982.6 
				924.76,984.79 922.96,984.79 			'
				/>
				<text
					transform='matrix(1 0 0 1 934.0367 999.7185)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 928.2261 1011.0474)'
					className={SCSS.UnitNumber}>
					199
				</text>
				<circle cx='936.46' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('200')} id='A-200'>
				<polygon
					className={SCSS.UnitBase}
					points='976.9,1035.41 949.96,1035.41 949.96,984.79 951.81,984.79 951.81,982.6 965.34,982.6 
				965.34,984.79 976.9,984.79 			'
				/>
				<text
					transform='matrix(1 0 0 1 961.0081 999.7185)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 955.1976 1011.0474)'
					className={SCSS.UnitNumber}>
					200
				</text>
				<circle cx='963.43' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A2')}
				className={checkHandler('201')}
				id='A2-201'>
				<polygon
					className={SCSS.UnitBase}
					points='1006.84,1014.57 1004.01,1014.57 1004.01,1035.41 976.9,1035.41 976.9,984.79 988.12,984.79 
				988.12,982.6 1001.65,982.6 1001.65,984.79 1004.01,984.79 1004.01,1001.16 1006.84,1001.16 			'
				/>
				<text
					transform='matrix(1 0 0 1 986.0055 999.7185)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 982.2228 1011.0474)'
					className={SCSS.UnitNumber}>
					201
				</text>
				<circle cx='990.46' cy='1019.2' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-19-OUTLINE'>
				<path
					d='M1001.65,982.6v2.19h2.36v16.38h2.83v13.4h-2.83v20.84H868.78v-21.84h-1.97v-12.4h1.97v-16.38h1.91v-2.19h14.53v2.19
				h22.32v-2.19h13.53v2.19h3.68v-2.19h13.53v2.19h13.52v-2.19h13.53v2.19h22.78v-2.19H1001.65 M1003.65,980.6h-2h-13.53h-2v2v0.19
				h-18.78v-0.19v-2h-2h-13.53h-2v2v0.19h-9.52v-0.19v-2h-2h-13.53h-1.68h-2h-13.53h-2v2v0.19h-18.32v-0.19v-2h-2h-14.53h-2v2v0.19
				h-1.91v2v14.38h-1.97v2v12.4v2h1.97v19.84v2h2h135.23h2v-2v-18.84h0.83h2v-2v-13.4v-2h-2h-0.83v-14.38v-2h-2h-0.36v-0.19V980.6
				L1003.65,980.6z'
				/>
			</g>
			<g id='BUILDING-LABEL-19' className={SCSS.BuildingLabel}>
				<rect x='909.12' y='1040.95' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 914.4634 1048.0767)'>BUILDING 19</text>
			</g>
		</g>
	);
};

export default Building19;
