import React from 'react';

import SCSS from '../Rentals.module.scss';

const Building41 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'disable':
				classes = SCSS.Disable;
				break;
			default:
				classes = SCSS.Availabe;
				break;
		}
		return classes;
	};




	return (
		<g id='BUILDING-41'>
			<g id='UNIT-303'>
				<rect
					x='1170.13'
					y='446.22'
					className={SCSS.UnitBase}
					width='66.87'
					height='26.74'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('303 B')}
					id='B-303'>
					<text
						transform='matrix(1 0 0 1 1215.4489 456.9382)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1209.3579 466.4407)'
						className={SCSS.UnitNumber}>
						303
					</text>
					<circle cx='1229.77' cy='459.59' r='3.45' className="dot"  />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('303 Suite')}
					id='SUITE-303'>
					<text
						transform='matrix(1 0 0 1 1173.9213 461.9397)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1199.35' cy='459.59' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-302'>
				<rect
					x='1170.13'
					y='472.96'
					className={SCSS.UnitBase}
					width='66.87'
					height='26.74'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('302 B')}
					id='B-302'>
					<text
						transform='matrix(1 0 0 1 1215.4489 483.6781)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1209.3579 493.1806)'
						className={SCSS.UnitNumber}>
						302
					</text>
					<circle cx='1229.77' cy='486.33' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('302 Suite')}
					id='SUITE-302'>
					<text
						transform='matrix(1 0 0 1 1173.9213 488.6795)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1199.35' cy='486.33' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-301'>
				<rect
					x='1170.13'
					y='499.7'
					className={SCSS.UnitBase}
					width='66.87'
					height='26.74'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('301 B')}
					id='B-301'>
					<text
						transform='matrix(1 0 0 1 1215.4489 510.4179)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1209.3579 519.9204)'
						className={SCSS.UnitNumber}>
						301
					</text>
					<circle cx='1229.77' cy='513.07' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('301 Suite')}
					id='SUITE-301'>
					<text
						transform='matrix(1 0 0 1 1173.9213 515.4193)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1199.35' cy='513.07' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-300'>
				<rect
					x='1170.13'
					y='526.44'
					className={SCSS.UnitBase}
					width='66.87'
					height='26.74'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('300 B')}
					id='B-300_1_'>
					<text
						transform='matrix(1 0 0 1 1215.4489 537.1577)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1209.3579 546.6602)'
						className={SCSS.UnitNumber}>
						300
					</text>
					<circle cx='1229.77' cy='539.81' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('300 Suite')}
					id='SUITE-300'>
					<text
						transform='matrix(1 0 0 1 1173.9213 542.1592)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1199.35' cy='539.81' r='3.45' className="dot" />
				</g>
			</g>
			<g id='RENTAL-BUILDING-1-OUTLINE'>
				<path d='M1237,446.22v106.96h-66.87V446.22H1237 M1239,444.22h-2h-66.87h-2v2v106.96v2h2H1237h2v-2V446.22V444.22L1239,444.22z' />
			</g>
			<g id='BUILDING-LABEL-41' className={SCSS.BuildingLabel}>
				<rect
					x='1218.94'
					y='495.94'
					transform='matrix(6.123234e-17 -1 1 6.123234e-17 745.8955 1747.4095)'
					width='55.42'
					height='9.64'
				/>
				<text transform='matrix(6.123234e-17 -1 1 6.123234e-17 1248.9635 523.1235)'>
					BUILDING 41
				</text>
			</g>
		</g>
	);
};

export default Building41;
