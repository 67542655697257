import React from 'react';
import SCSS from '../Rentals.module.scss';

const Building49 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'disable':
				classes = SCSS.Disable;
				break;
			default:
				classes = SCSS.Availabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-49'>
			<g id='UNIT-358'>
				<rect
					x='1437.13'
					y='168.63'
					className={SCSS.UnitBase}
					width='28.26'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('358 B')}
					id='B-358'>
					<text
						transform='matrix(1 0 0 1 1449.1185 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1443.0276 218.8953)'
						className={SCSS.UnitNumber}>
						358
					</text>
					<circle cx='1451.26' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('358 Suite')}
					id='SUITE-358'>
					<text
						transform='matrix(1 0 0 1 1441.0265 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1451.26' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-359'>
				<rect
					x='1465.4'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.54'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('359 B')}
					id='B-359'>
					<text
						transform='matrix(1 0 0 1 1476.5244 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1470.4336 218.8953)'
						className={SCSS.UnitNumber}>
						359
					</text>
					<circle cx='1478.67' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('359 Suite')}
					id='SUITE-359'>
					<text
						transform='matrix(1 0 0 1 1468.4325 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1478.67' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-360'>
				<rect
					x='1491.94'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.91'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('360 B')}
					id='B-360'>
					<text
						transform='matrix(1 0 0 1 1503.2493 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1497.1584 218.8953)'
						className={SCSS.UnitNumber}>
						360
					</text>
					<circle cx='1505.39' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('360 Suite')}
					id='SUITE-360'>
					<text
						transform='matrix(1 0 0 1 1495.1573 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1505.39' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-361'>
				<rect
					x='1518.85'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.91'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('361 B')}
					id='B-361'>
					<text
						transform='matrix(1 0 0 1 1530.155 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1524.0641 218.8953)'
						className={SCSS.UnitNumber}>
						361
					</text>
					<circle cx='1532.3' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('361 Suite')}
					id='SUITE-361'>
					<text
						transform='matrix(1 0 0 1 1522.063 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1532.3' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-362'>
				<rect
					x='1545.75'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.91'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('362 B')}
					id='B-362'>
					<text
						transform='matrix(1 0 0 1 1557.0607 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1550.9697 218.8953)'
						className={SCSS.UnitNumber}>
						362
					</text>
					<circle cx='1559.2' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('362 Suite')}
					id='SUITE-362'>
					<text
						transform='matrix(1 0 0 1 1548.9686 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1559.2' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='RENTAL-BUILDING-9-OUTLINE'>
				<path
					d='M1572.66,168.63v66.85h-135.53v-66.85H1572.66 M1574.66,166.63h-2h-135.53h-2v2v66.85v2h2h135.53h2v-2v-66.85V166.63
				L1574.66,166.63z'
				/>
			</g>
			<g id='BUILDING-LABEL-49' className={SCSS.BuildingLabel}>
				<rect x='1477.68' y='239.78' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1483.0238 246.9107)'>BUILDING 49</text>
			</g>
		</g>
	);
};

export default Building49;
