import React from 'react';
import SCSS from '../Rentals.module.scss';

const Building51 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'disable':
				classes = SCSS.Disable;
				break;
			default:
				classes = SCSS.Availabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-51'>
			<g id='UNIT-368'>
				<rect
					x='1744.08'
					y='168.63'
					className={SCSS.UnitBase}
					width='28.26'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('368 B')}
					id='B-368'>
					<text
						transform='matrix(1 0 0 1 1756.073 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1749.9821 218.8953)'
						className={SCSS.UnitNumber}>
						368
					</text>
					<circle cx='1758.22' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('368 Suite')}
					id='SUITE-368'>
					<text
						transform='matrix(1 0 0 1 1747.981 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1758.22' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-369'>
				<rect
					x='1772.35'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.54'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('369 B')}
					id='B-369'>
					<text
						transform='matrix(1 0 0 1 1783.6611 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1777.5702 218.8953)'
						className={SCSS.UnitNumber}>
						369
					</text>
					<circle cx='1785.8' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('369 Suite')}
					id='SUITE-369'>
					<text
						transform='matrix(1 0 0 1 1775.5691 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1785.8' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-370'>
				<rect
					x='1798.89'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.91'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('370 B')}
					id='B-370'>
					<text
						transform='matrix(1 0 0 1 1810.2021 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1804.1111 218.8953)'
						className={SCSS.UnitNumber}>
						370
					</text>
					<circle cx='1812.35' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('370 Suite')}
					id='SUITE-370'>
					<text
						transform='matrix(1 0 0 1 1802.1101 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1812.35' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-371'>
				<rect
					x='1825.8'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.91'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('371 B')}
					id='B-371'>
					<text
						transform='matrix(1 0 0 1 1837.1078 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1831.0168 218.8953)'
						className={SCSS.UnitNumber}>
						371
					</text>
					<circle cx='1839.25' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('371 Suite')}
					id='SUITE-371'>
					<text
						transform='matrix(1 0 0 1 1829.0159 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1839.25' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-372'>
				<rect
					x='1852.7'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.91'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('372 B')}
					id='B-372'>
					<text
						transform='matrix(1 0 0 1 1864.0134 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1857.9226 218.8953)'
						className={SCSS.UnitNumber}>
						372
					</text>
					<circle cx='1866.16' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('372 Suite')}
					id='SUITE-372'>
					<text
						transform='matrix(1 0 0 1 1855.9215 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1866.16' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='RENTAL-BUILDING-11-OUTLINE'>
				<path
					d='M1879.61,168.63v66.85h-135.53v-66.85H1879.61 M1881.61,166.63h-2h-135.53h-2v2v66.85v2h2h135.53h2v-2v-66.85V166.63
				L1881.61,166.63z'
				/>
			</g>
			<g id='BUILDING-LABEL-51' className={SCSS.BuildingLabel}>
				<rect x='1784.66' y='239.78' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1790.0066 246.9107)'>BUILDING 51</text>
			</g>
		</g>
	);
};

export default Building51;
