import React from 'react';
import SCSS from '../Sales.module.scss';

const Building22 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-22'>
			<g
				onClick={() => click('A1')}
				className={checkHandler('213')}
				id='A1-213'>
				<polygon
					className={SCSS.UnitBase}
					points='462.12,1095.08 434.22,1095.08 434.22,1116.92 432.25,1116.92 432.25,1129.33 434.22,1129.33 
				434.22,1145.71 436.13,1145.71 436.13,1147.9 450.66,1147.9 450.66,1145.71 462.12,1145.71 			'
				/>
				<text
					transform='matrix(1 0 0 1 444.217 1112.2046)'
					className={SCSS.PlanName}>
					A1
				</text>
				<text
					transform='matrix(1 0 0 1 440.4343 1123.5334)'
					className={SCSS.UnitNumber}>
					213
				</text>
				<circle cx='448.67' cy='1131.69' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('214')} id='A-214'>
				<polygon
					className={SCSS.UnitBase}
					points='488.4,1095.08 462.12,1095.08 462.12,1145.71 472.98,1145.71 472.98,1147.9 486.51,1147.9 
				486.51,1145.71 488.4,1145.71 			'
				/>
				<text
					transform='matrix(1 0 0 1 472.8335 1112.2046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 467.0229 1123.5334)'
					className={SCSS.UnitNumber}>
					214
				</text>
				<circle cx='475.26' cy='1131.69' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('215')} id='A-215'>
				<polygon
					className={SCSS.UnitBase}
					points='515.4,1095.08 488.4,1095.08 488.4,1145.71 500.07,1145.71 500.07,1147.9 513.6,1147.9 
				513.6,1145.71 515.4,1145.71 			'
				/>
				<text
					transform='matrix(1 0 0 1 499.4726 1112.2046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 493.662 1123.5334)'
					className={SCSS.UnitNumber}>
					215
				</text>
				<circle cx='501.9' cy='1131.69' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('216')} id='A-216'>
				<polygon
					className={SCSS.UnitBase}
					points='542.34,1095.08 515.4,1095.08 515.4,1145.71 517.24,1145.71 517.24,1147.9 530.78,1147.9 
				530.78,1145.71 542.34,1145.71 			'
				/>
				<text
					transform='matrix(1 0 0 1 526.444 1112.2046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 520.6335 1123.5334)'
					className={SCSS.UnitNumber}>
					216
				</text>
				<circle cx='528.87' cy='1131.69' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A2')}
				className={checkHandler('217')}
				id='A2-217'>
				<polygon
					className={SCSS.UnitBase}
					points='573.28,1115.92 570.45,1115.92 570.45,1095.08 542.34,1095.08 542.34,1145.71 553.56,1145.71 
				553.56,1147.9 568.09,1147.9 568.09,1145.71 570.45,1145.71 570.45,1129.33 573.28,1129.33 			'
				/>
				<text
					transform='matrix(1 0 0 1 551.4414 1112.2046)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 547.6587 1123.5334)'
					className={SCSS.UnitNumber}>
					217
				</text>
				<circle cx='555.89' cy='1131.69' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-22-OUTLINE'>
				<path
					d='M570.45,1095.08v20.84h2.83v13.4h-2.83v16.38h-2.36v2.19h-14.53v-2.19h-22.78v2.19h-13.53v-2.19h-3.64v2.19h-13.53v-2.19
				h-13.56v2.19h-13.53v-2.19h-22.32v2.19h-14.53v-2.19h-1.91v-16.38h-1.97v-12.4h1.97v-21.84H570.45 M572.45,1093.08h-2H434.22h-2
				v2v19.84h-1.97v2v12.4v2h1.97v14.38v2h1.91v0.19v2h2h14.53h2v-2v-0.19h18.32v0.19v2h2h13.53h2v-2v-0.19h9.56v0.19v2h2h13.53h2
				h1.64h13.53h2v-2v-0.19h18.78v0.19v2h2h14.53h2v-2v-0.19h0.36h2v-2v-14.38h0.83h2v-2v-13.4v-2h-2h-0.83v-18.84V1093.08
				L572.45,1093.08z'
				/>
			</g>
			<g id='BUILDING-LABEL-22' className={SCSS.BuildingLabel}>
				<rect x='475.05' y='1080.29' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 480.3994 1087.4181)'>BUILDING 22</text>
			</g>
		</g>
	);
};

export default Building22;
