import React from 'react';
import SitemapImage from './../../../images/sitemap-20211014.jpg';

const index = () => {
	return (
		<g id='IMAGE'>
			<image
				width='2474'
				height='1297'
				xlinkHref={SitemapImage}
				transform='matrix(1 0 0 1 0 -0.5)'
			></image>
		</g>
	);
};

export default index;
