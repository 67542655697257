import React from 'react';
import SCSS from './AvailabilityBox.module.scss';

const AvailabilityBox = () => {
	const gotoRentalsPage = () => {
		window.open(
			'https://www.base10living.com/rentals/#sitemap-for-rentals',
			'_self'
		);
	};
	return (
		<>
		<symbol className={SCSS.AvailabilityBox} id="TOOLTIP-AVAILABILITY" viewBox="-137.1894 -87.0019 274.3787 283.2942">
			<path className={SCSS.st0} d="M124.6108-86.0895h-249.2216
		c-6.4164,0-11.6661,5.2498-11.6661,11.6661v258.1371c0,6.4164,5.2498,11.6661,11.6661,11.6661h249.2216
		c6.4164,0,11.6661-5.2498,11.6661-11.6661V-74.4234C136.2769-80.8397,131.0272-86.0895,124.6108-86.0895z"/>
			<g >
				<g >
					<path className={SCSS.st1} d="M-113.5419,146.8536c2.8433,0.4975,8.5299,0.9951,14.0031,0.9951c6.6108,0,10.6623-0.6398,14.2165-2.7013
				c3.3408-1.7771,5.6866-5.0468,5.6866-9.4539c0-4.1939-2.4879-8.1034-7.8901-10.1646v-0.1422
				c5.4733-1.4217,9.525-5.6157,9.525-11.7285c0-4.4074-1.9902-7.819-4.9757-10.2358c-3.4831-2.7722-9.3118-4.3361-18.8368-4.3361
				c-5.3312,0-9.3117,0.3553-11.7285,0.7107V146.8536z M-102.8085,128.5143h3.5542c5.7575,0,8.743,2.3459,8.743,5.8997
				c0,3.6254-2.7722,5.5447-7.6768,5.5447c-2.4168,0-3.7675-0.1422-4.6204-0.2844V128.5143z M-102.8085,107.2606
				c1.0662-0.1422,2.3457-0.1422,4.1939-0.1422c4.9046,0,9.2406,1.9193,9.2406,6.9661c0,4.7628-4.336,6.6817-9.7383,6.6817h-3.6962
				V107.2606z"/>
					<path className={SCSS.st1} d="M-9.0781,110.6138c2.8433-1.493,7.3926-2.9857,12.0129-2.9857c4.9757,0,7.6059,2.0615,7.6059,5.189
				c0,2.9857-2.2746,4.6915-8.0323,6.6817c-7.9612,2.8435-13.2213,7.2505-13.2213,14.2166c0,8.1743,6.895,14.3585,18.126,14.3585
				c5.4733,0,9.383-1.066,12.2262-2.4168l-2.4168-8.6719c-1.8482,0.9242-5.3312,2.2746-9.9516,2.2746
				c-4.6913,0-6.9659-2.2037-6.9659-4.6202c0-3.0566,2.63-4.4074,8.8852-6.753c8.4588-3.1275,12.3683-7.5346,12.3683-14.2876
				c0-8.0321-6.113-14.856-19.2633-14.856c-5.4733,0-10.8756,1.4927-13.5767,2.9853L-9.0781,110.6138z"/>
					<path className={SCSS.st1} d="M62.6587,120.0825H45.0301v-11.3732h19.6897V99.824h-30.494v47.9095h29.4989v-8.8854H45.0301v-9.9514
				h17.6286V120.0825z"/>
				</g>
				<g>
					<g>
						<path className={SCSS.st2} d="M-22.4998,98.7956h-46.2165l3.6502,7.984h39.0498L-22.4998,98.7956z"/>
						<path className={SCSS.st2}
							  d="M-27.0321,109.5963h-8.4731l-10.0169,21.8685l-9.8405-21.8685h-8.6024l18.4159,40.9876L-27.0321,109.5963z"
						/>
					</g>
					<path className={SCSS.st2} d="M112.3353,139.091H80.1127c-1.9669,0-3.5761,1.6093-3.5761,3.5761v25.0317
				c0,1.9669,1.6093,3.5761,3.5761,3.5761h32.2225c1.9669,0,3.5761-1.6093,3.5761-3.5761v-25.0317
				C115.9114,140.7003,114.3021,139.091,112.3353,139.091z"/>
				</g>
				<g>
					<path className={SCSS.st3}
						  d="M82.33,163.5936l4.5947,3.1812h2.1205v-23.3268h-3.5344v18.5554H82.33V163.5936z"/>
					<path className={SCSS.st3} d="M102.2232,167.1987c4.4532,0,7.634-3.4283,7.634-10.6734v-2.8277c0-7.2805-3.1808-10.6734-7.634-10.6734
				h-0.3182c-4.4179,0-7.5987,3.3929-7.5987,10.6734v2.8277c0,7.2451,3.1808,10.6734,7.5987,10.6734H102.2232z M106.3231,157.0552
				c0,5.2311-1.6965,7.1395-4.2413,7.1395c-2.5446,0-4.2411-1.9084-4.2411-7.1395v-3.9582c0-5.2311,1.7672-7.0688,4.2411-7.0688
				c2.4741,0,4.2413,1.8377,4.2413,7.0688V157.0552z"/>
				</g>
			</g>
			<g>
				<circle className={SCSS.st4} cx="-77.366" cy="-24.2343" r="16.0849"/>
				<text transform="matrix(1 0 0 -1 -103.8467 -57.5503)" className={SCSS.st29} >Occupied
				</text>
			</g>
			<g>
				<circle className={SCSS.st5} cx="0" cy="-24.2343" r="16.0849"/>
				<text transform="matrix(1 0 0 -1 -25.1147 -57.5503)" className={SCSS.st29} >For Sale
				</text>
			</g>
			<g>
				<circle className={SCSS.st6} cx="77.8785" cy="-24.2343" r="16.0849"/>
				<text transform="matrix(1 0 0 -1 52.9033 -57.5503)" className={SCSS.st29}>For Rent
				</text>
			</g>
			<g>
				<path d="M-90.8131,74.2786h-5.6011v3.9608h16.0296v-3.9608h-5.6943V57.3835h-4.7343V74.2786z"/>
				<path d="M-59.6747,68.0275c0-6.8382-4.146-10.9842-10.2421-10.9842c-6.1894,0-9.8088,4.6726-9.8088,10.6138
			c0,6.2498,3.991,10.9224,10.1489,10.9224C-63.1706,78.5796-59.6747,73.7835-59.6747,68.0275z M-74.7444,67.7491
			c0-4.0843,1.9187-6.9616,5.0757-6.9616c3.186,0,5.0127,3.0323,5.0127,7.0864c0,3.7441-1.7952,6.9616-5.0442,6.9616
			C-72.8874,74.8354-74.7444,71.8031-74.7444,67.7491z"/>
				<path d="M-53.4122,57.3835l-4.951,20.8559h5.0442l1.5785-8.6019c0.4636-2.4755,0.897-5.1677,1.2371-7.2715h0.0617
			c0.3414,2.2588,0.8365,4.7646,1.3618,7.3333l1.7637,8.5402h5.0127l1.6717-8.7883c0.4636-2.444,0.8037-4.6726,1.1137-6.9931h0.0617
			c0.3099,2.3205,0.7735,4.7658,1.2069,7.2413l1.702,8.5402h4.796l-5.3844-20.8559h-5.1047l-1.765,8.9735
			c-0.4019,2.1039-0.742,4.054-0.9902,6.4363h-0.0617c-0.3704-2.352-0.7118-4.3324-1.2069-6.4363l-1.9804-8.9735H-53.4122z"/>
				<path d="M-29.2922,57.3835v20.8559h5.5078l4.3324-7.6432c1.2384-2.1971,2.4755-4.796,3.404-7.1481h0.0932
			c-0.3099,2.7539-0.4031,5.5696-0.4031,8.6951v6.0962h4.3324V57.3835h-4.951l-4.4559,8.0451
			c-1.2384,2.2286-2.599,4.9208-3.6207,7.3648l-0.092-0.0302c0.1235-2.7552,0.1852-5.6943,0.1852-9.0982v-6.2813H-29.2922z"/>
				<path
					d="M-3.1955,78.2394v-8.0148h7.7666v8.0148h4.7041V57.3835H4.5711v8.7266h-7.7666v-8.7266h-4.7343v20.8559H-3.1955z"/>
				<path d="M32.4945,68.0275c0-6.8382-4.146-10.9842-10.2421-10.9842c-6.1894,0-9.8088,4.6726-9.8088,10.6138
			c0,6.2498,3.991,10.9224,10.1489,10.9224C28.9986,78.5796,32.4945,73.7835,32.4945,68.0275z M17.4249,67.7491
			c0-4.0843,1.9187-6.9616,5.0757-6.9616c3.186,0,5.0127,3.0323,5.0127,7.0864c0,3.7441-1.7952,6.9616-5.0442,6.9616
			C19.2818,74.8354,17.4249,71.8031,17.4249,67.7491z"/>
				<path d="M53.2396,65.3668c-0.0932,2.507-0.1865,5.5393-0.1865,8.5717h-0.092c-0.6501-2.6607-1.5168-5.6313-2.3218-8.0766
			l-2.5372-8.1383h-3.6824l-2.2273,8.0766c-0.6803,2.444-1.3921,5.4146-1.8872,8.1383h-0.0617
			c-0.1247-2.8156-0.2167-6.0344-0.3716-8.6334l-0.3716-7.9216h-4.3627l1.3303,20.8559h6.2813l2.0421-6.9616
			c0.6501-2.4138,1.3001-5.014,1.7637-7.458h0.0932c0.5883,2.4138,1.3001,5.1677,1.9804,7.4882l2.2273,6.9314h6.1579l1.1452-20.8559
			h-4.6109L53.2396,65.3668z"/>
				<path
					d="M74.2026,66.202h-7.6747v-4.951h8.5717v-3.8676h-13.306v20.8559h12.8726v-3.8676h-8.1383v-4.3324h7.6747V66.202z"/>
				<path d="M78.4809,62.2413c1.2686-0.6488,3.2175-1.2989,5.2294-1.2989c2.1656,0,3.3107,0.897,3.3107,2.2588
			c0,1.2989-0.9902,2.0421-3.4959,2.9391c-3.4657,1.2069-5.7245,3.1256-5.7245,6.1579c0,3.5589,2.9706,6.2813,7.8901,6.2813
			c2.352,0,4.0843-0.4951,5.3226-1.0519l-1.0519-3.8058c-0.8365,0.4019-2.3218,0.9902-4.3639,0.9902
			c-2.0421,0-3.0323-0.9285-3.0323-2.0119c0-1.3303,1.1767-1.9174,3.8676-2.9391c3.6824-1.3618,5.4159-3.2805,5.4159-6.2196
			c0-3.4972-2.6922-6.4678-8.4167-6.4678c-2.3823,0-4.7343,0.6198-5.911,1.2686L78.4809,62.2413z"/>
				<path d="M-90.0232,25.5837l-1.4853-5.3529h-4.888l6.3733,20.8559h6.1894l6.4678-20.8559h-5.0757l-1.6088,5.3529H-90.0232z
			 M-84.7308,29.1111l-1.3001,4.4257c-0.3716,1.2371-0.7433,2.7841-1.0519,4.0225h-0.0617c-0.3099-1.2384-0.6198-2.8156-0.96-4.0225
			l-1.2371-4.4257H-84.7308z"/>
				<path d="M-71.4765,20.2308l-6.6845,20.8559h5.1677l2.5372-8.8186c0.7118-2.4755,1.3618-4.859,1.8569-7.458h0.0932
			c0.5253,2.507,1.1754,4.9825,1.8872,7.3648l2.6607,8.9118h5.0127l-7.0234-20.8559H-71.4765z"/>
				<path d="M-53.5206,25.5837l-1.4853-5.3529h-4.888l6.3733,20.8559h6.1894l6.4665-20.8559h-5.0745l-1.6088,5.3529H-53.5206z
			 M-48.2282,29.1111l-1.3001,4.4257c-0.3716,1.2371-0.742,2.7841-1.0519,4.0225h-0.0617c-0.3099-1.2384-0.6186-2.8156-0.96-4.0225
			l-1.2371-4.4257H-48.2282z"/>
				<path d="M-33.3336,41.0868V20.2308h-4.7343v20.8559H-33.3336z"/>
				<path d="M-29.2443,41.0868h4.7343V24.1916h8.2932v-3.9608h-13.0276V41.0868z"/>
				<path d="M-8.3808,25.5837l-1.4853-5.3529h-4.888l6.3733,20.8559h6.1894l6.4678-20.8559h-5.0757l-1.6088,5.3529H-8.3808z
			 M-3.0884,29.1111l-1.3001,4.4257c-0.3716,1.2371-0.7433,2.7841-1.0519,4.0225h-0.0617c-0.3099-1.2384-0.6198-2.8156-0.96-4.0225
			l-1.2371-4.4257H-3.0884z"/>
				<path d="M7.0718,40.8083c1.2384,0.2482,3.7441,0.4334,6.0962,0.4334c2.8774,0,4.6411-0.2784,6.1579-1.1754
			c1.4538-0.7748,2.5057-2.1971,2.5057-4.0855c0-1.8557-1.0822-3.5892-3.4342-4.4559v-0.0617
			c2.3823-0.6501,4.146-2.444,4.146-5.1362c0-1.8884-0.8655-3.3725-2.1656-4.4257c-1.5168-1.2069-4.054-1.8872-8.2-1.8872
			c-2.3205,0-4.054,0.155-5.106,0.3099V40.8083z M11.7444,32.825h1.547c2.4755,0,3.8058,1.0217,3.8058,2.5687
			c0,1.5773-1.2069,2.4138-3.341,2.4138c-1.0217,0-1.61-0.063-2.0119-0.1247V32.825z M11.7444,23.5731
			c0.4636-0.0617,1.0217-0.0617,1.8254-0.0617c2.1354,0,4.0225,0.8038,4.0225,3.0008c0,2.1039-1.8872,2.9404-4.2392,2.9404h-1.6088
			V23.5731z"/>
				<path d="M30.5053,41.0868V20.2308H25.771v20.8559H30.5053z"/>
				<path d="M34.5946,41.0868h4.7343V24.1916h8.2932v-3.9608H34.5946V41.0868z"/>
				<path d="M55.1495,41.0868V20.2308h-4.7343v20.8559H55.1495z"/>
				<path d="M63.2613,37.1259h-5.6011v3.9608h16.0296v-3.9608h-5.6943V20.2308h-4.7343V37.1259z"/>
				<path d="M81.2487,20.2308v8.5402l-6.5913,12.3158h5.3844l2.1354-5.0127c0.6501-1.5168,1.0822-2.6305,1.6088-3.9923h0.0617
			c0.4649,1.3001,0.96,2.507,1.5785,3.9923l2.1039,5.0127h5.2911l-6.8697-12.1608v-8.6951H81.2487z"/>
			</g>
		</symbol>
		<use xlinkHref="#TOOLTIP-AVAILABILITY"  width="274.3787" height="283.2942" x="-137.1894" y="-87.0019" transform="matrix(1 0 0 -1 879.7878 404.1982)" /></>
	);
};

export default AvailabilityBox;
