import React from 'react';
import SCSS from '../Sales.module.scss';

const Building32 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-32_1_'>
			<g
				onClick={() => click('E1')}
				className={checkHandler('426')}
				id='E1-426'>
				<polygon
					className={SCSS.UnitBase}
					points='1849.18,600.59 1845.57,598 1836.97,610.02 1850.62,619.78 1848.83,622.29 1865.52,634.23 
			1867.31,631.71 1889.14,647.32 1898.96,633.58 1894.93,630.7 1897.04,627.75 1852.52,595.92 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1859.1913 618.9364)'
					className={SCSS.PlanName}>
					E1
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1864.6461 628.064)'
					className={SCSS.UnitNumber}>
					426
				</text>
				<circle cx='1880.5' cy='627.78' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('427')} id='D-427'>
				<polygon
					className={SCSS.UnitBase}
					points='1852.81,587.88 1856.42,590.47 1852.52,595.92 1897.04,627.75 1900.19,623.35 1904.22,626.23 
			1913.09,613.82 1860.92,576.52 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1872.2833 600.6039)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1876.9647 610.816)'
					className={SCSS.UnitNumber}>
					427
				</text>
				<circle cx='1892.82' cy='610.53' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('428')} id='E-428'>
				<polygon
					className={SCSS.UnitBase}
					points='1872.89,567.43 1869.28,564.84 1860.92,576.52 1913.09,613.82 1922.03,601.32 1918,598.44 
			1920.88,594.4 1876.36,562.57 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1884.5278 583.4521)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1888.8048 594.231)'
					className={SCSS.UnitNumber}>
					428
				</text>
				<circle cx='1904.66' cy='593.94' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('429')} id='D-429'>
				<polygon
					className={SCSS.UnitBase}
					points='1876.51,554.72 1880.13,557.31 1876.36,562.57 1920.88,594.4 1924.06,589.95 1928.09,592.83 
			1936.27,581.39 1884.11,544.1 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1895.796 567.7435)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1900.4774 577.9556)'
					className={SCSS.UnitNumber}>
					429
				</text>
				<circle cx='1916.33' cy='577.67' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('430')} id='E-430'>
				<polygon
					className={SCSS.UnitBase}
					points='1896.28,534.71 1892.67,532.13 1884.11,544.1 1936.27,581.39 1945.8,568.07 1941.76,565.19 
			1944.53,561.33 1900.01,529.5 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1908.0461 550.541)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1912.3231 561.3199)'
					className={SCSS.UnitNumber}>
					430
				</text>
				<circle cx='1928.18' cy='561.03' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('431')} id='D-431'>
				<polygon
					className={SCSS.UnitBase}
					points='1899.9,522.01 1903.52,524.59 1900.01,529.5 1944.53,561.33 1947.83,556.71 1951.86,559.59 
			1959.7,548.63 1907.53,511.33 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1919.2084 534.9388)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1923.8898 545.1509)'
					className={SCSS.UnitNumber}>
					431
				</text>
				<circle cx='1939.75' cy='544.86' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('432')} id='E-432'>
				<polygon
					className={SCSS.UnitBase}
					points='1919.83,501.78 1916.21,499.2 1907.53,511.33 1959.7,548.63 1968.92,535.73 1964.89,532.84 
			1967.62,529.03 1923.1,497.2 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1931.1453 518.2187)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1935.4222 528.9976)'
					className={SCSS.UnitNumber}>
					432
				</text>
				<circle cx='1951.28' cy='528.71' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('433')} id='D-433'>
				<polygon
					className={SCSS.UnitBase}
					points='1974.99,527.24 1983.29,515.63 1931.12,478.34 1923.45,489.08 1927.06,491.66 1923.1,497.2 
			1967.62,529.03 1970.96,524.36 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1942.6671 502.0883)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1947.3485 512.3004)'
					className={SCSS.UnitNumber}>
					433
				</text>
				<circle cx='1963.2' cy='512.01' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('434')} id='E-434'>
				<polygon
					className={SCSS.UnitBase}
					points='1992.75,502.41 1988.71,499.52 1991.1,496.19 1946.58,464.36 1943.4,468.81 1939.79,466.22 
			1931.12,478.34 1983.29,515.63 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1954.812 485.2021)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1959.089 495.981)'
					className={SCSS.UnitNumber}>
					434
				</text>
				<circle cx='1974.94' cy='495.69' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('D1')}
				className={checkHandler('435')}
				id='D1-435'>
				<polygon
					className={SCSS.UnitBase}
					points='1994.04,492.08 1998.07,494.96 2007.05,482.4 1985.22,466.8 1990,460.12 1973.31,448.19 
			1968.53,454.87 1954.88,445.11 1946.34,457.05 1949.96,459.64 1946.58,464.36 1991.1,496.19 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1965.8297 469.7651)'
					className={SCSS.PlanName}>
					D1
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1971.6884 478.3267)'
					className={SCSS.UnitNumber}>
					435
				</text>
				<circle cx='1987.54' cy='478.04' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-32-OUTLINE_1_'>
				<path
					d='M1954.88,445.11l13.65,9.76l4.78-6.68l16.69,11.93l-4.78,6.68l21.83,15.6l-8.98,12.56l-4.03-2.88l-2.94,4.11l-2.39,3.34
			l4.03,2.88l-9.46,13.23l-8.3,11.61l-4.03-2.88l-3.34,4.67l-2.73,3.82l4.03,2.88l-9.22,12.9l-7.84,10.96l-4.03-2.88l-3.31,4.62
			l-2.76,3.86l4.03,2.88l-9.52,13.32l-8.18,11.44l-4.03-2.88l-3.18,4.45l-2.89,4.04l4.03,2.88l-8.94,12.5l-8.87,12.41l-4.03-2.88
			l-3.15,4.4l-2.11,2.95l4.03,2.88l-9.82,13.74l-21.83-15.6l-1.79,2.51l-16.69-11.93l1.8-2.51l-13.65-9.76l8.6-12.02l3.62,2.58
			l3.34-4.67l3.9-5.45l-3.62-2.58l8.12-11.36l8.35-11.68l3.62,2.58l3.47-4.85l3.77-5.27l-3.62-2.58l7.59-10.62l8.56-11.97
			l3.62,2.58l3.73-5.21l3.51-4.91l-3.62-2.58l7.63-10.68l8.68-12.13l3.62,2.58l3.28-4.58l3.96-5.54l-3.62-2.58l7.68-10.74
			l8.66-12.12l3.62,2.58l3.18-4.45l3.38-4.72l-3.62-2.58L1954.88,445.11 M1954.42,442.32l-1.16,1.63l-8.54,11.95l-1.16,1.63
			l1.63,1.16l1.99,1.42l-2.21,3.09l-2.02,2.82l-1.99-1.42l-1.63-1.16l-1.16,1.63l-8.66,12.12l-7.68,10.74l-1.16,1.63l1.63,1.16
			l1.99,1.42l-2.8,3.91l-2.11,2.96l-1.99-1.42l-1.63-1.16l-1.16,1.63l-8.68,12.13l-7.63,10.68l-1.16,1.63l1.63,1.16l1.99,1.42
			l-2.35,3.28l-2.56,3.59l-1.99-1.42l-1.63-1.16l-1.16,1.63l-8.56,11.97l-7.59,10.62l-1.16,1.63l1.63,1.16l1.99,1.42l-2.6,3.64
			l-2.31,3.23l-1.99-1.42l-1.63-1.16l-1.16,1.63l-8.35,11.68l-8.12,11.36l-1.16,1.63l1.63,1.16l1.99,1.42l-2.74,3.83l-2.17,3.04
			l-1.99-1.42l-1.63-1.16l-1.16,1.63l-8.6,12.02l-1.16,1.63l1.63,1.16l12.02,8.6l-0.63,0.88l-1.16,1.63l1.63,1.16l16.69,11.93
			l1.63,1.16l1.16-1.63l0.63-0.88l20.2,14.44l1.63,1.16l1.16-1.63l9.82-13.74l1.16-1.63l-1.63-1.16l-2.4-1.72l0.95-1.32l1.98-2.77
			l2.4,1.72l1.63,1.16l1.16-1.63l8.87-12.41l8.94-12.5l1.16-1.63l-1.63-1.16l-2.4-1.72l1.72-2.41l2.02-2.82l2.4,1.72l1.63,1.16
			l1.16-1.63l8.18-11.44l9.52-13.32l1.16-1.63l-1.63-1.16l-2.4-1.72l1.6-2.24l2.14-3l2.4,1.72l1.63,1.16l1.16-1.63l7.84-10.96
			l9.22-12.9l1.16-1.63l-1.63-1.16l-2.4-1.72l1.57-2.19l2.17-3.04l2.4,1.72l1.63,1.16l1.16-1.63l8.3-11.61l9.46-13.23l1.16-1.63
			l-1.63-1.16l-2.4-1.72l1.22-1.71l1.77-2.48l2.4,1.72l1.63,1.16l1.16-1.63l8.98-12.56l1.16-1.63l-1.63-1.16l-20.2-14.44l3.61-5.05
			l1.16-1.63l-1.63-1.16l-16.69-11.93l-1.63-1.16l-1.16,1.63l-3.61,5.05l-12.02-8.6L1954.42,442.32L1954.42,442.32z'
				/>
			</g>
			<g id='BUILDING-LABEL-32' className={SCSS.BuildingLabel}>
				<rect
					x='1854.26'
					y='524.77'
					transform='matrix(0.5816 -0.8135 0.8135 0.5816 356.6113 1752.5187)'
					width='55.42'
					height='9.64'
				/>
				<text transform='matrix(0.5816 -0.8135 0.8135 0.5816 1870.8049 549.1515)'>
					BUILDING 32
				</text>
			</g>
		</g>
	);
};

export default Building32;
