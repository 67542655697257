import React from 'react';
import SCSS from '../Rentals.module.scss';

const Building52 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'disable':
				classes = SCSS.Disable;
				break;
			default:
				classes = SCSS.Availabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-52'>
			<g id='UNIT-373'>
				<rect
					x='1898.67'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.54'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('373 B')}
					id='B-373'>
					<text
						transform='matrix(1 0 0 1 1909.7981 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1903.7072 218.8953)'
						className={SCSS.UnitNumber}>
						373
					</text>
					<circle cx='1911.94' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('373 Suite')}
					id='SUITE-373'>
					<text
						transform='matrix(1 0 0 1 1901.7061 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1911.94' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-374'>
				<rect
					x='1925.21'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.91'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('374 B')}
					id='B-374'>
					<text
						transform='matrix(1 0 0 1 1936.5229 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1930.432 218.8953)'
						className={SCSS.UnitNumber}>
						374
					</text>
					<circle cx='1938.67' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('374 Suite')}
					id='SUITE-374'>
					<text
						transform='matrix(1 0 0 1 1928.4309 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1938.67' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-375'>
				<rect
					x='1952.12'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.91'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('375 B')}
					id='B-375'>
					<text
						transform='matrix(1 0 0 1 1963.4287 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1957.3376 218.8953)'
						className={SCSS.UnitNumber}>
						375
					</text>
					<circle cx='1965.57' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('375 Suite')}
					id='SUITE-375'>
					<text
						transform='matrix(1 0 0 1 1955.3367 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1965.57' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-376'>
				<rect
					x='1979.03'
					y='168.63'
					className={SCSS.UnitBase}
					width='26.91'
					height='66.85'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('376 B')}
					id='B-376'>
					<text
						transform='matrix(1 0 0 1 1990.3344 209.3928)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1984.2434 218.8953)'
						className={SCSS.UnitNumber}>
						376
					</text>
					<circle cx='1992.48' cy='226.06' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('376 Suite')}
					id='SUITE-376'>
					<text
						transform='matrix(1 0 0 1 1982.2424 180.534)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1992.48' cy='188.13' r='3.45' className="dot" />
				</g>
			</g>
			<g id='RENTAL-BUILDING-12-OUTLINE'>
				<path
					d='M2005.93,168.63v66.85h-107.26v-66.85H2005.93 M2007.93,166.63h-2h-107.26h-2v2v66.85v2h2h107.26h2v-2v-66.85V166.63
				L2007.93,166.63z'
				/>
			</g>
			<g id='BUILDING-LABEL-52' className={SCSS.BuildingLabel}>
				<rect x='1924.41' y='239.78' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1929.7504 246.9107)'>BUILDING 52</text>
			</g>
		</g>
	);
};

export default Building52;
