import React from 'react';
import SCSS from './PackingLot.module.scss';

const index = () => {
	return (
		<g id='PARKING-LOT-LABELS' className={SCSS.PackingLotText}>
			<text transform='matrix(1 0 0 1 587.9507 693.3746)'>102</text>
			<text transform='matrix(1 0 0 1 619.9507 693.3746)'>102</text>
			<text transform='matrix(1 0 0 1 651.9507 693.3746)'>103</text>
			<text transform='matrix(1 0 0 1 683.9507 693.3746)'>104</text>
			<text transform='matrix(1 0 0 1 503.419 730.2658)'>129</text>
			<text transform='matrix(0 -1 1 0 458.2621 800.6733)'>128</text>
			<text transform='matrix(1 0 0 1 535.8369 730.2658)'>130</text>
			<text transform='matrix(1 0 0 1 568.2546 730.2658)'>131</text>
			<text transform='matrix(1 0 0 1 452.8795 934.5657)'>V</text>
			<text transform='matrix(1 0 0 1 631.9147 892.4813)'>V</text>
			<text transform='matrix(1 0 0 1 631.9147 1042.6328)'>V</text>
			<text transform='matrix(1 0 0 1 816.2438 1042.6328)'>V</text>
			<text transform='matrix(1 0 0 1 834.0385 1042.6328)'>V</text>
			<text transform='matrix(1 0 0 1 845.1804 1042.6328)'>V</text>
			<text transform='matrix(1 0 0 1 856.5955 1042.6328)'>V</text>
			<text transform='matrix(1 0 0 1 452.8795 989.0889)'>V</text>
			<text transform='matrix(1 0 0 1 600.6722 730.2658)'>132</text>
			<text transform='matrix(1 0 0 1 659.689 730.2658)'>133</text>
			<text transform='matrix(1 0 0 1 692.0308 730.2658)'>134</text>
			<text transform='matrix(1 0 0 1 724.3726 730.2658)'>135</text>
			<text transform='matrix(1 0 0 1 756.7144 730.2658)'>136</text>
			<text transform='matrix(1 0 0 1 988.2039 692.6911)'>111</text>
			<text transform='matrix(1 0 0 1 1021.0392 692.6911)'>112</text>
			<text transform='matrix(1 0 0 1 1053.8744 692.6911)'>113</text>
			<text transform='matrix(1 0 0 1 1086.7102 692.6911)'>114</text>
			<text transform='matrix(1 0 0 1 1258.3252 692.3628)'>118</text>
			<text transform='matrix(1 0 0 1 1291.1604 692.3628)'>119</text>
			<text transform='matrix(1 0 0 1 1323.9957 692.3628)'>120</text>
			<text transform='matrix(1 0 0 1 1356.8315 692.3628)'>121</text>
			<text transform='matrix(0 -1 1 0 1396.0813 609.9604)'>122</text>
			<text transform='matrix(0 -1 1 0 1519.2756 611.3442)'>123</text>
			<text transform='matrix(0 -1 1 0 1530.6692 611.3442)'>124</text>
			<text transform='matrix(0 -1 1 0 1542.0637 611.3442)'>125</text>
			<text transform='matrix(0 -1 1 0 1553.4574 611.3442)'>126</text>
			<text transform='matrix(0 -1 1 0 1564.8521 611.3442)'>127</text>
			<text transform='matrix(1 0 0 1 1571.166 607.108)'>V</text>
			<text transform='matrix(1 0 0 1 1582.8064 607.108)'>V</text>
			<text transform='matrix(1 0 0 1 1593.2703 607.108)'>V</text>
			<text transform='matrix(1 0 0 1 1225.9707 730.2666)'>145</text>
			<text transform='matrix(1 0 0 1 1258.806 730.2666)'>146</text>
			<text transform='matrix(1 0 0 1 1291.6414 730.2666)'>147</text>
			<text transform='matrix(1 0 0 1 1324.4771 730.2666)'>148</text>
			<text transform='matrix(1 0 0 1 1387.7361 856.9682)'>149</text>
			<text transform='matrix(1 0 0 1 1387.7361 868.379)'>150</text>
			<text transform='matrix(1 0 0 1 1387.7361 879.7899)'>151</text>
			<text transform='matrix(1 0 0 1 1387.7361 891.2008)'>152</text>
			<text transform='matrix(1 0 0 1 1387.7361 902.6126)'>153</text>
			<text transform='matrix(1 0 0 1 1387.7361 914.0234)'>154</text>
			<text transform='matrix(1 0 0 1 1387.7361 925.4343)'>155</text>
			<text transform='matrix(1 0 0 1 1387.7361 936.8462)'>156</text>
			<text transform='matrix(1 0 0 1 1394.0065 1001.533)'>V</text>
			<text transform='matrix(1 0 0 1 1349.6218 947.3052)'>V</text>
			<text transform='matrix(1 0 0 1 1349.6218 986.5244)'>V</text>
			<text transform='matrix(0.8102 0.5862 -0.5862 0.8102 1540.755 778.3947)'>
				V
			</text>
			<text transform='matrix(0.8102 0.5862 -0.5862 0.8102 1550.0553 784.5151)'>
				V
			</text>
			<text transform='matrix(0.8102 0.5862 -0.5862 0.8102 1558.756 790.5754)'>
				V
			</text>
			<text transform='matrix(0.8102 0.5862 -0.5862 0.8102 1567.3966 796.3957)'>
				V
			</text>
			<text transform='matrix(0.8102 0.5862 -0.5862 0.8102 1576.1569 802.456)'>
				V
			</text>
			<text transform='matrix(0.8102 0.5862 -0.5862 0.8102 1584.7975 808.3964)'>
				V
			</text>
			<text transform='matrix(1 0 0 1 1394.0065 1012.6411)'>V</text>
			<text transform='matrix(1 0 0 1 1394.0065 1023.9575)'>V</text>
			<text transform='matrix(1 0 0 1 1394.0065 1035.2939)'>V</text>
			<text transform='matrix(1 0 0 1 1394.0065 1046.6863)'>V</text>
			<text transform='matrix(0.565 -0.8251 0.8251 0.565 1462.8319 1033.5734)'>
				V
			</text>
			<text transform='matrix(0.565 -0.8251 0.8251 0.565 1469.3636 1025.1427)'>
				V
			</text>
			<text transform='matrix(0.565 -0.8251 0.8251 0.565 1475.2118 1016.8644)'>
				V
			</text>
			<text transform='matrix(0.565 -0.8251 0.8251 0.565 1693.2623 801.9269)'>
				V
			</text>
			<text transform='matrix(0.565 -0.8251 0.8251 0.565 1769.5914 603.9269)'>
				V
			</text>
			<text transform='matrix(0.565 -0.8251 0.8251 0.565 1826.0016 616.2491)'>
				V
			</text>
			<text transform='matrix(0.565 -0.8251 0.8251 0.565 1953.3685 435.9073)'>
				V
			</text>
			<text transform='matrix(0.565 -0.8251 0.8251 0.565 1959.8055 427.59)'>
				V
			</text>
			<text transform='matrix(0.565 -0.8251 0.8251 0.565 1966.2421 419.2736)'>
				V
			</text>
			<text transform='matrix(0.8542 -0.52 0.52 0.8542 1971.4294 340.8841)'>
				V
			</text>
			<text transform='matrix(0.8542 -0.52 0.52 0.8542 1980.4011 335.3875)'>
				V
			</text>
			<text transform='matrix(0.8542 -0.52 0.52 0.8542 1989.3728 329.8909)'>
				V
			</text>
			<text transform='matrix(0.8542 -0.52 0.52 0.8542 1998.3445 324.3939)'>
				V
			</text>
			<text transform='matrix(0.8542 -0.52 0.52 0.8542 2007.3156 318.8963)'>
				V
			</text>
			<text transform='matrix(0.8542 -0.52 0.52 0.8542 2121.7083 258.075)'>
				V
			</text>
			<text transform='matrix(0.8542 -0.52 0.52 0.8542 2150.3032 241.1004)'>
				V
			</text>
			<text transform='matrix(0.8542 -0.52 0.52 0.8542 2177.0752 224.8094)'>
				V
			</text>
			<text transform='matrix(0.8542 -0.52 0.52 0.8542 2241.6702 185.7332)'>
				V
			</text>
			<text transform='matrix(0.8542 -0.52 0.52 0.8542 2269.5815 169.4422)'>
				V
			</text>
			<text transform='matrix(1 0 0 1 1029.9061 730.266)'>140</text>
			<text transform='matrix(1 0 0 1 1066.3883 730.266)'>141</text>
			<text transform='matrix(1 0 0 1 1099.2236 730.266)'>142</text>
			<text transform='matrix(1 0 0 1 911.1985 730.2663)'>137</text>
			<text transform='matrix(1 0 0 1 944.0339 730.2663)'>138</text>
			<text transform='matrix(1 0 0 1 1159.8835 572.2303)'>101</text>
			<text transform='matrix(1 0 0 1 1220.9468 572.2303)'>101</text>
			<text transform='matrix(1 0 0 1 1300.5732 385.1356)'>302</text>
			<text transform='matrix(1 0 0 1 1300.5732 373.8736)'>303</text>
			<text transform='matrix(1 0 0 1 1300.5732 362.6111)'>304</text>
			<text transform='matrix(1 0 0 1 1300.5732 351.3491)'>305</text>
			<text transform='matrix(1 0 0 1 1300.5732 340.0866)'>306</text>
			<text transform='matrix(1 0 0 1 1300.5732 328.8241)'>307</text>
			<text transform='matrix(1 0 0 1 1300.5732 317.5621)'>308</text>
			<text transform='matrix(1 0 0 1 1300.5732 306.3)'>349</text>
			<text transform='matrix(1 0 0 1 1352.4834 385.136)'>357</text>
			<text transform='matrix(1 0 0 1 1352.4834 373.8735)'>356</text>
			<text transform='matrix(1 0 0 1 1352.4834 362.6115)'>355</text>
			<text transform='matrix(1 0 0 1 1352.4834 351.349)'>354</text>
			<text transform='matrix(1 0 0 1 1352.4834 340.0865)'>353</text>
			<text transform='matrix(1 0 0 1 1352.4834 328.8245)'>352</text>
			<text transform='matrix(1 0 0 1 1352.4834 317.5624)'>351</text>
			<text transform='matrix(1 0 0 1 1352.4834 305.5854)'>350</text>
			<text transform='matrix(1 0 0 1 1784.2556 373.1838)'>358</text>
			<text transform='matrix(1 0 0 1 1784.2556 361.9213)'>359</text>
			<text transform='matrix(1 0 0 1 1784.2556 350.6588)'>360</text>
			<text transform='matrix(1 0 0 1 1784.2556 339.3968)'>361</text>
			<text transform='matrix(1 0 0 1 1784.2556 328.1347)'>362</text>
			<text transform='matrix(1 0 0 1 2081.9006 172.3572)'>V</text>
			<text transform='matrix(1 0 0 1 2093.1533 172.3572)'>V</text>
			<text transform='matrix(1 0 0 1 2104.4053 172.3572)'>V</text>
			<text transform='matrix(1 0 0 1 2115.1143 172.3572)'>V</text>
			<text transform='matrix(1 0 0 1 2126.2788 172.3572)'>V</text>
			<text transform='matrix(1 0 0 1 2115.1143 225.3283)'>V</text>
			<text transform='matrix(1 0 0 1 2126.2788 225.3283)'>V</text>
			<text transform='matrix(0 -1 1 0 1826.6848 298.2358)'>363</text>
			<text transform='matrix(0 -1 1 0 1837.7567 298.2358)'>364</text>
			<text transform='matrix(0 -1 1 0 1848.8285 298.2358)'>365</text>
			<text transform='matrix(0 -1 1 0 1859.9001 298.2358)'>366</text>
			<text transform='matrix(0 -1 1 0 1941.6343 298.2358)'>367</text>
			<text transform='matrix(0 -1 1 0 1952.896 298.2358)'>368</text>
			<text transform='matrix(0 -1 1 0 1964.1567 298.2358)'>369</text>
			<text transform='matrix(0 -1 1 0 1975.4182 298.2358)'>370</text>
			<text transform='matrix(0 -1 1 0 2074.9717 228.8901)'>373</text>
			<text transform='matrix(0 -1 1 0 2074.9712 176.3765)'>372</text>
			<text transform='matrix(0 -1 1 0 2086.3853 228.8901)'>374</text>
			<text transform='matrix(0 -1 1 0 2097.7988 228.8901)'>375</text>
			<text transform='matrix(0 -1 1 0 2109.2114 228.8901)'>376</text>
			<text transform='matrix(0 -1 1 0 1986.6799 298.2358)'>371</text>
			<text transform='matrix(1 0 0 1 976.8691 730.2663)'>139</text>
			<text transform='matrix(1 0 0 1 1132.0593 730.266)'>143</text>
			<text transform='matrix(1 0 0 1 1165.8948 730.266)'>144</text>
			<text transform='matrix(1 0 0 1 1139.634 692.4633)'>115</text>
			<text transform='matrix(1 0 0 1 1172.4694 692.4633)'>116</text>
			<text transform='matrix(1 0 0 1 1205.3052 692.4633)'>117</text>
			<text transform='matrix(1 0 0 1 738.9507 693.4506)'>105</text>
			<text transform='matrix(1 0 0 1 770.9507 693.4506)'>106</text>
			<text transform='matrix(1 0 0 1 802.9507 693.4506)'>107</text>
			<text transform='matrix(1 0 0 1 863.0519 693.4506)'>108</text>
			<text transform='matrix(1 0 0 1 895.8114 693.4506)'>109</text>
			<text transform='matrix(1 0 0 1 927.8114 693.4506)'>110</text>
			<text transform='matrix(1 0 0 1 1701.4814 504.1042)'>V</text>
			<text transform='matrix(1 0 0 1 1701.4814 493.0635)'>V</text>
			<text transform='matrix(1 0 0 1 1701.4814 482.0228)'>V</text>
		</g>
	);
};

export default index;
