import React from 'react';
import SVG from './SVG';

const SiteMap = () => {
	return (
		<div className='SiteMap'>
			<div className='InteractiveMap' />
			<div className='SVG'>
				<SVG />
			</div>
		</div>
	);
};

export default SiteMap;
