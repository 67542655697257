import React from 'react';
import SCSS from '../Sales.module.scss';

const Building8 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-8'>
			<g
				onClick={() => click('B1')}
				className={checkHandler('134')}
				id='B1-134'>
				<rect
					x='646.5'
					y='764.98'
					className={SCSS.UnitBase}
					width='26.9'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 655.7745 790.8925)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 651.7115 802.2214)'
					className={SCSS.UnitNumber}>
					134
				</text>
				<circle cx='659.95' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('135')} id='B-135'>
				<rect
					x='673.4'
					y='764.98'
					className={SCSS.UnitBase}
					width='26.28'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 684.3909 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 678.3002 802.2214)'
					className={SCSS.UnitNumber}>
					135
				</text>
				<circle cx='686.53' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('136')} id='B-136'>
				<rect
					x='699.67'
					y='764.98'
					className={SCSS.UnitBase}
					width='27'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 711.03 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 704.9392 802.2214)'
					className={SCSS.UnitNumber}>
					136
				</text>
				<circle cx='713.17' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('137')} id='B-137'>
				<rect
					x='726.67'
					y='764.98'
					className={SCSS.UnitBase}
					width='26.94'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 738.0015 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 731.9106 802.2214)'
					className={SCSS.UnitNumber}>
					137
				</text>
				<circle cx='740.15' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('138')} id='B-138'>
				<rect
					x='753.62'
					y='764.98'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 765.0267 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 758.9359 802.2214)'
					className={SCSS.UnitNumber}>
					138
				</text>
				<circle cx='767.17' cy='810.37' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('B1')}
				className={checkHandler('139')}
				id='B1-139'>
				<rect
					x='780.72'
					y='764.98'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 790.1064 790.8925)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 786.0435 802.2214)'
					className={SCSS.UnitNumber}>
					139
				</text>
				<circle cx='794.28' cy='810.37' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-8-OUTLINE'>
				<path
					d='M807.83,764.98v67.01H646.5v-67.01H807.83 M809.83,762.98h-2H646.5h-2v2v67.01v2h2h161.34h2v-2v-67.01V762.98
				L809.83,762.98z'
				/>
			</g>
			<g id='BUILDING-LABEL-8' className={SCSS.BuildingLabel}>
				<rect x='699.45' y='749.85' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 706.9588 756.979)'>BUILDING 8</text>
			</g>
		</g>
	);
};

export default Building8;
