import React from 'react';
import {animateScroll as scroll} from 'react-scroll';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import {gql, useQuery} from '@apollo/client';
import ImageBackground from './SVG/ImageBackground';
import PackingLots from './SVG/PackingLots';
import Sales from './SVG/Sales/Sales';
import Rentals from './SVG/Rentals/Rentals';
import Overlay from './SVG/Overlay';
import Labels from './SVG/Labels';
import BuildingStatus from './SVG/BuildingStatus';
import Tooltips from './SVG/Tooltips';


declare global {
	interface Window {
		revapi15: any;
	}
}

const FETCH_DATA = gql`
	query NewQuery {
		units(first: 400) {
			nodes {
				title
				unitStatus
				unitBuildings {
					nodes {
						name
						description
					}
				}
				unitPhases {
					nodes {
						name
					}
				}
			}
		}
	}
`;

const SVG = () => {
	const {data, loading, error} = useQuery(FETCH_DATA);

	const click = (plan: string) => {
		console.log('plan =', plan);

		const rev = window.revapi15;
		let index: number = 1;
		switch (plan.toLowerCase()) {
			case 'a':
				index = 1;
				break;
			case 'a1':
				index = 2;
				break;
			case 'a2':
				index = 3;
				break;
			case 'b':
				index = 4;
				break;
			case 'b1':
				index = 5;
				break;
			case 'c':
				index = 6;
				break;
			case 'd':
				index = 7;
				break;
			case 'd1':
				index = 8;
				break;
			case 'd2':
				index = 9;
				break;
			case 'e':
				index = 10;
				break;
			case 'e1':
				index = 11;
				break;
			case 'e2':
				index = 12;
				break;
			case 'suite':
				index = 13;
				break;
			default:
				index = 1;
				break;
		}
		const floorplanContainer = document.getElementById('floorplan-section');
		floorplanContainer && scroll.scrollTo(floorplanContainer.offsetTop);
		rev.revshowslide(index);
	};

	if (loading) {
		return (
			<div
				style={{
					width: `100%`,
					height: `100%`,
					display: `flex`,
					alignItems: `center`,
					justifyContent: `center`,
				}}>
				<CircularProgress color='primary' />
			</div>
		);
	}

	if (error) {
		return null;
	}

	// console.log('data =', data);

	const salesData = _.filter(data.units.nodes, (n) => {
		return n.unitPhases.nodes[0].name === 'Sales';
	});

	const rentalsData = _.filter(data.units.nodes, (n) => {
		return n.unitPhases.nodes[0].name === 'Rentals';
	});

	return (
		<svg
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			viewBox='0 0 2474 1297'>
			<ImageBackground />
			<PackingLots />
			<Sales DATA={salesData} click={click} />
			<Rentals DATA={rentalsData} click={click} />
			<Labels />
			<Overlay />
			{/*<PhasesAreas />*/}
			<BuildingStatus />
			<Tooltips />
		</svg>
	);
};

export default SVG;
