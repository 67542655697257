import React from 'react';
import SCSS from '../Rentals.module.scss';

const Building45 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'disable':
				classes = SCSS.Disable;
				break;
			default:
				classes = SCSS.Availabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-45'>
			<g id='UNIT-339'>
				<polygon
					className={SCSS.UnitBase}
					points='1380.24,344.56 1417.72,344.56 1417.72,307.58 1412.19,307.58 1412.19,299.93 1383.58,299.93 
				1383.58,321.03 1380.24,321.03 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('339 C')}
					id='C-339'>
					<text
						transform='matrix(1 0 0 1 1396.6842 315.5383)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1390.7452 325.0408)'
						className={SCSS.UnitNumber}>
						339
					</text>
					<circle cx='1398.98' cy='332.2' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-340'>
				<polygon
					className={SCSS.UnitBase}
					points='1417.72,307.58 1423.26,307.58 1423.26,299.93 1450.59,299.93 1450.59,307.58 1453.25,307.58 
				1453.25,344.56 1417.72,344.56 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('340 C')}
					id='C-340'>
					<text
						transform='matrix(1 0 0 1 1433.1896 315.5383)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1427.2505 325.0408)'
						className={SCSS.UnitNumber}>
						340
					</text>
					<circle cx='1435.49' cy='332.2' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-341'>
				<polygon
					className={SCSS.UnitBase}
					points='1453.25,307.58 1456.71,307.58 1456.71,299.93 1484.39,299.93 1484.39,344.56 1453.25,344.56 			
				'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('341 C')}
					id='C-341'>
					<text
						transform='matrix(1 0 0 1 1466.5221 315.5383)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1460.583 325.0408)'
						className={SCSS.UnitNumber}>
						341
					</text>
					<circle cx='1468.82' cy='332.2' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-342'>
				<polygon
					className={SCSS.UnitBase}
					points='1484.39,299.93 1511.72,299.93 1511.72,307.58 1517.15,307.58 1517.15,344.56 1484.39,344.56 			
				'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('342 C')}
					id='C-342'>
					<text
						transform='matrix(1 0 0 1 1498.4714 315.5383)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1492.5325 325.0408)'
						className={SCSS.UnitNumber}>
						342
					</text>
					<circle cx='1500.77' cy='332.2' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-343'>
				<polygon
					className={SCSS.UnitBase}
					points='1517.15,307.58 1522.97,307.58 1522.97,299.93 1550.48,299.93 1550.48,321.84 1553.36,321.84 
				1553.36,344.56 1517.15,344.56 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('343 C')}
					id='C-343'>
					<text
						transform='matrix(1 0 0 1 1532.958 315.5383)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1527.019 325.0408)'
						className={SCSS.UnitNumber}>
						343
					</text>
					<circle cx='1535.25' cy='332.2' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-329'>
				<polygon
					className={SCSS.UnitBase}
					points='1380.24,344.56 1417.72,344.56 1417.72,381.55 1412.19,381.55 1412.19,389.2 1383.58,389.2 
				1383.58,368.09 1380.24,368.09 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('329 C')}
					id='C-329'>
					<text
						transform='matrix(1 0 0 1 1396.6842 356.8272)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1390.7452 366.3297)'
						className={SCSS.UnitNumber}>
						329
					</text>
					<circle cx='1398.98' cy='373.49' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-330'>
				<polygon
					className={SCSS.UnitBase}
					points='1417.72,381.55 1423.26,381.55 1423.26,389.2 1450.59,389.2 1450.59,381.55 1453.25,381.55 
				1453.25,344.56 1417.72,344.56 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('330 C')}
					id='C-330'>
					<text
						transform='matrix(1 0 0 1 1433.1896 356.8272)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1427.2505 366.3297)'
						className={SCSS.UnitNumber}>
						330
					</text>
					<circle cx='1435.49' cy='373.49' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-331'>
				<polygon
					className={SCSS.UnitBase}
					points='1453.25,381.55 1456.71,381.55 1456.71,389.2 1484.39,389.2 1484.39,344.56 1453.25,344.56 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('331 C')}
					id='C-331'>
					<text
						transform='matrix(1 0 0 1 1466.5221 356.8272)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1460.583 366.3297)'
						className={SCSS.UnitNumber}>
						331
					</text>
					<circle cx='1468.82' cy='373.49' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-332'>
				<polygon
					className={SCSS.UnitBase}
					points='1484.39,389.2 1511.72,389.2 1511.72,381.55 1517.15,381.55 1517.15,344.56 1484.39,344.56 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('332 C')}
					id='C-332'>
					<text
						transform='matrix(1 0 0 1 1498.4714 356.8272)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1492.5325 366.3297)'
						className={SCSS.UnitNumber}>
						332
					</text>
					<circle cx='1500.77' cy='373.49' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-333'>
				<polygon
					className={SCSS.UnitBase}
					points='1517.15,381.55 1522.97,381.55 1522.97,389.2 1550.48,389.2 1550.48,367.29 1553.36,367.29 
				1553.36,344.56 1517.15,344.56 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('333 C')}
					id='C-333'>
					<text
						transform='matrix(1 0 0 1 1532.958 356.8272)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1527.019 366.3297)'
						className={SCSS.UnitNumber}>
						333
					</text>
					<circle cx='1535.25' cy='373.49' r='3.45' className="dot" />
				</g>
			</g>
			<g id='RENTAL-BUILDING-5-OUTLINE'>
				<path
					d='M1550.48,299.93v22.32h2.88v45.04h-2.88v21.91h-27.5v-7.65h-11.25v7.65h-55.02v-7.65h-6.11v7.65h-27.34v-7.65h-11.07
				v7.65h-28.6v-21.11h-3.34v-47.06h3.34v-21.11h28.6v7.65h11.07v-7.65h27.34v7.65h6.11v-7.65h55.02v7.65h11.25v-7.65H1550.48
				 M1552.48,297.93h-2h-27.5h-2v2v5.65h-7.25v-5.65v-2h-2h-55.02h-2v2v5.65h-2.11v-5.65v-2h-2h-27.34h-2v2v5.65h-7.07v-5.65v-2h-2
				h-28.6h-2v2v19.11h-1.34h-2v2v47.06v2h2h1.34v19.11v2h2h28.6h2v-2v-5.65h7.07v5.65v2h2h27.34h2v-2v-5.65h2.11v5.65v2h2h55.02h2
				v-2v-5.65h7.25v5.65v2h2h27.5h2v-2v-19.91h0.88h2v-2v-45.04v-2h-2h-0.88v-20.32V297.93L1552.48,297.93z'
				/>
			</g>
			<g id='BUILDING-LABEL-45' className={SCSS.BuildingLabel}>
				<rect x='1441.11' y='393.41' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1446.4485 400.5446)'>BUILDING 45</text>
			</g>
		</g>
	);
};

export default Building45;
