import React from 'react';
import SCSS from '../Sales.module.scss';

const Building31 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-31_1_'>
			<g
				onClick={() => click('E1')}
				className={checkHandler('416')}
				id='E1-416'>
				<polygon
					className={SCSS.UnitBase}
					points='1715.73,787.24 1712.12,784.66 1704.03,795.98 1717.44,805.57 1712.82,812.04 1729.51,823.97 
			1734.13,817.5 1756.19,833.27 1765.53,820.21 1761.5,817.33 1764.55,813.06 1720.03,781.24 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1725.1771 806.3505)'
					className={SCSS.PlanName}>
					E1
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1730.632 815.4781)'
					className={SCSS.UnitNumber}>
					416
				</text>
				<circle cx='1746.49' cy='815.19' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('417')} id='D-417'>
				<polygon
					className={SCSS.UnitBase}
					points='1719.74,774 1723.35,776.59 1720.03,781.24 1764.55,813.06 1767.24,809.3 1771.27,812.18 
			1779.83,800.21 1727.66,762.92 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1739.3124 786.6429)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1743.9938 796.855)'
					className={SCSS.UnitNumber}>
					417
				</text>
				<circle cx='1759.85' cy='796.57' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('418')} id='E-418'>
				<polygon
					className={SCSS.UnitBase}
					points='1739.86,753.5 1736.24,750.92 1727.66,762.92 1779.83,800.21 1789.41,786.81 1785.38,783.93 
			1788.16,780.04 1743.64,748.21 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1751.5803 769.4433)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1755.8573 780.2222)'
					className={SCSS.UnitNumber}>
					418
				</text>
				<circle cx='1771.71' cy='779.93' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('419')} id='D-419'>
				<polygon
					className={SCSS.UnitBase}
					points='1743.48,740.79 1747.1,743.37 1743.64,748.21 1788.16,780.04 1790.61,776.61 1794.64,779.49 
			1803.69,766.83 1751.53,729.54 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1763.1141 753.2953)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1767.7955 763.5074)'
					className={SCSS.UnitNumber}>
					419
				</text>
				<circle cx='1783.65' cy='763.22' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('420')} id='E-420'>
				<polygon
					className={SCSS.UnitBase}
					points='1763.25,720.78 1759.63,718.2 1751.53,729.54 1803.69,766.83 1812.79,754.11 1808.76,751.23 
			1811.11,747.93 1766.59,716.1 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1774.9932 736.7314)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1779.2701 747.5103)'
					className={SCSS.UnitNumber}>
					420
				</text>
				<circle cx='1795.13' cy='747.22' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('421')} id='D-421'>
				<polygon
					className={SCSS.UnitBase}
					points='1766.87,708.07 1770.49,710.66 1766.59,716.1 1811.11,747.93 1814.02,743.87 1818.05,746.75 
			1826.78,734.54 1774.62,697.24 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1786.2369 720.9457)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1790.9183 731.1578)'
					className={SCSS.UnitNumber}>
					421
				</text>
				<circle cx='1806.77' cy='730.87' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('422')} id='E-422'>
				<polygon
					className={SCSS.UnitBase}
					points='1786.86,687.76 1783.25,685.17 1774.62,697.24 1826.78,734.54 1836.4,721.09 1832.37,718.21 
			1834.99,714.53 1790.48,682.7 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1798.4722 703.8456)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1802.7491 714.6246)'
					className={SCSS.UnitNumber}>
					422
				</text>
				<circle cx='1818.6' cy='714.34' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('423')} id='D-423'>
				<polygon
					className={SCSS.UnitBase}
					points='1841.65,713.74 1850.58,701.25 1798.41,663.96 1790.49,675.04 1794.1,677.63 1790.48,682.7 
			1834.99,714.53 1837.62,710.85 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1810.0385 687.6967)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1814.7198 697.9088)'
					className={SCSS.UnitNumber}>
					423
				</text>
				<circle cx='1830.58' cy='697.62' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('424')} id='E-424'>
				<polygon
					className={SCSS.UnitBase}
					points='1859.77,688.4 1855.74,685.51 1858.14,682.15 1813.57,650.29 1810.18,655.03 1806.62,652.48 
			1798.41,663.96 1850.58,701.25 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1821.7512 671.1376)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1826.0282 681.9166)'
					className={SCSS.UnitNumber}>
					424
				</text>
				<circle cx='1841.88' cy='681.63' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('D1')}
				className={checkHandler('425')}
				id='D1-425'>
				<polygon
					className={SCSS.UnitBase}
					points='1861,678.16 1865.03,681.04 1874.1,668.36 1852.04,652.59 1853.99,649.86 1837.3,637.93 
			1835.35,640.65 1821.93,631.06 1813.37,643.04 1816.93,645.59 1813.57,650.29 1858.14,682.15 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1833.0219 655.5806)'
					className={SCSS.PlanName}>
					D1
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1838.8805 664.1422)'
					className={SCSS.UnitNumber}>
					425
				</text>
				<circle cx='1854.74' cy='663.85' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-31-OUTLINE_1_'>
				<path
					d='M1821.93,631.06l13.42,9.59l1.95-2.72l16.69,11.93l-1.95,2.72l22.06,15.77l-9.07,12.69l-4.03-2.88l-2.85,3.99l-2.4,3.36
			l4.03,2.88l-9.19,12.86l-8.92,12.48l-4.03-2.88l-2.63,3.68l-2.63,3.68l4.03,2.88l-9.61,13.45l-8.73,12.22l-4.03-2.88l-2.9,4.06
			l-2.35,3.29l4.03,2.88l-9.1,12.72l-9.05,12.66l-4.03-2.88l-2.45,3.43l-2.78,3.89l4.03,2.88l-9.58,13.4l-8.56,11.97l-4.03-2.88
			l-2.69,3.77l-3.05,4.26l4.03,2.88l-9.34,13.06l-22.06-15.77l-4.62,6.47l-16.69-11.93l4.62-6.47l-13.42-9.59l8.09-11.32l3.62,2.58
			l4.3-6.01l3.32-4.65l-3.62-2.58l7.93-11.09l8.58-12l3.62,2.58l3.79-5.3l3.46-4.83l-3.62-2.58l8.05-11.25l8.1-11.34l3.62,2.58
			l3.35-4.68l3.89-5.45l-3.62-2.58l7.74-10.83l8.63-12.07l3.62,2.58l3.61-5.06l3.63-5.07l-3.62-2.58l7.93-11.08l8.2-11.47
			l3.57,2.55l3.39-4.74l3.36-4.7l-3.57-2.55L1821.93,631.06 M1821.47,628.27l-1.16,1.63l-8.57,11.98l-1.16,1.63l1.63,1.16
			l1.94,1.39l-2.19,3.07l-2.23,3.12l-1.94-1.39l-1.63-1.16l-1.16,1.63l-8.2,11.47l-7.93,11.08l-1.16,1.63l1.63,1.16l1.99,1.42
			l-2.46,3.45l-2.45,3.43l-1.99-1.42l-1.63-1.16l-1.16,1.63l-8.63,12.07l-7.74,10.83l-1.16,1.63l1.63,1.16l1.99,1.42l-2.73,3.82
			l-2.18,3.05l-1.99-1.42l-1.63-1.16l-1.16,1.63l-8.1,11.34l-8.05,11.25l-1.16,1.63l1.63,1.16l1.99,1.42l-2.29,3.21l-2.62,3.67
			l-1.99-1.42l-1.63-1.16l-1.16,1.63l-8.58,12l-7.93,11.09l-1.16,1.63l1.63,1.16l1.99,1.42l-2.16,3.02l-3.13,4.38l-1.99-1.42
			l-1.63-1.16l-1.16,1.63l-8.09,11.32l-1.16,1.63l1.63,1.16l11.79,8.43l-3.46,4.84l-1.16,1.63l1.63,1.16l16.69,11.93l1.63,1.16
			l1.16-1.63l3.46-4.84l20.43,14.61l1.63,1.16l1.16-1.63l9.34-13.06l1.16-1.63l-1.63-1.16l-2.4-1.72l1.88-2.64l1.53-2.14l2.4,1.72
			l1.63,1.16l1.16-1.63l8.56-11.97l9.58-13.4l1.16-1.63l-1.63-1.16l-2.4-1.72l1.62-2.26l1.29-1.8l2.4,1.72l1.63,1.16l1.16-1.63
			l9.05-12.66l9.1-12.72l1.16-1.63l-1.63-1.16l-2.4-1.72l1.19-1.67l1.74-2.43l2.4,1.72l1.63,1.16l1.16-1.63l8.73-12.22l9.61-13.45
			l1.16-1.63l-1.63-1.16l-2.4-1.72l1.47-2.05l1.47-2.05l2.4,1.72l1.63,1.16l1.16-1.63l8.92-12.48l9.19-12.86l1.16-1.63l-1.63-1.16
			l-2.4-1.72l1.24-1.73l1.69-2.37l2.4,1.72l1.63,1.16l1.16-1.63l9.07-12.69l1.16-1.63l-1.63-1.16l-20.43-14.61l0.78-1.1l1.16-1.63
			l-1.63-1.16l-16.69-11.93l-1.63-1.16l-1.16,1.63l-0.78,1.1l-11.79-8.43L1821.47,628.27L1821.47,628.27z'
				/>
			</g>
			<g id='BUILDING-LABEL-31' className={SCSS.BuildingLabel}>
				<rect
					x='1721.36'
					y='710.66'
					transform='matrix(0.5816 -0.8135 0.8135 0.5816 149.7857 1722.1808)'
					width='55.42'
					height='9.64'
				/>
				<text transform='matrix(0.5816 -0.8135 0.8135 0.5816 1737.8999 735.0431)'>
					BUILDING 31
				</text>
			</g>
		</g>
	);
};

export default Building31;
