import React from 'react';
import SCSS from '../Sales.module.scss';

const Building38 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-38_1_'>
			<g
				onClick={() => click('D2')}
				className={checkHandler('484')}
				id='D2-484'>
				<polygon
					className={SCSS.UnitBase}
					points='1834.37,392.74 1829.16,389.01 1820.22,401.52 1841.96,417.06 1840.15,419.6 1856.84,431.53 
			1858.65,428.99 1872.74,439.07 1884.62,422.46 1837.3,388.64 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1845.2382 412.2261)'
					className={SCSS.PlanName}>
					D2
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1851.0968 420.7877)'
					className={SCSS.UnitNumber}>
					484
				</text>
				<circle cx='1866.95' cy='420.5' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('485')} id='E-485'>
				<polygon
					className={SCSS.UnitBase}
					points='1846.42,375.88 1841.22,372.15 1832.1,384.91 1837.3,388.64 1837.3,388.64 1884.62,422.46 
			1893.13,410.55 1890.21,408.46 1893.56,403.78 1849.16,372.05 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1858.5288 393.6562)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1862.8058 404.4351)'
					className={SCSS.UnitNumber}>
					485
				</text>
				<circle cx='1878.66' cy='404.15' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('486')} id='D-486'>
				<polygon
					className={SCSS.UnitBase}
					points='1846.7,364.49 1851.9,368.21 1849.16,372.05 1893.56,403.78 1897.13,398.79 1900.05,400.88 
			1907.88,389.93 1855.36,352.38 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1869.7286 378.0082)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1874.41 388.2203)'
					className={SCSS.UnitNumber}>
					486
				</text>
				<circle cx='1890.27' cy='387.93' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('487')} id='E-487'>
				<polygon
					className={SCSS.UnitBase}
					points='1872.93,338.81 1869.87,343.08 1864.67,339.36 1855.36,352.38 1907.88,389.93 1916.98,377.2 
			1914.06,375.11 1917.32,370.55 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1882.2156 360.5781)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1886.4926 371.357)'
					className={SCSS.UnitNumber}>
					487
				</text>
				<circle cx='1902.35' cy='371.07' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('488')} id='D-488'>
				<polygon
					className={SCSS.UnitBase}
					points='1920.97,365.43 1923.9,367.52 1931.93,356.28 1929.08,354.25 1929.1,354.22 1884.63,322.43 
			1884.62,322.46 1879.41,318.73 1870.15,331.69 1875.35,335.42 1872.93,338.81 1917.32,370.55 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1893.6339 344.5404)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1898.3153 354.7525)'
					className={SCSS.UnitNumber}>
					488
				</text>
				<circle cx='1914.17' cy='354.46' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('E2')}
				className={checkHandler('489')}
				id='E2-489'>
				<polygon
					className={SCSS.UnitBase}
					points='1935.63,345.09 1938.48,347.13 1947.11,335.05 1933.02,324.98 1934.74,322.58 1918.05,310.64 
			1916.33,313.05 1894.59,297.5 1884,312.32 1889.2,316.04 1884.63,322.43 1929.1,354.22 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1906.494 326.5252)'
					className={SCSS.PlanName}>
					E2
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1911.9489 335.6529)'
					className={SCSS.UnitNumber}>
					489
				</text>
				<circle cx='1927.8' cy='335.36' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-38-OUTLINE_1_'>
				<path
					d='M1894.59,297.5l21.74,15.54l1.72-2.4l16.69,11.93l-1.72,2.4l14.09,10.07l-8.63,12.08l-2.85-2.04l-6.53,9.13l-0.02,0.03
			l2.85,2.04l-8.04,11.24l-2.92-2.09l-3.66,5.11l-3.26,4.56l2.92,2.09l-9.1,12.73l-7.83,10.95l-2.92-2.09l-3.57,5l-3.34,4.68
			l2.92,2.09l-8.52,11.91l-11.87,16.6l-14.09-10.08l-1.82,2.54l-16.69-11.93l1.82-2.54l-21.74-15.54l8.94-12.51l5.21,3.72l2.93-4.1
			l-5.21-3.72l9.12-12.76l5.21,3.72l2.74-3.83l2.74-3.83l-5.21-3.72l8.66-12.11l9.31-13.02l5.21,3.72l3.05-4.27l2.43-3.39
			l-5.21-3.72l9.26-12.96l5.21,3.72l0.02-0.03l4.57-6.39l-5.21-3.72L1894.59,297.5 M1894.13,294.71l-1.16,1.63l-10.59,14.82
			l-1.16,1.63l1.63,1.16l3.58,2.56l-2.26,3.16l-3.58-2.56l-1.63-1.16l-1.16,1.63l-9.26,12.96l-1.16,1.63l1.63,1.16l3.58,2.56
			l-1.26,1.77l-1.89,2.64l-3.58-2.56l-1.63-1.16l-1.16,1.63l-9.31,13.02l-8.66,12.11l-1.16,1.63l1.63,1.16l3.58,2.56l-1.58,2.2
			l-1.58,2.2l-3.58-2.56l-1.63-1.16l-1.16,1.63l-9.12,12.76l-1.16,1.63l1.63,1.16l3.58,2.56l-0.6,0.85l-3.58-2.56l-1.63-1.16
			l-1.16,1.63l-8.94,12.51l-1.16,1.63l1.63,1.16l20.11,14.38l-0.65,0.91l-1.16,1.63l1.63,1.16l16.69,11.93l1.63,1.16l1.16-1.63
			l0.65-0.91l12.47,8.91l1.63,1.16l1.16-1.63l11.87-16.6l8.52-11.91l1.16-1.63l-1.63-1.16L1893,408l2.18-3.05l2.41-3.37l1.3,0.93
			l1.63,1.16l1.16-1.63l7.83-10.95l9.1-12.73l1.16-1.63l-1.63-1.16l-1.3-0.93l2.1-2.93l2.49-3.49l1.3,0.93l1.63,1.16l1.16-1.63
			l8.04-11.24l1.16-1.63l-1.63-1.16l-1.22-0.87l4.22-5.9l1.22,0.87l1.63,1.16l1.16-1.63l8.63-12.08l1.16-1.63l-1.63-1.16
			l-12.46-8.91l0.56-0.78l1.16-1.63l-1.63-1.16l-16.69-11.93l-1.63-1.16l-1.16,1.63l-0.56,0.78l-20.11-14.38L1894.13,294.71
			L1894.13,294.71z'
				/>
			</g>
			<g id='BUILDING-LABEL-38' className={SCSS.BuildingLabel}>
				<rect
					x='1884.23'
					y='400.52'
					transform='matrix(0.5816 -0.8135 0.8135 0.5816 470.2253 1724.9072)'
					width='55.42'
					height='9.64'
				/>
				<text transform='matrix(0.5816 -0.8135 0.8135 0.5816 1900.77 424.8985)'>
					BUILDING 38
				</text>
			</g>
		</g>
	);
};

export default Building38;
