import React from 'react';
import SCSS from '../Sales.module.scss';

const Building10 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-10'>
			<g
				onClick={() => click('B1')}
				className={checkHandler('146')}
				id='B1-146'>
				<rect
					x='1050.71'
					y='764.98'
					className={SCSS.UnitBase}
					width='26.28'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1059.6799 790.8925)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 1055.6169 802.2214)'
					className={SCSS.UnitNumber}>
					146
				</text>
				<circle cx='1063.85' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('147')} id='B-147'>
				<rect
					x='1076.99'
					y='764.98'
					className={SCSS.UnitBase}
					width='27'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1088.3469 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 1082.2561 802.2214)'
					className={SCSS.UnitNumber}>
					147
				</text>
				<circle cx='1090.49' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('148')} id='B-148'>
				<rect
					x='1103.99'
					y='764.98'
					className={SCSS.UnitBase}
					width='26.94'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1115.3184 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 1109.2275 802.2214)'
					className={SCSS.UnitNumber}>
					148
				</text>
				<circle cx='1117.46' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('149')} id='B-149'>
				<rect
					x='1130.93'
					y='764.98'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1142.3436 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 1136.2528 802.2214)'
					className={SCSS.UnitNumber}>
					149
				</text>
				<circle cx='1144.49' cy='810.37' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('B1')}
				className={checkHandler('150')}
				id='B1-150'>
				<rect
					x='1158.04'
					y='764.98'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1167.4233 790.8925)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 1163.3604 802.2214)'
					className={SCSS.UnitNumber}>
					150
				</text>
				<circle cx='1171.6' cy='810.37' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-10-OUTLINE'>
				<path
					d='M1185.15,764.98v67.01h-133.94v-67.01H1185.15 M1187.15,762.98h-2h-133.94h-2v2v67.01v2h2h133.94h2v-2v-67.01V762.98
					L1187.15,762.98z'
				/>
			</g>
			<g id='BUILDING-LABEL-10' className={SCSS.BuildingLabel}>
				<rect x='1076.67' y='749.85' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1082.0096 756.979)'>BUILDING 10</text>
			</g>
		</g>
	);
};

export default Building10;
