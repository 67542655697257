import React, {useEffect} from 'react';
import gsap from 'gsap';
import _ from 'lodash';
import Building41 from './Buildings/Building41';
import Building42 from './Buildings/Building42';
import Building43 from './Buildings/Building43';
import Building44 from './Buildings/Building44';
import Building45 from './Buildings/Building45';
import Building46 from './Buildings/Building46';
import Building47 from './Buildings/Building47';
import Building48 from './Buildings/Building48';
import Building49 from './Buildings/Building49';
import Building50 from './Buildings/Building50';
import Building51 from './Buildings/Building51';
import Building52 from './Buildings/Building52';

const Rentals = (props: any) => {
	const {DATA, click} = props;
	const checkRentalStatus = (name: string) => {
		const unit = _.find(DATA, {title: name});
		return unit ? unit.unitStatus : '';
	};



	useEffect(()=>{
		const dots = gsap.utils.toArray(`.dot`);
		gsap.set(dots, {
			scale: 0,
			transformOrigin: "center",
		})
		gsap.to(dots,  {
			scale: 1.75,
			duration: 0.5,
			ease: `back.out`,
			stagger: 0.0025,
			transformOrigin: "center",

		})
	}, [])

	return (
		<g id='UNITS-RENTAL'>
			<Building41 check={checkRentalStatus} click={click} />
			<Building42 check={checkRentalStatus} click={click} />
			<Building43 check={checkRentalStatus} click={click} />
			<Building44 check={checkRentalStatus} click={click} />
			<Building45 check={checkRentalStatus} click={click} />
			<Building46 check={checkRentalStatus} click={click} />
			<Building47 check={checkRentalStatus} click={click} />
			<Building48 check={checkRentalStatus} click={click} />
			<Building49 check={checkRentalStatus} click={click} />
			<Building50 check={checkRentalStatus} click={click} />
			<Building51 check={checkRentalStatus} click={click} />
			<Building52 check={checkRentalStatus} click={click} />
		</g>
	);
};

export default Rentals;
