import React from 'react';
import SCSS from '../Sales.module.scss';

const Building11 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-11'>
			<g
				onClick={() => click('B1')}
				className={checkHandler('151')}
				id='B1-151'>
				<rect
					x='1204.38'
					y='764.98'
					className={SCSS.UnitBase}
					width='26.9'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1213.6621 790.8925)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 1209.5991 802.2214)'
					className={SCSS.UnitNumber}>
					151
				</text>
				<circle cx='1217.83' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('152')} id='B-152'>
				<rect
					x='1231.28'
					y='764.98'
					className={SCSS.UnitBase}
					width='26.28'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1242.2786 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 1236.1877 802.2214)'
					className={SCSS.UnitNumber}>
					152
				</text>
				<circle cx='1244.42' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('153')} id='B-153'>
				<rect
					x='1257.56'
					y='764.98'
					className={SCSS.UnitBase}
					width='27'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1268.9176 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 1262.8268 802.2214)'
					className={SCSS.UnitNumber}>
					153
				</text>
				<circle cx='1271.06' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('154')} id='B-154'>
				<rect
					x='1284.56'
					y='764.98'
					className={SCSS.UnitBase}
					width='26.94'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1295.889 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 1289.7982 802.2214)'
					className={SCSS.UnitNumber}>
					154
				</text>
				<circle cx='1298.03' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('155')} id='B-155'>
				<rect
					x='1311.5'
					y='764.98'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1322.9143 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 1316.8235 802.2214)'
					className={SCSS.UnitNumber}>
					155
				</text>
				<circle cx='1325.06' cy='810.37' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('B1')}
				className={checkHandler('156')}
				id='B1-156'>
				<rect
					x='1338.61'
					y='764.98'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1347.994 790.8925)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 1343.931 802.2214)'
					className={SCSS.UnitNumber}>
					156
				</text>
				<circle cx='1352.17' cy='810.37' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-11-OUTLINE'>
				<path
					d='M1365.72,764.98v67.01h-161.34v-67.01H1365.72 M1367.72,762.98h-2h-161.34h-2v2v67.01v2h2h161.34h2v-2v-67.01V762.98
				L1367.72,762.98z'
				/>
			</g>
			<g id='BUILDING-LABEL-11' className={SCSS.BuildingLabel}>
				<rect x='1257.34' y='749.85' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1262.6848 756.979)'>BUILDING 11</text>
			</g>
		</g>
	);
};

export default Building11;
