import React from 'react';
import SCSS from '../Sales.module.scss';

const Building26 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-26'>
			<g
				onClick={() => click('A2')}
				className={checkHandler('232')}
				id='A2-232'>
				<polygon
					className={SCSS.UnitBase}
					points='1052.21,1095.31 1024.31,1095.31 1024.31,1117.15 1022.35,1117.15 1022.35,1129.56 
			1024.31,1129.56 1024.31,1145.94 1026.22,1145.94 1026.22,1148.13 1040.75,1148.13 1040.75,1145.94 1052.21,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 1034.3104 1112.4325)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 1030.5277 1123.7615)'
					className={SCSS.UnitNumber}>
					232
				</text>
				<circle cx='1038.76' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('233')} id='A-233'>
				<polygon
					className={SCSS.UnitBase}
					points='1078.49,1095.31 1052.21,1095.31 1052.21,1145.94 1063.08,1145.94 1063.08,1148.13 
			1076.61,1148.13 1076.61,1145.94 1078.49,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 1062.9269 1112.4325)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1057.1163 1123.7615)'
					className={SCSS.UnitNumber}>
					233
				</text>
				<circle cx='1065.35' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('234')} id='A-234'>
				<polygon
					className={SCSS.UnitBase}
					points='1105.49,1095.31 1078.49,1095.31 1078.49,1145.94 1090.16,1145.94 1090.16,1148.13 1103.7,1148.13 
			1103.7,1145.94 1105.49,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 1089.5659 1112.4325)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1083.7554 1123.7615)'
					className={SCSS.UnitNumber}>
					234
				</text>
				<circle cx='1091.99' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('235')} id='A-235'>
				<polygon
					className={SCSS.UnitBase}
					points='1132.43,1095.31 1105.49,1095.31 1105.49,1145.94 1107.34,1145.94 1107.34,1148.13 
			1120.87,1148.13 1120.87,1145.94 1132.43,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 1116.5374 1112.4325)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1110.7268 1123.7615)'
					className={SCSS.UnitNumber}>
					235
				</text>
				<circle cx='1118.96' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('236')} id='A-236'>
				<polygon
					className={SCSS.UnitBase}
					points='1159.38,1095.31 1132.43,1095.31 1132.43,1145.94 1134.28,1145.94 1134.28,1148.13 
			1147.81,1148.13 1147.81,1145.94 1159.38,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 1143.4805 1112.4325)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1137.6699 1123.7615)'
					className={SCSS.UnitNumber}>
					236
				</text>
				<circle cx='1145.9' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A2')}
				className={checkHandler('237')}
				id='A2-237'>
				<polygon
					className={SCSS.UnitBase}
					points='1189.32,1116.15 1186.48,1116.15 1186.48,1095.31 1159.38,1095.31 1159.38,1145.94 
			1170.59,1145.94 1170.59,1148.13 1184.13,1148.13 1184.13,1145.94 1186.48,1145.94 1186.48,1129.56 1189.32,1129.56 			'
				/>
				<text
					transform='matrix(1 0 0 1 1168.4778 1112.4325)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 1164.6951 1123.7615)'
					className={SCSS.UnitNumber}>
					237
				</text>
				<circle cx='1172.93' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-26-OUTLINE'>
				<path
					d='M1186.48,1095.31v20.84h2.83v13.4h-2.83v16.38h-2.36v2.19h-13.53v-2.19h-22.78v2.19h-13.53v-2.19h-13.41v2.19h-13.53
			v-2.19h-3.64v2.19h-13.53v-2.19h-13.56v2.19h-13.53v-2.19h-22.32v2.19h-14.53v-2.19h-1.91v-16.38h-1.97v-12.4h1.97v-21.84
			H1186.48 M1188.48,1093.31h-2h-162.17h-2v2v19.84h-1.97v2v12.4v2h1.97v14.38v2h1.91v0.19v2h2h14.53h2v-2v-0.19h18.32v0.19v2h2
			h13.53h2v-2v-0.19h9.56v0.19v2h2h13.53h2h1.64h13.53h2v-2v-0.19h9.41v0.19v2h2h13.53h2v-2v-0.19h18.78v0.19v2h2h13.53h2v-2v-0.19
			h0.36h2v-2v-14.38h0.83h2v-2v-13.4v-2h-2h-0.83v-18.84V1093.31L1188.48,1093.31z'
				/>
			</g>
			<g id='BUILDING-LABEL-26' className={SCSS.BuildingLabel}>
				<rect x='1078.12' y='1080.29' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1083.4642 1087.4181)'>BUILDING 26</text>
			</g>
		</g>
	);
};

export default Building26;
