import React from 'react';
import SCSS from '../Sales.module.scss';

const Building4 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-4'>
			<g
				onClick={() => click('B1')}
				className={checkHandler('114')}
				id='B1-114'>
				<rect
					x='971'
					y='593.34'
					className={SCSS.UnitBase}
					width='26.9'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 980.2813 619.2469)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 976.2183 630.5758)'
					className={SCSS.UnitNumber}>
					114
				</text>
				<circle cx='984.45' cy='638.73' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('115')} id='B-115'>
				<rect
					x='997.9'
					y='593.34'
					className={SCSS.UnitBase}
					width='26.28'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1008.8977 619.2469)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 1002.8069 630.5758)'
					className={SCSS.UnitNumber}>
					115
				</text>
				<circle cx='1011.04' cy='638.73' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('116')} id='B-116'>
				<rect
					x='1024.18'
					y='593.34'
					className={SCSS.UnitBase}
					width='27'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1035.5369 619.2469)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 1029.446 630.5758)'
					className={SCSS.UnitNumber}>
					116
				</text>
				<circle cx='1037.68' cy='638.73' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('117')} id='B-117'>
				<rect
					x='1051.18'
					y='593.34'
					className={SCSS.UnitBase}
					width='26.94'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1062.5083 619.2469)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 1056.4175 630.5758)'
					className={SCSS.UnitNumber}>
					117
				</text>
				<circle cx='1064.65' cy='638.73' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('B1')}
				className={checkHandler('118')}
				id='B1-118'>
				<rect
					x='1078.12'
					y='593.34'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1087.5056 619.2469)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 1083.4426 630.5758)'
					className={SCSS.UnitNumber}>
					118
				</text>
				<circle cx='1091.68' cy='638.73' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-4-OUTLINE'>
				<path
					d='M1105.23,593.34v67.01H971v-67.01H1105.23 M1107.23,591.34h-2H971h-2v2v67.01v2h2h134.23h2v-2v-67.01V591.34
				L1107.23,591.34z'
				/>
			</g>
			<g id='BUILDING-LABEL-4' className={SCSS.BuildingLabel}>
				<rect x='1010.41' y='664.58' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1017.9117 671.7123)'>BUILDING 4</text>
			</g>
		</g>
	);
};

export default Building4;
