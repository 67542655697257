import React from 'react';
import SCSS from '../Sales.module.scss';

const Building16 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-16'>
			<g
				onClick={() => click('A2')}
				className={checkHandler('180')}
				id='A2-180'>
				<polygon
					className={SCSS.UnitBase}
					points='1231.67,892.48 1203.77,892.48 1203.77,914.32 1201.8,914.32 1201.8,926.73 1203.77,926.73 
				1203.77,943.11 1205.67,943.11 1205.67,945.3 1220.21,945.3 1220.21,943.11 1231.67,943.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 1213.7642 909.6046)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 1209.9814 920.9335)'
					className={SCSS.UnitNumber}>
					180
				</text>
				<circle cx='1218.22' cy='929.09' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('181')} id='A-181'>
				<polygon
					className={SCSS.UnitBase}
					points='1257.94,892.48 1231.67,892.48 1231.67,943.11 1242.53,943.11 1242.53,945.3 1256.06,945.3 
				1256.06,943.11 1257.94,943.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 1242.3806 909.6046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1236.5701 920.9335)'
					className={SCSS.UnitNumber}>
					181
				</text>
				<circle cx='1244.8' cy='929.09' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('182')} id='A-182'>
				<polygon
					className={SCSS.UnitBase}
					points='1284.94,892.48 1257.94,892.48 1257.94,943.11 1269.62,943.11 1269.62,945.3 1283.15,945.3 
				1283.15,943.11 1284.94,943.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 1269.0198 909.6046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1263.2092 920.9335)'
					className={SCSS.UnitNumber}>
					182
				</text>
				<circle cx='1271.44' cy='929.09' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('183')} id='A-183'>
				<polygon
					className={SCSS.UnitBase}
					points='1311.89,892.48 1284.94,892.48 1284.94,943.11 1286.79,943.11 1286.79,945.3 1300.32,945.3 
				1300.32,943.11 1311.89,943.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 1295.9912 909.6046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1290.1807 920.9335)'
					className={SCSS.UnitNumber}>
					183
				</text>
				<circle cx='1298.42' cy='929.09' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A1')}
				className={checkHandler('184')}
				id='A1-184'>
				<polygon
					className={SCSS.UnitBase}
					points='1341.83,913.32 1338.99,913.32 1338.99,892.48 1311.89,892.48 1311.89,943.11 1323.1,943.11 
				1323.1,945.3 1336.64,945.3 1336.64,943.11 1338.99,943.11 1338.99,926.73 1341.83,926.73 			'
				/>
				<text
					transform='matrix(1 0 0 1 1320.9885 909.6046)'
					className={SCSS.PlanName}>
					A1
				</text>
				<text
					transform='matrix(1 0 0 1 1317.2058 920.9335)'
					className={SCSS.UnitNumber}>
					184
				</text>
				<circle cx='1325.44' cy='929.09' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-16-OUTLINE'>
				<path
					d='M1338.99,892.48v20.84h2.83v13.4h-2.83v16.38h-2.36v2.19h-13.53v-2.19h-22.78v2.19h-13.53v-2.19h-3.64v2.19h-13.53v-2.19
				h-13.56v2.19h-13.53v-2.19h-22.32v2.19h-14.53v-2.19h-1.91v-16.38h-1.97v-12.4h1.97v-21.84H1338.99 M1340.99,890.48h-2h-135.23
				h-2v2v19.84h-1.97v2v12.4v2h1.97v14.38v2h1.91v0.19v2h2h14.53h2v-2v-0.19h18.32v0.19v2h2h13.53h2v-2v-0.19h9.56v0.19v2h2h13.53h2
				h1.64h13.53h2v-2v-0.19h18.78v0.19v2h2h13.53h2v-2v-0.19h0.36h2v-2v-14.38h0.83h2v-2v-13.4v-2h-2h-0.83v-18.84V890.48
				L1340.99,890.48z'
				/>
			</g>
			<g id='BUILDING-LABEL-16' className={SCSS.BuildingLabel}>
				<rect x='1244.1' y='876.29' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1249.4465 883.4219)'>BUILDING 16</text>
			</g>
		</g>
	);
};

export default Building16;
