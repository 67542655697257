import React from 'react';
import SCSS from './Overlay.module.scss';

const index = () => {
	return (
		<g id='OVERLAY'>
			<path
				className={SCSS.Overlay}
				d='M2398,128.5h-284v107h-49.49v-28H2061c-29,28-6,58-6,58l-24,16c-13-13-41-2-41-2v28h-59.47v-28H1864v27.54h-47V279.5
				c-40,0-40.77,38.04-40.77,38.04h28.89v59.96H1777c0,29-32,51-50,54s-36,17-36,39.24c0,0.33,0,0.65,0,0.97h26.9v36.81H1691
				c0,5.92,0,9.93,0,16.98c0,19-12,64.81-60,64.81s-495,0-495,0v-16.37H542.34v103.41H418v488.01h924.59l12.41-16.86h191l495-697
				l403-244L2398,128.5z'
			/>
		</g>
	);
};

export default index;
