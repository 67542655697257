import React from 'react';
import SCSS from '../Sales.module.scss';

const Building21 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-21'>
			<g
				onClick={() => click('A2')}
				className={checkHandler('208')}
				id='A2-208'>
				<polygon
					className={SCSS.UnitBase}
					points='1231.67,1035.87 1203.77,1035.87 1203.77,1014.03 1201.8,1014.03 1201.8,1001.62 1203.77,1001.62 
				1203.77,985.24 1205.67,985.24 1205.67,983.05 1220.21,983.05 1220.21,985.24 1231.67,985.24 			'
				/>
				<text
					transform='matrix(1 0 0 1 1213.7642 999.5665)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 1209.9814 1010.8955)'
					className={SCSS.UnitNumber}>
					208
				</text>
				<circle cx='1218.22' cy='1019.05' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('209')} id='A-209'>
				<polygon
					className={SCSS.UnitBase}
					points='1257.94,1035.87 1231.67,1035.87 1231.67,985.24 1242.53,985.24 1242.53,983.05 1256.06,983.05 
				1256.06,985.24 1257.94,985.24 			'
				/>
				<text
					transform='matrix(1 0 0 1 1242.3806 999.5665)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1236.5701 1010.8955)'
					className={SCSS.UnitNumber}>
					209
				</text>
				<circle cx='1244.8' cy='1019.05' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('210')} id='A-210'>
				<polygon
					className={SCSS.UnitBase}
					points='1284.94,1035.87 1257.94,1035.87 1257.94,985.24 1269.62,985.24 1269.62,983.05 1283.15,983.05 
				1283.15,985.24 1284.94,985.24 			'
				/>
				<text
					transform='matrix(1 0 0 1 1269.0198 999.5665)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1263.2092 1010.8955)'
					className={SCSS.UnitNumber}>
					210
				</text>
				<circle cx='1271.44' cy='1019.05' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('211')} id='A-211'>
				<polygon
					className={SCSS.UnitBase}
					points='1311.89,1035.87 1284.94,1035.87 1284.94,985.24 1286.79,985.24 1286.79,983.05 1300.32,983.05 
				1300.32,985.24 1311.89,985.24 			'
				/>
				<text
					transform='matrix(1 0 0 1 1295.9912 999.5665)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1290.1807 1010.8955)'
					className={SCSS.UnitNumber}>
					211
				</text>
				<circle cx='1298.42' cy='1019.05' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A1')}
				className={checkHandler('212')}
				id='A1-212'>
				<polygon
					className={SCSS.UnitBase}
					points='1341.83,1015.03 1338.99,1015.03 1338.99,1035.87 1311.89,1035.87 1311.89,985.24 1323.1,985.24 
				1323.1,983.05 1336.64,983.05 1336.64,985.24 1338.99,985.24 1338.99,1001.62 1341.83,1001.62 			'
				/>
				<text
					transform='matrix(1 0 0 1 1320.9885 999.5665)'
					className={SCSS.PlanName}>
					A1
				</text>
				<text
					transform='matrix(1 0 0 1 1317.2058 1010.8955)'
					className={SCSS.UnitNumber}>
					212
				</text>
				<circle cx='1325.44' cy='1019.05' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-21-OUTLINE'>
				<path
					d='M1336.64,983.05v2.19h2.36v16.38h2.83v13.4h-2.83v20.84h-135.23v-21.84h-1.97v-12.4h1.97v-16.38h1.91v-2.19h14.53v2.19
				h22.32v-2.19h13.53v2.19h13.56v-2.19h13.53v2.19h3.64v-2.19h13.53v2.19h22.78v-2.19H1336.64 M1338.64,981.05h-2h-13.53h-2v2v0.19
				h-18.78v-0.19v-2h-2h-13.53h-1.64h-2h-13.53h-2v2v0.19h-9.56v-0.19v-2h-2h-13.53h-2v2v0.19h-18.32v-0.19v-2h-2h-14.53h-2v2v0.19
				h-1.91v2v14.38h-1.97v2v12.4v2h1.97v19.84v2h2h135.23h2v-2v-18.84h0.83h2v-2v-13.4v-2h-2h-0.83v-14.38v-2h-2h-0.36v-0.19V981.05
				L1338.64,981.05z'
				/>
			</g>
			<g id='BUILDING-LABEL-21' className={SCSS.BuildingLabel}>
				<rect x='1244.1' y='1040.95' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1249.4465 1048.0767)'>BUILDING 21</text>
			</g>
		</g>
	);
};

export default Building21;
