import React from 'react';
import SCSS from '../Sales.module.scss';

const Building20 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-20'>
			<g
				onClick={() => click('A2')}
				className={checkHandler('202')}
				id='A2-202'>
				<polygon
					className={SCSS.UnitBase}
					points='1051.21,1035.56 1023.31,1035.56 1023.31,1013.72 1021.35,1013.72 1021.35,1001.32 
				1023.31,1001.32 1023.31,984.94 1025.22,984.94 1025.22,982.75 1039.75,982.75 1039.75,984.94 1051.21,984.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 1033.3104 999.8704)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 1029.5277 1011.1993)'
					className={SCSS.UnitNumber}>
					202
				</text>
				<circle cx='1037.76' cy='1019.35' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('203')} id='A-203'>
				<polygon
					className={SCSS.UnitBase}
					points='1077.49,1035.56 1051.21,1035.56 1051.21,984.94 1062.08,984.94 1062.08,982.75 1075.61,982.75 
				1075.61,984.94 1077.49,984.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 1061.9269 999.8704)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1056.1163 1011.1993)'
					className={SCSS.UnitNumber}>
					203
				</text>
				<circle cx='1064.35' cy='1019.35' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('204')} id='A-204'>
				<polygon
					className={SCSS.UnitBase}
					points='1104.49,1035.56 1077.49,1035.56 1077.49,984.94 1089.16,984.94 1089.16,982.75 1102.7,982.75 
				1102.7,984.94 1104.49,984.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 1088.5659 999.8704)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1082.7554 1011.1993)'
					className={SCSS.UnitNumber}>
					204
				</text>
				<circle cx='1090.99' cy='1019.35' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('205')} id='A-205'>
				<polygon
					className={SCSS.UnitBase}
					points='1131.43,1035.56 1104.49,1035.56 1104.49,984.94 1106.34,984.94 1106.34,982.75 1119.87,982.75 
				1119.87,984.94 1131.43,984.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 1115.5374 999.8704)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1109.7268 1011.1993)'
					className={SCSS.UnitNumber}>
					205
				</text>
				<circle cx='1117.96' cy='1019.35' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('206')} id='A-206'>
				<polygon
					className={SCSS.UnitBase}
					points='1158.38,1035.56 1131.43,1035.56 1131.43,984.94 1133.28,984.94 1133.28,982.75 1146.81,982.75 
				1146.81,984.94 1158.38,984.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 1142.4805 999.8704)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1136.6699 1011.1993)'
					className={SCSS.UnitNumber}>
					206
				</text>
				<circle cx='1144.9' cy='1019.35' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A2')}
				className={checkHandler('207')}
				id='A2-207'>
				<polygon
					className={SCSS.UnitBase}
					points='1188.32,1014.72 1185.48,1014.72 1185.48,1035.56 1158.38,1035.56 1158.38,984.94 1169.59,984.94 
				1169.59,982.75 1183.13,982.75 1183.13,984.94 1185.48,984.94 1185.48,1001.32 1188.32,1001.32 			'
				/>
				<text
					transform='matrix(1 0 0 1 1167.4778 999.8704)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 1163.6951 1011.1993)'
					className={SCSS.UnitNumber}>
					207
				</text>
				<circle cx='1171.93' cy='1019.35' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-20-OUTLINE'>
				<path
					d='M1183.13,982.75v2.19h2.36v16.38h2.83v13.4h-2.83v20.84h-162.17c0-0.5,0-21.84,0-21.84h-1.97v-12.4h1.97v-16.38h1.91
				v-2.19h14.53v2.19h22.32v-2.19h13.53v2.19h13.56v-2.19h13.53v2.19h3.64v-2.19h13.53v2.19h13.41v-2.19h13.53v2.19h22.78v-2.19
				H1183.13 M1185.13,980.75h-2h-13.53h-2v2v0.19h-18.78v-0.19v-2h-2h-13.53h-2v2v0.19h-9.41v-0.19v-2h-2h-13.53h-1.64h-2h-13.53h-2
				v2v0.19h-9.56v-0.19v-2h-2h-13.53h-2v2v0.19h-18.32v-0.19v-2h-2h-14.53h-2v2v0.19h-1.91v2v14.38h-1.97v2v12.4v2h1.97v19.84v2h2
				h162.17h2v-2v-18.84h0.83h2v-2v-13.4v-2h-2h-0.83v-14.38v-2h-2h-0.36v-0.19V980.75L1185.13,980.75z'
				/>
			</g>
			<g id='BUILDING-LABEL-20' className={SCSS.BuildingLabel}>
				<rect x='1077.12' y='1040.95' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1082.4642 1048.0767)'>BUILDING 20</text>
			</g>
		</g>
	);
};

export default Building20;
