import React from 'react';
import SCSS from '../Rentals.module.scss';

const Building42 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'disable':
				classes = SCSS.Disable;
				break;
			default:
				classes = SCSS.Availabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-42'>
			<g id='UNIT-308'>
				<rect
					x='1170.13'
					y='292.22'
					className={SCSS.UnitBase}
					width='66.87'
					height='26.74'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('308 B')}
					id='B-308'>
					<text
						transform='matrix(1 0 0 1 1215.4489 302.9321)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1209.3579 312.4346)'
						className={SCSS.UnitNumber}>
						308
					</text>
					<circle cx='1229.77' cy='305.59' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('308 Suite')}
					id='SUITE-308'>
					<text
						transform='matrix(1 0 0 1 1173.9213 307.9335)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1199.35' cy='305.59' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-307'>
				<rect
					x='1170.13'
					y='318.96'
					className={SCSS.UnitBase}
					width='66.87'
					height='26.74'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('307 B')}
					id='B-307'>
					<text
						transform='matrix(1 0 0 1 1215.4489 329.6718)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1209.3579 339.1743)'
						className={SCSS.UnitNumber}>
						307
					</text>
					<circle cx='1229.77' cy='332.33' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('307 Suite')}
					id='SUITE-307'>
					<text
						transform='matrix(1 0 0 1 1173.9213 334.6733)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1199.35' cy='332.33' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-306'>
				<rect
					x='1170.13'
					y='345.69'
					className={SCSS.UnitBase}
					width='66.87'
					height='26.74'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('306 B')}
					id='B-306'>
					<text
						transform='matrix(1 0 0 1 1215.4489 356.4118)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1209.3579 365.9143)'
						className={SCSS.UnitNumber}>
						306
					</text>
					<circle cx='1229.77' cy='359.07' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('306 Suite')}
					id='SUITE-306'>
					<text
						transform='matrix(1 0 0 1 1173.9213 361.4132)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1199.35' cy='359.07' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-305'>
				<rect
					x='1170.13'
					y='372.43'
					className={SCSS.UnitBase}
					width='66.87'
					height='26.74'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('305 B')}
					id='B-305'>
					<text
						transform='matrix(1 0 0 1 1215.4489 383.1517)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1209.3579 392.6542)'
						className={SCSS.UnitNumber}>
						305
					</text>
					<circle cx='1229.77' cy='385.81' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('305 Suite')}
					id='SUITE-305'>
					<text
						transform='matrix(1 0 0 1 1173.9213 388.1532)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1199.35' cy='385.81' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-304'>
				<rect
					x='1170.13'
					y='399.17'
					className={SCSS.UnitBase}
					width='66.87'
					height='26.74'
				/>
				<g
					onClick={() => click('B')}
					className={checkHandler('304 B')}
					id='B-304'>
					<text
						transform='matrix(1 0 0 1 1215.4489 409.8915)'
						className={SCSS.PlanName}>
						B
					</text>
					<text
						transform='matrix(1 0 0 1 1209.3579 419.394)'
						className={SCSS.UnitNumber}>
						304
					</text>
					<circle cx='1229.77' cy='412.54' r='3.45' className="dot" />
				</g>
				<g
					onClick={() => click('Suite')}
					className={checkHandler('304 Suite')}
					id='SUITE-304'>
					<text
						transform='matrix(1 0 0 1 1173.9213 414.8929)'
						className={SCSS.Suite}>
						SUITE
					</text>
					<circle cx='1199.35' cy='412.54' r='3.45' className="dot" />
				</g>
			</g>
			<g id='RENTAL-BUILDING-2-OUTLINE'>
				<path d='M1237,292.22v133.7h-66.87v-133.7H1237 M1239,290.22h-2h-66.87h-2v2v133.7v2h2H1237h2v-2v-133.7V290.22L1239,290.22z' />
			</g>
			<g id='BUILDING-LABEL-42' className={SCSS.BuildingLabel}>
				<rect
					x='1218.94'
					y='355.14'
					transform='matrix(6.123234e-17 -1 1 6.123234e-17 886.6962 1606.6088)'
					width='55.42'
					height='9.64'
				/>
				<text transform='matrix(6.123234e-17 -1 1 6.123234e-17 1248.9635 382.3228)'>
					BUILDING 42
				</text>
			</g>
		</g>
	);
};

export default Building42;
