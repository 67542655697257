import React from 'react';
import SCSS from '../Sales.module.scss';

const Building14 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-14'>
			<g
				onClick={() => click('A1')}
				className={checkHandler('169')}
				id='A1-169'>
				<polygon
					className={SCSS.UnitBase}
					points='896.68,892.48 868.78,892.48 868.78,914.32 866.82,914.32 866.82,926.73 868.78,926.73 
				868.78,943.11 870.69,943.11 870.69,945.3 885.22,945.3 885.22,943.11 896.68,943.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 878.7811 909.6046)'
					className={SCSS.PlanName}>
					A1
				</text>
				<text
					transform='matrix(1 0 0 1 874.9984 920.9335)'
					className={SCSS.UnitNumber}>
					169
				</text>
				<circle cx='883.23' cy='929.09' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('170')} id='A-170'>
				<polygon
					className={SCSS.UnitBase}
					points='922.96,892.48 896.68,892.48 896.68,943.11 907.55,943.11 907.55,945.3 921.08,945.3 
				921.08,943.11 922.96,943.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 907.3976 909.6046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 901.587 920.9335)'
					className={SCSS.UnitNumber}>
					170
				</text>
				<circle cx='909.82' cy='929.09' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('171')} id='A-171'>
				<polygon
					className={SCSS.UnitBase}
					points='922.96,892.48 949.96,892.48 949.96,943.11 938.29,943.11 938.29,945.3 924.76,945.3 
				924.76,943.11 922.96,943.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 934.0367 909.6046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 928.2261 920.9335)'
					className={SCSS.UnitNumber}>
					171
				</text>
				<circle cx='936.46' cy='929.09' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('172')} id='A-172'>
				<polygon
					className={SCSS.UnitBase}
					points='976.9,892.48 949.96,892.48 949.96,943.11 951.81,943.11 951.81,945.3 965.34,945.3 965.34,943.11 
				976.9,943.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 961.0081 909.6046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 955.1976 920.9335)'
					className={SCSS.UnitNumber}>
					172
				</text>
				<circle cx='963.43' cy='929.09' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A2')}
				className={checkHandler('173')}
				id='A2-173'>
				<polygon
					className={SCSS.UnitBase}
					points='1006.84,913.32 1004.01,913.32 1004.01,892.48 976.9,892.48 976.9,943.11 988.12,943.11 
				988.12,945.3 1001.65,945.3 1001.65,943.11 1004.01,943.11 1004.01,926.73 1006.84,926.73 			'
				/>
				<text
					transform='matrix(1 0 0 1 986.0055 909.6046)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 982.2228 920.9335)'
					className={SCSS.UnitNumber}>
					173
				</text>
				<circle cx='990.46' cy='929.09' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-14-OUTLINE'>
				<path
					d='M868.78,892.47C868.78,892.47,868.78,892.48,868.78,892.47C868.78,892.48,868.78,892.47,868.78,892.47 M1004.01,892.48
				v20.84h2.83v13.4h-2.83v16.38h-2.36v2.19h-13.53v-2.19h-22.78v2.19h-13.53v-2.19h-13.52v2.19h-13.53v-2.19h-3.68v2.19h-13.53
				v-2.19h-22.32v2.19h-14.53v-2.19h-1.91v-16.38h-1.97v-12.4h1.97c0,0,0-21.38,0-21.84H1004.01 M1006.01,890.48h-2H868.78h-2v1.99
				v0.01v19.84h-1.97v2v12.4v2h1.97v14.38v2h1.91v0.19v2h2h14.53h2v-2v-0.19h18.32v0.19v2h2h13.53h2h1.68h13.53h2v-2v-0.19h9.52
				v0.19v2h2h13.53h2v-2v-0.19h18.78v0.19v2h2h13.53h2v-2v-0.19h0.36h2v-2v-14.38h0.83h2v-2v-13.4v-2h-2h-0.83v-18.84V890.48
				L1006.01,890.48z'
				/>
			</g>
			<g id='BUILDING-LABEL-14' className={SCSS.BuildingLabel}>
				<rect x='909.12' y='876.29' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 914.4634 883.4219)'>BUILDING 14</text>
			</g>
		</g>
	);
};

export default Building14;
