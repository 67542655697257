import React from 'react';
import SCSS from '../Sales.module.scss';

const Building33 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-33_1_'>
			<g
				onClick={() => click('E1')}
				className={checkHandler('436')}
				id='E1-436'>
				<polygon
					className={SCSS.UnitBase}
					points='1997.64,397.5 1995.45,393.93 1983.17,401.43 1991.91,415.72 1989.12,417.43 1999.82,434.93 
			2002.6,433.23 2016.61,456.15 2030.07,447.92 2027.26,443.32 2031.49,440.73 2003.05,394.19 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 1999.1514 416.9927)'
					className={SCSS.PlanName}>
					E1
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2000.6428 427.5214)'
					className={SCSS.UnitNumber}>
					436
				</text>
				<circle cx='2015.37' cy='433.33' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('437')} id='D-437'>
				<polygon
					className={SCSS.UnitBase}
					points='2005.83,387.59 2008.01,391.16 2003.05,394.19 2031.49,440.73 2035.61,438.22 2038.42,442.82 
			2051.35,434.92 2017.92,380.2 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2018.8044 405.1961)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2019.1626 416.4247)'
					className={SCSS.UnitNumber}>
					437
				</text>
				<circle cx='2033.89' cy='422.23' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('438')} id='E-438'>
				<polygon
					className={SCSS.UnitBase}
					points='2032.42,376.25 2030.23,372.67 2017.92,380.2 2051.35,434.92 2064.68,426.77 2061.87,422.17 
			2066.04,419.62 2037.61,373.08 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2036.278 394.4455)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2036.0437 406.0399)'
					className={SCSS.UnitNumber}>
					438
				</text>
				<circle cx='2050.77' cy='411.85' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('439')} id='D-439'>
				<polygon
					className={SCSS.UnitBase}
					points='2040.61,366.33 2042.79,369.91 2037.61,373.08 2066.04,419.62 2070.22,417.07 2073.03,421.67 
			2085.75,413.9 2052.32,359.18 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2053.2146 384.5516)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2053.5728 395.7802)'
					className={SCSS.UnitNumber}>
					439
				</text>
				<circle cx='2068.3' cy='401.59' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('440')} id='E-440'>
				<polygon
					className={SCSS.UnitBase}
					points='2066.86,355.21 2064.68,351.63 2052.32,359.18 2085.75,413.9 2098.84,405.9 2096.03,401.3 
			2100.49,398.58 2072.05,352.04 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2070.9929 373.633)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2070.7588 385.2274)'
					className={SCSS.UnitNumber}>
					440
				</text>
				<circle cx='2085.48' cy='391.03' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('441')} id='D-441'>
				<polygon
					className={SCSS.UnitBase}
					points='2075.05,345.29 2077.24,348.87 2072.05,352.04 2100.49,398.58 2104.38,396.2 2107.19,400.8 
			2120.24,392.83 2086.8,338.11 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2087.7507 363.3211)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2088.1089 374.5497)'
					className={SCSS.UnitNumber}>
					441
				</text>
				<circle cx='2102.83' cy='380.36' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('442')} id='E-442'>
				<polygon
					className={SCSS.UnitBase}
					points='2101.09,334.29 2098.91,330.71 2086.8,338.11 2120.24,392.83 2133.35,384.81 2130.54,380.21 
			2134.72,377.66 2106.28,331.12 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2105.2747 352.7047)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2105.0405 364.2992)'
					className={SCSS.UnitNumber}>
					442
				</text>
				<circle cx='2119.77' cy='370.11' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('443')} id='D-443'>
				<polygon
					className={SCSS.UnitBase}
					points='2141.7,379.71 2155.03,371.57 2121.6,316.85 2109.28,324.37 2111.47,327.95 2106.28,331.12 
			2134.72,377.66 2138.89,375.11 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2121.9446 342.6673)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2122.3027 353.8959)'
					className={SCSS.UnitNumber}>
					443
				</text>
				<circle cx='2137.03' cy='359.7' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('444')} id='E-444'>
				<polygon
					className={SCSS.UnitBase}
					points='2167.95,363.67 2165.14,359.07 2169.32,356.52 2140.88,309.98 2135.48,313.28 2133.29,309.7 
			2121.6,316.85 2155.03,371.57 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2140.0659 331.7472)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2139.8318 343.3412)'
					className={SCSS.UnitNumber}>
					444
				</text>
				<circle cx='2154.56' cy='349.15' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('D1')}
				className={checkHandler('445')}
				id='D1-445'>
				<polygon
					className={SCSS.UnitBase}
					points='2173.49,353.97 2176.3,358.57 2189.15,350.72 2175.15,327.8 2177.88,326.14 2167.19,308.63 
			2164.45,310.3 2155.72,296 2142.93,303.81 2145.12,307.39 2140.88,309.98 2169.32,356.52 			'
				/>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2156.0398 322.2375)'
					className={SCSS.PlanName}>
					D1
				</text>
				<text
					transform='matrix(0.8508 -0.5255 0.5255 0.8508 2158.1228 332.4003)'
					className={SCSS.UnitNumber}>
					445
				</text>
				<circle cx='2172.85' cy='338.21' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-33-OUTLINE_1_'>
				<path
					d='M2155.72,296l8.74,14.3l2.73-1.67l10.7,17.51l-2.73,1.67l14,22.91l-12.85,7.85l-2.81-4.6l-4.17,2.55l-4.17,2.55l2.81,4.6
			l-12.92,7.9l-13.33,8.14l-2.81-4.6l-4.17,2.55l-4.17,2.55l2.81,4.6l-13.11,8.01l-13.05,7.97l-2.81-4.6l-3.9,2.38l-4.45,2.72
			l2.81,4.6l-13.09,8l-12.72,7.77l-2.81-4.6l-4.17,2.55l-4.17,2.55l2.81,4.6l-13.33,8.14l-12.93,7.9l-2.81-4.6l-4.12,2.52
			l-4.23,2.58l2.81,4.6l-13.47,8.23l-14-22.92l-2.78,1.7l-10.7-17.51l2.78-1.7l-8.74-14.3l12.28-7.5l2.19,3.58l5.42-3.31l4.96-3.03
			l-2.19-3.58l12.09-7.39l12.32-7.53l2.19,3.58l5.19-3.17l5.19-3.17l-2.19-3.58l11.71-7.15l12.36-7.55l2.19,3.58l5.19-3.17
			l5.19-3.17l-2.19-3.58l11.75-7.18l12.1-7.39l2.19,3.58l5.19-3.17l5.19-3.17l-2.19-3.58l12.32-7.53l11.69-7.14l2.19,3.58l5.4-3.3
			l4.24-2.59l-2.19-3.58L2155.72,296 M2156.38,293.25l-1.71,1.04l-12.78,7.81l-1.71,1.04l1.04,1.71l1.14,1.87l-2.53,1.55l-3.7,2.26
			l-1.14-1.87l-1.04-1.71l-1.71,1.04l-11.69,7.14l-12.32,7.53l-1.71,1.04l1.04,1.71l1.14,1.87l-3.48,2.13l-3.48,2.13l-1.14-1.87
			l-1.04-1.71l-1.71,1.04l-12.1,7.39l-11.75,7.18l-1.71,1.04l1.04,1.71l1.14,1.87l-3.48,2.13l-3.48,2.13l-1.14-1.87l-1.04-1.71
			l-1.71,1.04l-12.36,7.55l-11.71,7.15l-1.71,1.04l1.04,1.71l1.14,1.87l-3.48,2.13l-3.48,2.13l-1.14-1.87l-1.04-1.71l-1.71,1.04
			l-12.32,7.53l-12.09,7.39l-1.71,1.04l1.04,1.71l1.14,1.87l-3.25,1.99l-3.71,2.27l-1.14-1.87l-1.04-1.71l-1.71,1.04l-12.28,7.5
			l-1.71,1.04l1.04,1.71l7.69,12.59l-1.08,0.66l-1.71,1.04l1.04,1.71l10.7,17.51l1.04,1.71l1.71-1.04l1.08-0.66l12.96,21.21
			l1.04,1.71l1.71-1.04l13.47-8.23l1.71-1.04l-1.04-1.71l-1.77-2.89l2.52-1.54l2.41-1.47l1.77,2.89l1.04,1.71l1.71-1.04l12.93-7.9
			l13.33-8.14l1.71-1.04l-1.04-1.71l-1.77-2.89l2.47-1.51l2.47-1.51l1.77,2.89l1.04,1.71l1.71-1.04l12.72-7.77l13.09-8l1.71-1.04
			l-1.04-1.71l-1.77-2.89l2.75-1.68l2.19-1.34l1.77,2.89l1.04,1.71l1.71-1.04l13.05-7.97l13.11-8.01l1.71-1.04l-1.04-1.71
			l-1.77-2.89l2.47-1.51l2.47-1.51l1.77,2.89l1.04,1.71l1.71-1.04l13.33-8.14l12.92-7.9l1.71-1.04l-1.04-1.71l-1.77-2.89l2.47-1.51
			l2.47-1.51l1.77,2.89l1.04,1.71l1.71-1.04l12.85-7.85l1.71-1.04l-1.04-1.71l-12.96-21.21l1.02-0.63l1.71-1.04l-1.04-1.71
			l-10.7-17.51l-1.04-1.71l-1.71,1.04l-1.02,0.63l-7.69-12.59L2156.38,293.25L2156.38,293.25z'
				/>
			</g>
			<g id='BUILDING-LABEL-33' className={SCSS.BuildingLabel}>
				<rect
					x='2043.84'
					y='329.23'
					transform='matrix(0.8575 -0.5145 0.5145 0.8575 123.3197 1113.3549)'
					width='55.42'
					height='9.64'
				/>
				<text transform='matrix(0.8575 -0.5145 0.5145 0.8575 2053.5745 347.5652)'>
					BUILDING 33
				</text>
			</g>
		</g>
	);
};

export default Building33;
