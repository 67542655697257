import React from 'react';
import _ from 'lodash';
import {gql, useQuery} from '@apollo/client';

import S from './BuildingStatus.module.scss';

const classNames = require('classnames');

const FETCH_DATA = gql`
	query NewQuery {
		unitBuildings(first: 100) {
			nodes {
				name
				description
			}
		}
	}
`;
const BuildingStatus = () => {
	const {data, loading, error} = useQuery(FETCH_DATA);

	if (loading) {
		return null;
	}

	if (error) {
		return null;
	}

	type TBuilding = {
		__typename: string;
		name: string;
		description: string;
	};

	const buildingsData: Array<TBuilding> = data.unitBuildings.nodes;
	// console.log('buildingsData =', buildingsData);

	const buildingStyle = (building: string) => {
		const b: TBuilding | undefined = _.find(buildingsData, {name: building});
		const classes = [
			S.Building,
			{
				[`${S.Disable}`]: b?.description === null,
			},
		];
		return classNames(classes);
	};

	const buildingLabel = (building: string) => {
		const b: TBuilding | undefined = _.find(buildingsData, {name: building});
		return b?.description;
	};

	return (
		<g id='BUILDING-STATUS' className={S.BuildingStatus}>
			<g id='BUILDING-1-STATUS' className={buildingStyle('Building 1')}>
				<rect
					x='565.8101'
					y='593.3354'
					className={S.BackgroundColor}
					width='134.2278'
					height='67.0127'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 596.5592 629.3305)'
						className={S.Note}>
						{buildingLabel('Building 1')}
					</text>
				</g>
			</g>
			<g id='BUILDING-2-STATUS' className={buildingStyle('Building 2')}>
				<rect
					x='715.962'
					y='593.3354'
					className={S.BackgroundColor}
					width='108.3291'
					height='67.0127'
				/>
				<g>
					<text transform='matrix(1 0 0 1 733.762 629.3305)' className={S.Note}>
						{buildingLabel('Building 2')}
					</text>
				</g>
			</g>
			<g id='BUILDING-3-STATUS' className={buildingStyle('Building 3')}>
				<rect
					x='843.2532'
					y='593.3354'
					className={S.BackgroundColor}
					width='108.3291'
					height='67.0127'
				/>
				<g>
					<text transform='matrix(1 0 0 1 861.053 629.3305)' className={S.Note}>
						{buildingLabel('Building 3')}
					</text>
				</g>
			</g>
			<g id='BUILDING-4-STATUS' className={buildingStyle('Building 4')}>
				<rect
					x='971.0036'
					y='593.3354'
					className={S.BackgroundColor}
					width='134.2278'
					height='67.0127'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1001.7527 629.3305)'
						className={S.Note}>
						{buildingLabel('Building 4')}
					</text>
				</g>
			</g>
			<g id='BUILDING-5-STATUS' className={buildingStyle('Building 5')}>
				<rect
					x='1125.1935'
					y='593.3354'
					className={S.BackgroundColor}
					width='107.3291'
					height='67.0127'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1142.493 629.3305)'
						className={S.Note}>
						{buildingLabel('Building 5')}
					</text>
				</g>
			</g>
			<g id='BUILDING-6-STATUS' className={buildingStyle('Building 6')}>
				<rect
					x='1252.3987'
					y='593.3354'
					className={S.BackgroundColor}
					width='134.2278'
					height='67.0127'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1283.1478 629.3305)'
						className={S.Note}>
						{buildingLabel('Building 6')}
					</text>
				</g>
			</g>
			<g id='BUILDING-7-STATUS' className={buildingStyle('Building 7')}>
				<rect
					x='465.674'
					y='764.981'
					className={S.BackgroundColor}
					width='161.3354'
					height='67.0127'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 509.9742 800.6989)'
						className={S.Note}>
						{buildingLabel('Building 7')}
					</text>
				</g>
			</g>
			<g id='BUILDING-8-STATUS' className={buildingStyle('Building 8')}>
				<rect
					x='646.4968'
					y='764.981'
					className={S.BackgroundColor}
					width='161.3354'
					height='67.0127'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 690.7971 800.6989)'
						className={S.Note}>
						{buildingLabel('Building 8')}
					</text>
				</g>
			</g>
			<g id='BUILDING-9-STATUS' className={buildingStyle('Building 9')}>
				<rect
					x='869.8576'
					y='764.981'
					className={S.BackgroundColor}
					width='161.3354'
					height='67.0127'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 914.1573 800.6993)'
						className={S.Note}>
						{buildingLabel('Building 9')}
					</text>
				</g>
			</g>
			<g id='BUILDING-10-STATUS' className={buildingStyle('Building 10')}>
				<rect
					x='1050.7125'
					y='764.981'
					className={S.BackgroundColor}
					width='134.4367'
					height='67.0127'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1081.5631 800.6993)'
						className={S.Note}>
						{buildingLabel('Building 10')}
					</text>
				</g>
			</g>
			<g id='BUILDING-11-STATUS' className={buildingStyle('Building 11')}>
				<rect
					x='1204.3844'
					y='764.981'
					className={S.BackgroundColor}
					width='161.3354'
					height='67.0127'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1248.6842 800.6989)'
						className={S.Note}>
						{buildingLabel('Building 11')}
					</text>
				</g>
			</g>
			<g id='BUILDING-12-STATUS' className={buildingStyle('Building 12')}>
				<polygon
					className={S.BackgroundColor}
					points='627.293,891.1471 627.293,913.9888 629.1245,913.9888 629.1245,926.3935 627.293,926.3935
			627.293,943.7733 624.9348,943.7733 624.9348,946.9631 612.4031,946.9631 612.4031,943.7733 587.4252,943.7733 587.4252,946.9631
			574.8936,946.9631 574.8936,943.7733 560.5138,943.7733 560.5138,946.9631 547.9821,946.9631 547.9821,943.7733
			544.3088,943.7733 544.3088,946.9631 531.8088,946.9631 531.8088,943.7733 517.2518,943.7733 517.2518,946.9631
			504.7202,946.9631 504.7202,943.7733 480.3974,943.7733 480.3974,946.9631 466.8657,946.9631 466.8657,943.7733
			464.9575,943.7733 464.9575,926.3935 462.9923,926.3935 462.9923,913.9888 464.9575,913.9888 464.9575,891.1471 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 509.6908 921.2671)'
						className={S.Note}>
						{buildingLabel('Building 12')}
					</text>
				</g>
			</g>
			<g id='BUILDING-13-STATUS' className={buildingStyle('Building 13')}>
				<polygon
					className={S.BackgroundColor}
					points='807.9086,891.4814 807.9086,914.3231 809.7401,914.3231 809.7401,926.7278 807.9086,926.7278
			807.9086,944.1076 805.5504,944.1076 805.5504,947.2974 793.0187,947.2974 793.0187,944.1076 768.0409,944.1076
			768.0409,947.2974 755.5093,947.2974 755.5093,944.1076 741.1295,944.1076 741.1295,947.2974 728.5978,947.2974
			728.5978,944.1076 724.9561,944.1076 724.9561,947.2974 712.4244,947.2974 712.4244,944.1076 697.8675,944.1076
			697.8675,947.2974 685.3358,947.2974 685.3358,944.1076 661.0131,944.1076 661.0131,947.2974 647.4814,947.2974
			647.4814,944.1076 645.5732,944.1076 645.5732,926.7278 643.608,926.7278 643.608,914.3231 645.5732,914.3231 645.5732,891.4814
					'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 690.3066 921.6014)'
						className={S.Note}>
						{buildingLabel('Building 13')}
					</text>
				</g>
			</g>
			<g id='BUILDING-14-STATUS' className={buildingStyle('Building 14')}>
				<polygon
					className={S.BackgroundColor}
					points='1004.0116,892.4814 1004.0116,913.3231 1006.8431,913.3231 1006.8431,926.7278 1004.0116,926.7278
			1004.0116,943.1076 1001.6534,943.1076 1001.6534,945.2974 988.1217,945.2974 988.1217,943.1076 965.34,943.1076 965.34,945.2974
			951.8083,945.2974 951.8083,943.1076 938.2869,943.1076 938.2869,945.6012 924.7552,945.6012 924.7552,943.1076
			921.0781,943.1076 921.0781,945.2974 907.5464,945.2974 907.5464,943.1076 885.2236,943.1076 885.2236,945.2974 870.692,945.2974
			870.692,943.1076 868.7838,943.1076 868.7838,926.7278 866.8185,926.7278 866.8185,914.3231 868.7838,914.3231 868.7838,892.4814
					'
				/>
				<g>
					<text transform='matrix(1 0 0 1 900.463 921.2529)' className={S.Note}>
						{buildingLabel('Building 14')}
					</text>
				</g>
			</g>
			<g id='BUILDING-15-STATUS' className={buildingStyle('Building 15')}>
				<polygon
					className={S.BackgroundColor}
					points='1185.4839,892.7852 1185.4839,913.6269 1188.3154,913.6269 1188.3154,927.0316 1185.4839,927.0316
			1185.4839,943.4114 1183.1257,943.4114 1183.1257,945.6012 1169.594,945.6012 1169.594,943.4114 1146.8124,943.4114
			1146.8124,945.6012 1133.1649,945.6012 1133.1649,943.4114 1119.8693,943.4114 1119.8693,945.6012 1106.3376,945.6012
			1106.3376,943.4114 1102.6959,943.4114 1102.6959,945.6012 1089.1643,945.6012 1089.1643,943.4114 1075.6073,943.4114
			1075.6073,945.6012 1062.0757,945.6012 1062.0757,943.4114 1039.7529,943.4114 1039.7529,945.6012 1025.2212,945.6012
			1025.2212,943.4114 1023.313,943.4114 1023.313,927.0316 1021.3478,927.0316 1021.3478,914.6269 1023.313,914.6269
			1023.313,892.7852 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1068.4641 921.4048)'
						className={S.Note}>
						{buildingLabel('Building 15')}
					</text>
				</g>
			</g>
			<g id='BUILDING-16-STATUS' className={buildingStyle('Building 16')}>
				<polygon
					className={S.BackgroundColor}
					points='1338.9946,892.4814 1338.9946,913.3231 1341.8262,913.3231 1341.8262,926.7278 1338.9946,926.7278
			1338.9946,943.1076 1336.6365,943.1076 1336.6365,945.2974 1323.1047,945.2974 1323.1047,943.1076 1300.323,943.1076
			1300.323,945.2974 1286.7914,945.2974 1286.7914,943.1076 1283.1498,943.1076 1283.1498,945.2974 1269.618,945.2974
			1269.618,943.1076 1256.061,943.1076 1256.061,945.2974 1242.5294,945.2974 1242.5294,943.1076 1220.2067,943.1076
			1220.2067,945.2974 1205.6749,945.2974 1205.6749,943.1076 1203.7667,943.1076 1203.7667,926.7278 1201.8015,926.7278
			1201.8015,914.3231 1203.7667,914.3231 1203.7667,892.4814 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1235.4462 921.101)'
						className={S.Note}>
						{buildingLabel('Building 16')}
					</text>
				</g>
			</g>
			<g id='BUILDING-17-STATUS' className={buildingStyle('Building 17')}>
				<polygon
					className={S.BackgroundColor}
					points='627.0095,1036.4114 627.0095,1013.5697 628.841,1013.5697 628.841,1001.165 627.0095,1001.165
			627.0095,984.7852 624.6513,984.7852 624.6513,981.5954 612.1196,981.5954 612.1196,984.7852 587.1418,984.7852
			587.1418,981.5954 574.6102,981.5954 574.6102,984.7852 560.2303,984.7852 560.2303,981.5954 547.6987,981.5954
			547.6987,984.7852 544.057,984.7852 544.057,981.5954 531.5253,981.5954 531.5253,984.7852 516.9684,984.7852 516.9684,981.5954
			504.4367,981.5954 504.4367,984.7852 480.114,984.7852 480.114,981.5954 467.5823,981.5954 467.5823,984.7852 464.6741,984.7852
			464.6741,1001.165 462.7089,1001.165 462.7089,1013.5697 464.6741,1013.5697 464.6741,1036.4114 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 509.4073 1011.2155)'
						className={S.Note}>
						{buildingLabel('Building 17')}
					</text>
				</g>
			</g>
			<g id='BUILDING-18-STATUS' className={buildingStyle('Building 18')}>
				<polygon
					className={S.BackgroundColor}
					points='807.9086,1036.4114 807.9086,1013.5697 809.7401,1013.5697 809.7401,1001.165 807.9086,1001.165
			807.9086,984.7852 805.5504,984.7852 805.5504,981.5954 793.0187,981.5954 793.0187,984.7852 768.0409,984.7852
			768.0409,981.5954 755.5093,981.5954 755.5093,984.7852 741.1295,984.7852 741.1295,981.5954 728.5978,981.5954
			728.5978,984.7852 724.9561,984.7852 724.9561,981.5954 712.4244,981.5954 712.4244,984.7852 697.8675,984.7852
			697.8675,981.5954 685.3358,981.5954 685.3358,984.7852 661.0131,984.7852 661.0131,981.5954 648.4814,981.5954
			648.4814,984.7852 645.5732,984.7852 645.5732,1001.165 643.608,1001.165 643.608,1013.5697 645.5732,1013.5697
			645.5732,1036.4114 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 690.3066 1011.2155)'
						className={S.Note}>
						{buildingLabel('Building 18')}
					</text>
				</g>
			</g>
			<g id='BUILDING-19-STATUS' className={buildingStyle('Building 19')}>
				<polygon
					className={S.BackgroundColor}
					points='1004.0116,1035.4114 1004.0116,1014.5697 1006.8431,1014.5697 1006.8431,1001.165
			1004.0116,1001.165 1004.0116,984.7852 1001.6534,984.7852 1001.6534,982.5954 988.1217,982.5954 988.1217,984.7852
			965.34,984.7852 965.34,982.5954 951.8083,982.5954 951.8083,984.7852 938.2869,984.7852 938.2869,982.7473 924.7552,982.7473
			924.7552,984.7852 921.0781,984.7852 921.0781,982.5954 907.5464,982.5954 907.5464,984.7852 885.2236,984.7852
			885.2236,982.5954 870.692,982.5954 870.692,984.7852 868.7838,984.7852 868.7838,1001.165 866.8185,1001.165 866.8185,1013.5697
			868.7838,1013.5697 868.7838,1035.4114 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 900.463 1011.2154)'
						className={S.Note}>
						{buildingLabel('Building 19')}
					</text>
				</g>
			</g>
			<g id='BUILDING-20-STATUS' className={buildingStyle('Building 20')}>
				<polygon
					className={S.BackgroundColor}
					points='1185.4839,1035.5632 1023.313,1035.5632 1023.313,1013.7216 1021.3478,1013.7216
			1021.3478,1001.3168 1023.313,1001.3168 1023.313,984.9371 1025.2212,984.9371 1025.2212,982.7473 1039.7529,982.7473
			1039.7529,984.9371 1062.0757,984.9371 1062.0757,982.7473 1075.6073,982.7473 1075.6073,984.9371 1089.1643,984.9371
			1089.1643,982.7473 1102.6959,982.7473 1102.6959,984.9371 1106.3376,984.9371 1106.3376,982.7473 1119.8693,982.7473
			1119.8693,984.9371 1133.2806,984.9371 1133.2806,982.7473 1146.8124,982.7473 1146.8124,984.9371 1169.594,984.9371
			1169.594,982.7473 1183.1257,982.7473 1183.1257,984.9371 1185.4839,984.9371 1185.4839,1001.3168 1188.3154,1001.3168
			1188.3154,1014.7216 1185.4839,1014.7216 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1068.4639 1011.3674)'
						className={S.Note}>
						{buildingLabel('Building 20')}
					</text>
				</g>
			</g>
			<g id='BUILDING-21-STATUS' className={buildingStyle('Building 21')}>
				<polygon
					className={S.BackgroundColor}
					points='1338.9946,1035.8671 1338.9946,1015.0254 1341.8262,1015.0254 1341.8262,1001.6207
			1338.9946,1001.6207 1338.9946,985.2409 1336.6365,985.2409 1336.6365,983.0511 1323.1047,983.0511 1323.1047,985.2409
			1300.323,985.2409 1300.323,983.0511 1286.7914,983.0511 1286.7914,985.2409 1283.1498,985.2409 1283.1498,983.0511
			1269.618,983.0511 1269.618,985.2409 1256.061,985.2409 1256.061,983.0511 1242.5294,983.0511 1242.5294,985.2409
			1220.2067,985.2409 1220.2067,983.0511 1205.6749,983.0511 1205.6749,985.2409 1203.7667,985.2409 1203.7667,1001.6207
			1201.8015,1001.6207 1201.8015,1014.0254 1203.7667,1014.0254 1203.7667,1035.8671 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1235.446 1011.6709)'
						className={S.Note}>
						{buildingLabel('Building 21')}
					</text>
				</g>
			</g>
			<g id='BUILDING-22-STATUS' className={buildingStyle('Building 22')}>
				<polygon
					className={S.BackgroundColor}
					points='570.4475,1095.0815 570.4475,1115.9232 573.279,1115.9232 573.279,1129.3279 570.4475,1129.3279
			570.4475,1145.7076 568.0893,1145.7076 568.0893,1147.8975 553.5576,1147.8975 553.5576,1145.7076 530.7759,1145.7076
			530.7759,1147.8975 517.2443,1147.8975 517.2443,1145.7076 513.6026,1145.7076 513.6026,1147.8975 500.0709,1147.8975
			500.0709,1145.7076 486.514,1145.7076 486.514,1147.8975 472.9823,1147.8975 472.9823,1145.7076 450.6595,1145.7076
			450.6595,1147.8975 436.1279,1147.8975 436.1279,1145.7076 434.2197,1145.7076 434.2197,1129.3279 432.2545,1129.3279
			432.2545,1116.9232 434.2197,1116.9232 434.2197,1095.0815 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 466.3989 1123.7019)'
						className={S.Note}>
						{buildingLabel('Building 22')}
					</text>
				</g>
			</g>
			<g id='BUILDING-23-STATUS' className={buildingStyle('Building 23')}>
				<polygon
					className={S.BackgroundColor}
					points='694.3969,1095.0815 694.3969,1115.9232 697.2283,1115.9232 697.2283,1129.3279 694.3969,1129.3279
			694.3969,1145.7076 692.0386,1145.7076 692.0386,1147.8975 677.507,1147.8975 677.507,1145.7076 654.7253,1145.7076
			654.7253,1147.8975 641.1936,1147.8975 641.1936,1145.7076 637.5519,1145.7076 637.5519,1147.8975 624.0203,1147.8975
			624.0203,1145.7076 601.1891,1145.7076 601.1891,1147.8975 586.6575,1147.8975 586.6575,1145.7076 584.7493,1145.7076
			584.7493,1129.3279 582.7841,1129.3279 582.7841,1116.9232 584.7493,1116.9232 584.7493,1095.0815 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 603.6384 1123.7013)'
						className={S.Note}>
						{buildingLabel('Building 23')}
					</text>
				</g>
			</g>
			<g id='BUILDING-24-STATUS' className={buildingStyle('Building 24')}>
				<polygon
					className={S.BackgroundColor}
					points='844.7433,1095.3093 844.7433,1116.151 847.5748,1116.151 847.5748,1129.5558 844.7433,1129.5558
			844.7433,1145.9355 842.3851,1145.9355 842.3851,1148.1254 828.8534,1148.1254 828.8534,1145.9355 806.0717,1145.9355
			806.0717,1148.1254 792.54,1148.1254 792.54,1145.9355 788.8984,1145.9355 788.8984,1148.1254 775.3667,1148.1254
			775.3667,1145.9355 761.8098,1145.9355 761.8098,1148.1254 748.2781,1148.1254 748.2781,1145.9355 725.9553,1145.9355
			725.9553,1148.1254 711.4236,1148.1254 711.4236,1146.1075 709.5154,1146.1075 709.5154,1129.5558 707.5502,1129.5558
			707.5502,1117.151 709.5154,1117.151 709.5154,1095.0815 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 741.1946 1123.8157)'
						className={S.Note}>
						{buildingLabel('Building 24')}
					</text>
				</g>
			</g>
			<g id='BUILDING-25-STATUS' className={buildingStyle('Building 25')}>
				<polygon
					className={S.BackgroundColor}
					points='1005.2332,1095.3093 1005.2332,1116.151 1008.0646,1116.151 1008.0646,1129.5558
			1005.2332,1129.5558 1005.2332,1145.9355 1002.8749,1145.9355 1002.8749,1148.1254 989.3433,1148.1254 989.3433,1145.9355
			966.5615,1145.9355 966.5615,1148.1254 953.0299,1148.1254 953.0299,1145.9355 939.5084,1145.9355 939.5084,1148.1254
			925.9767,1148.1254 925.9767,1145.9355 922.2996,1145.9355 922.2996,1148.1254 908.7679,1148.1254 908.7679,1145.9355
			886.4452,1145.9355 886.4452,1148.1254 871.9135,1148.1254 871.9135,1145.9355 870.0052,1145.9355 870.0052,1129.5558
			868.04,1129.5558 868.04,1117.151 870.0052,1117.151 870.0052,1095.3093 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 901.6847 1123.9297)'
						className={S.Note}>
						{buildingLabel('Building 25')}
					</text>
				</g>
			</g>
			<g id='BUILDING-26-STATUS' className={buildingStyle('Building 26')}>
				<polygon
					className={S.BackgroundColor}
					points='1186.4839,1095.3093 1186.4839,1116.151 1189.3154,1116.151 1189.3154,1129.5558
			1186.4839,1129.5558 1186.4839,1145.9355 1184.1257,1145.9355 1184.1257,1148.1254 1170.594,1148.1254 1170.594,1145.9355
			1147.8124,1145.9355 1147.8124,1148.1254 1134.1519,1148.1254 1134.1519,1145.9355 1120.8693,1145.9355 1120.8693,1148.1254
			1107.3376,1148.1254 1107.3376,1145.9355 1103.6959,1145.9355 1103.6959,1148.1254 1090.1643,1148.1254 1090.1643,1145.9355
			1076.6073,1145.9355 1076.6073,1148.1254 1063.0757,1148.1254 1063.0757,1145.9355 1040.7529,1145.9355 1040.7529,1148.1254
			1026.2212,1148.1254 1026.2212,1145.9355 1024.313,1145.9355 1024.313,1129.5558 1022.3478,1129.5558 1022.3478,1117.151
			1024.313,1117.151 1024.313,1095.3093 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1069.4636 1123.929)'
						className={S.Note}>
						{buildingLabel('Building 26')}
					</text>
				</g>
			</g>
			<g id='BUILDING-27-STATUS' className={buildingStyle('Building 27')}>
				<polygon
					className={S.BackgroundColor}
					points='1339.9946,1095.3093 1339.9946,1116.151 1342.8262,1116.151 1342.8262,1129.5558
			1339.9946,1129.5558 1339.9946,1145.9355 1337.6365,1145.9355 1337.6365,1148.1254 1324.1047,1148.1254 1324.1047,1145.9355
			1301.323,1145.9355 1301.323,1148.1254 1287.7914,1148.1254 1287.7914,1145.9355 1284.1498,1145.9355 1284.1498,1148.1254
			1270.618,1148.1254 1270.618,1145.9355 1257.061,1145.9355 1257.061,1148.1254 1243.5294,1148.1254 1243.5294,1145.9355
			1221.2067,1145.9355 1221.2067,1148.1254 1206.6749,1148.1254 1206.6749,1145.9355 1204.7667,1145.9355 1204.7667,1129.5558
			1202.8015,1129.5558 1202.8015,1117.151 1204.7667,1117.151 1204.7667,1095.3093 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1236.4463 1123.9292)'
						className={S.Note}>
						{buildingLabel('Building 27')}
					</text>
				</g>
			</g>
			<g id='BUILDING-28-STATUS' className={buildingStyle('Building 28')}>
				<polygon
					className={S.BackgroundColor}
					points='1399.0099,1055.5366 1419.8516,1055.5366 1419.8516,1052.7051 1434.2562,1052.7051
			1434.2562,1055.5366 1450.636,1055.5366 1450.636,1057.8948 1452.8258,1057.8948 1452.8258,1071.4265 1450.636,1071.4265
			1450.636,1084.5457 1452.8258,1084.5457 1452.8258,1098.0774 1450.636,1098.0774 1450.636,1120.4001 1452.8258,1120.4001
			1452.8258,1134.9318 1450.636,1134.9318 1450.636,1136.8214 1434.2562,1136.8214 1434.2562,1138.7866 1420.8516,1138.7866
			1420.8516,1136.84 1399.0099,1136.84 		'
				/>
				<g>
					<text
						transform='matrix(0 -1 1 0 1428.1299 1132.1133)'
						className={S.Note}>
						{buildingLabel('Building 28')}
					</text>
				</g>
			</g>
			<g id='BUILDING-29-STATUS' className={buildingStyle('Building 29')}>
				<polygon
					className={S.BackgroundColor}
					points='1552.433,1118.6316 1530.9836,1103.2963 1529.3,1105.6511 1512.4189,1093.5818 1514.1025,1091.2269
			1500.2681,1081.3359 1509.1792,1068.8723 1512.6204,1071.3325 1518.9681,1062.4541 1515.5269,1059.9938 1524.5992,1047.3046
			1528.0404,1049.7649 1531.0139,1045.606 1527.5728,1043.1456 1544.5894,1019.3446 1548.0305,1021.8049 1554.3784,1012.9265
			1550.9373,1010.4661 1559.353,998.6951 1562.7942,1001.1554 1569.1575,992.2551 1565.7163,989.7947 1574.4941,977.5174
			1588.3286,987.4084 1590.0243,985.0366 1606.9055,997.106 1605.2097,999.4777 1626.6589,1014.8131 1617.865,1027.113
			1613.6599,1024.1064 1607.3126,1032.9843 1611.5178,1035.5632 1603.4172,1047.3208 1599.2122,1044.3143 1592.8643,1053.1929
			1597.0696,1056.1993 1579.7356,1080.4441 1575.5303,1077.4375 1572.5588,1081.5938 1576.7639,1084.6003 1567.9753,1096.8928
			1563.77,1093.8864 1557.4224,1102.7649 1561.6274,1105.7714 		'
				/>
				<g>
					<text
						transform='matrix(0.5816 -0.8135 0.8135 0.5816 1544.0717 1078.9646)'
						className={S.Note}>
						{buildingLabel('Building 29')}
					</text>
				</g>
			</g>
			<g id='BUILDING-30-STATUS' className={buildingStyle('Building 30')}>
				<polygon
					className={S.BackgroundColor}
					points='1689.2368,816.9572 1702.5157,826.451 1704.226,824.059 1720.9158,835.9915 1719.2056,838.3835
			1741.4016,854.2529 1733.0125,865.9869 1728.8289,862.9958 1725.3779,867.8226 1729.5614,870.8137 1721.0276,882.7499
			1716.8562,879.7676 1710.5085,888.6461 1714.6797,891.6284 1697.6123,915.5004 1693.4409,912.5181 1687.0933,921.3966
			1691.2644,924.3789 1674.0789,948.416 1669.9077,945.4337 1663.5599,954.3122 1667.7312,957.2944 1651.2183,980.3907
			1647.0471,977.4084 1640.6992,986.2869 1644.8706,989.2692 1635.6047,1002.2291 1613.4086,986.3598 1611.7267,988.7122
			1595.037,976.7797 1596.7188,974.4273 1583.4399,964.9335 1592.2288,952.6407 1595.7039,955.1252 1602.0515,946.2467
			1598.5765,943.7621 1615.2385,920.4574 1618.7136,922.942 1625.0613,914.0634 1621.5862,911.5789 1638.816,887.48
			1642.291,889.9645 1648.6389,881.086 1645.1638,878.6014 1662.175,854.8082 1665.6501,857.2928 1671.9978,848.4143
			1668.5227,845.9297 		'
				/>
				<g>
					<text
						transform='matrix(0.5816 -0.8135 0.8135 0.5816 1643.0287 940.4832)'
						className={S.Note}>
						{buildingLabel('Building 30')}
					</text>
				</g>
			</g>
			<g id='BUILDING-31-STATUS' className={buildingStyle('Building 31')}>
				<polygon
					className={S.BackgroundColor}
					points='1821.9332,631.0607 1835.3525,640.6549 1837.2998,637.9312 1853.9895,649.8636 1852.0422,652.5873
			1874.0981,668.3564 1865.0278,681.0427 1860.9968,678.1608 1855.7397,685.514 1859.7706,688.3959 1841.6538,713.7357
			1837.6228,710.8537 1832.3655,718.2069 1836.3965,721.0889 1818.0466,746.7545 1814.0156,743.8726 1808.7583,751.2258
			1812.7893,754.1077 1794.6423,779.4894 1790.6116,776.6075 1785.3792,783.9258 1789.4102,786.8077 1771.2695,812.1808
			1767.2385,809.2989 1761.499,817.3267 1765.5299,820.2086 1756.1902,833.2719 1734.1343,817.5029 1729.5115,823.9689
			1712.8218,812.0364 1717.4446,805.5704 1704.0254,795.9762 1712.1167,784.659 1715.7322,787.2439 1723.3517,776.5866
			1719.7363,774.0017 1736.2406,750.9174 1739.856,753.5023 1747.0979,743.3732 1743.4824,740.7883 1759.6322,718.2
			1763.2476,720.7849 1770.4895,710.6558 1766.874,708.0709 1783.2456,685.1722 1786.8611,687.7571 1794.103,677.628
			1790.4875,675.0431 1806.6162,652.4841 1810.1829,655.0339 1816.9324,645.5933 1813.366,643.0435 		'
				/>
				<g>
					<text
						transform='matrix(0.5816 -0.8135 0.8135 0.5816 1769.6699 763.0574)'
						className={S.Note}>
						{buildingLabel('Building 31')}
					</text>
				</g>
			</g>
			<g id='BUILDING-32-STATUS' className={buildingStyle('Building 32')}>
				<polygon
					className={S.BackgroundColor}
					points='1889.1389,647.319 1867.3132,631.7144 1865.5183,634.225 1848.8284,622.2926 1850.6235,619.782
			1836.9741,610.0233 1845.5693,598.0014 1849.1848,600.5863 1856.4209,590.4653 1852.8054,587.8804 1869.2772,564.8416
			1872.8927,567.4265 1880.1288,557.3054 1876.5134,554.7205 1892.6663,532.1277 1896.2817,534.7126 1903.5178,524.5916
			1899.9023,522.0067 1916.2103,499.1971 1919.8258,501.782 1927.0619,491.661 1923.4463,489.0761 1939.7454,465.9458
			1943.4016,468.8069 1949.957,459.6379 1946.3417,457.053 1954.8821,445.1077 1968.5312,454.8665 1973.3066,448.1874
			1989.9963,460.1199 1985.2211,466.799 2007.0469,482.4034 1998.0674,494.9627 1994.0365,492.0807 1988.7147,499.5243
			1992.7456,502.4062 1974.991,527.2393 1970.9601,524.3573 1964.8926,532.8439 1968.9236,535.7258 1951.8634,559.5875
			1947.8325,556.7055 1941.7649,565.192 1945.7959,568.074 1928.0939,592.8335 1924.063,589.9515 1917.9954,598.438
			1922.0264,601.32 1904.2175,626.2288 1900.1865,623.3469 1894.9294,630.7001 1898.9603,633.582 		'
				/>
				<g>
					<text
						transform='matrix(0.5816 -0.8135 0.8135 0.5816 1902.6185 577.1042)'
						className={S.Note}>
						{buildingLabel('Building 32')}
					</text>
				</g>
			</g>
			<g id='BUILDING-33-STATUS' className={buildingStyle('Building 33')}>
				<polygon
					className={S.BackgroundColor}
					points='1983.1714,401.428 1995.4507,393.9251 1997.6372,397.5038 2008.0122,391.1648 2005.8254,387.5861
			2030.2318,372.6735 2032.4185,376.2524 2042.7935,369.9134 2040.6067,366.3344 2064.676,351.6277 2066.8628,355.2068
			2077.2378,348.8678 2075.0508,345.2886 2098.9055,330.7131 2101.0925,334.2925 2111.4675,327.9534 2109.2805,324.374
			2133.2898,309.704 2135.4768,313.2836 2145.1213,307.3908 2142.9341,303.8112 2155.7188,295.9998 2164.4548,310.2976
			2167.1855,308.6292 2177.8826,326.1365 2175.1519,327.8048 2189.1531,350.7197 2176.3027,358.5714 2173.4927,353.9721
			2165.1438,359.0733 2167.9541,363.6727 2141.7019,379.713 2138.8916,375.1134 2130.5427,380.2145 2133.3533,384.8141
			2107.1938,400.7978 2104.3833,396.1981 2096.0344,401.2992 2098.845,405.899 2073.0281,421.6733 2070.2175,417.0734
			2061.8687,422.1745 2064.6794,426.7745 2038.4214,442.8184 2035.6108,438.2182 2027.262,443.3193 2030.0728,447.9196
			2016.6057,456.1479 2002.6039,433.232 1999.8188,434.9337 1989.1219,417.4263 1991.9067,415.7248 		'
				/>
				<g>
					<text
						transform='matrix(0.8536 -0.5209 0.5209 0.8536 2056.2673 396.8578)'
						className={S.Note}>
						{buildingLabel('Building 33')}
					</text>
				</g>
			</g>
			<g id='BUILDING-34-STATUS' className={buildingStyle('Building 34')}>
				<polygon
					className={S.BackgroundColor}
					points='2359.2065,193.731 2369.9036,211.2383 2362.8716,215.5349 2376.2949,237.5042 2363.0557,245.5935
			2360.8674,242.0122 2352.8167,246.9313 2355.0049,250.5126 2328.8459,266.4959 2326.6196,262.8521 2317.9231,268.1658
			2320.1494,271.8096 2294.5867,287.4288 2292.3601,283.7847 2283.6636,289.0984 2285.8901,292.7424 2260.0908,308.5061
			2257.8643,304.8619 2249.1675,310.1755 2251.394,313.8198 2225.6101,329.5742 2223.3833,325.9297 2215.0344,331.0309
			2217.2612,334.6754 2203.7476,342.9324 2190.3235,320.962 2186.8906,323.0596 2176.1936,305.5522 2179.6262,303.4548
			2170.313,288.2125 2182.6199,280.6929 2184.9038,284.4311 2195.3093,278.0733 2193.0254,274.335 2217.1057,259.6216
			2219.3899,263.3602 2229.7954,257.0023 2227.6284,253.4554 2251.9048,238.3589 2254.1892,242.0976 2264.595,235.7398
			2262.3105,232.001 2286.2498,217.374 2288.5342,221.1129 2298.9397,214.7551 2296.655,211.0161 2320.3933,196.5118
			2322.678,200.2509 2332.3892,194.3173 2330.1047,190.5782 2342.8606,182.7842 2352.1743,198.0276 		'
				/>
				<g>
					<text
						transform='matrix(0.8536 -0.5209 0.5209 0.8536 2243.4082 283.6424)'
						className={S.Note}>
						{buildingLabel('Building 34')}
					</text>
				</g>
			</g>
			<g id='BUILDING-35-STATUS' className={buildingStyle('Building 35')}>
				<polygon
					className={S.BackgroundColor}
					points='1562.6113,808.4884 1579.301,820.4208 1577.7,822.6604 1592.3137,833.1086 1584.1307,844.5543
			1580.4399,841.9156 1576.156,847.9076 1579.8467,850.5463 1572.2246,861.2072 1568.5339,858.5684 1561.4407,868.4896
			1565.1315,871.1284 1548.7029,894.107 1545.012,891.4681 1537.9187,901.3893 1541.6096,904.0282 1525.181,927.0068
			1521.49,924.3679 1514.6023,934.0016 1518.2932,936.6406 1501.6926,959.8597 1498.0016,957.2206 1491.114,966.8543
			1494.8052,969.4933 1486.459,981.1671 1482.7676,978.528 1476.3601,987.4903 1480.0513,990.1294 1471.2656,1002.418
			1456.651,991.9692 1455.074,994.1749 1438.3843,982.2424 1439.9612,980.0369 1419.1006,965.1224 1429.3862,950.7361
			1433.3975,953.6039 1438.3054,946.7393 1434.2942,943.8713 1443.167,931.4611 1447.1782,934.329 1452.895,926.3331
			1448.8835,923.4651 1466.5078,898.8144 1470.5193,901.6824 1476.236,893.6865 1472.2245,891.2008 1490.2349,865.6276
			1494.2463,868.4957 1499.9631,860.4998 1495.9515,857.6317 1513.5059,833.0787 1517.5173,835.9468 1523.2341,827.9509
			1519.2224,825.0828 1527.6819,813.2506 1531.6936,816.1188 1535.3149,811.0538 1531.3032,808.1855 1540.1489,795.8131
			1561.0103,810.728 		'
				/>
				<g>
					<text
						transform='matrix(0.5816 -0.8135 0.8135 0.5816 1486.3148 930.0056)'
						className={S.Note}>
						{buildingLabel('Building 35')}
					</text>
				</g>
			</g>
			<g id='BUILDING-36-STATUS' className={buildingStyle('Building 36')}>
				<polygon
					className={S.BackgroundColor}
					points='1756.927,601.6889 1748.9504,612.8455 1745.8496,610.6285 1738.7993,620.4895 1741.9004,622.7065
			1734.0593,633.6736 1730.9583,631.4566 1723.8982,641.3314 1726.9993,643.5485 1710.4844,666.6476 1707.3833,664.4305
			1700.3232,674.3053 1703.4243,676.5225 1687.1372,699.3034 1684.0359,697.0861 1676.9758,706.9609 1680.0771,709.1782
			1663.5786,732.2545 1660.4773,730.0372 1653.7148,739.4957 1656.8162,741.7131 1649.0055,752.6378 1645.8718,750.3974
			1641.4773,756.5439 1644.611,758.7844 1635.8787,770.9982 1622.0144,761.0859 1620.5398,763.1486 1603.8499,751.2161
			1605.3245,749.1535 1583.7041,733.6959 1592.7378,721.0604 1597.2996,724.3219 1600.3999,719.9855 1595.8381,716.7241
			1604.8882,704.0658 1609.45,707.3273 1614.9458,699.6403 1610.384,696.3788 1628.2975,671.3235 1632.8594,674.585
			1638.1431,667.1949 1633.5811,663.9333 1652.0222,638.14 1656.5842,641.4017 1661.8677,634.0115 1657.3058,630.7498
			1675.3179,605.5565 1679.88,608.8182 1685.396,601.1031 1680.8337,597.8414 1689.726,585.404 1694.2881,588.6658
			1699.1973,581.7994 1694.6351,578.5375 1704.7524,564.3865 1726.3735,579.8446 1728.0769,577.462 1744.7668,589.3945
			1743.0634,591.777 		'
				/>
				<g>
					<text
						transform='matrix(0.5816 -0.8135 0.8135 0.5816 1650.923 698.5828)'
						className={S.Note}>
						{buildingLabel('Building 36')}
					</text>
				</g>
			</g>
			<g id='BUILDING-37-STATUS' className={buildingStyle('Building 37')}>
				<polygon
					className={S.BackgroundColor}
					points='1864.4995,452.0127 1856.1074,463.7506 1852.5171,461.1837 1845.9265,470.4019 1849.5168,472.9689
			1832.907,496.201 1829.3164,493.634 1821.931,503.9638 1825.5216,506.5309 1809.8005,528.5199 1806.2098,525.9527
			1798.8245,536.2825 1802.415,538.8497 1785.9843,561.8315 1782.5122,559.3491 1775.1267,569.679 1778.5989,572.1613
			1769.947,584.2625 1755.1865,573.7094 1753.5933,575.938 1736.9034,564.0055 1738.4967,561.777 1717.4265,546.7128
			1726.605,533.875 1731.1421,537.1188 1737.1393,528.7307 1732.6021,525.4868 1750.2925,500.7434 1754.8298,503.9874
			1760.8269,495.5992 1756.2898,492.3552 1773.5448,468.2207 1778.082,471.4647 1784.0793,463.0766 1779.542,459.8326
			1797.4219,434.824 1801.9595,438.0681 1807.438,430.4052 1802.9004,427.1611 1811.979,414.463 1833.0497,429.5275
			1835.0823,426.6846 1851.772,438.6171 1849.7395,441.4599 		'
				/>
				<g>
					<text
						transform='matrix(0.5816 -0.8135 0.8135 0.5816 1771.571 530.2537)'
						className={S.Note}>
						{buildingLabel('Building 37')}
					</text>
				</g>
			</g>
			<g id='BUILDING-38-STATUS' className={buildingStyle('Building 38')}>
				<polygon
					className={S.BackgroundColor}
					points='1894.5898,297.5042 1916.3289,313.0465 1918.0476,310.6425 1934.7373,322.575 1933.0186,324.9789
			1947.1104,335.0538 1938.4771,347.129 1935.6305,345.0937 1929.0845,354.2496 1931.9312,356.2849 1923.896,367.5235
			1920.9739,365.4343 1914.0586,375.1068 1916.9807,377.1959 1900.0496,400.8774 1897.1273,398.7881 1890.2119,408.4606
			1893.1343,410.5499 1872.7444,439.0689 1858.6523,428.9937 1856.8362,431.5339 1840.1465,419.6014 1841.9625,417.0613
			1820.2239,401.5192 1829.1647,389.0139 1834.3701,392.7357 1837.3013,388.6362 1832.0957,384.9144 1841.2181,372.1548
			1846.4238,375.8766 1851.9023,368.2137 1846.6968,364.4919 1864.6675,339.3563 1869.8733,343.0782 1875.3518,335.4153
			1870.1461,331.6935 1879.4109,318.735 1884.6167,322.4569 1889.2019,316.0436 1883.9961,312.3217 		'
				/>
				<g>
					<text
						transform='matrix(0.5816 -0.8135 0.8135 0.5816 1864.2754 399.1775)'
						className={S.Note}>
						{buildingLabel('Building 38')}
					</text>
				</g>
			</g>
			<g id='BUILDING-41-STATUS' className={buildingStyle('Building 41')}>
				<rect
					x='1169.448'
					y='446.2215'
					className={S.BackgroundColor}
					width='67.552'
					height='106.9599'
				/>
				<g>
					<text
						transform='matrix(0 -1 1 0 1205.436 536.0693)'
						className={S.Note}>
						{buildingLabel('Building 41')}
					</text>
				</g>
			</g>
			<g id='BUILDING-42-STATUS' className={buildingStyle('Building 42')}>
				<rect
					x='1170.1349'
					y='292.2153'
					className={S.BackgroundColor}
					width='66.8652'
					height='133.6997'
				/>
				<g>
					<text
						transform='matrix(0 -1 1 0 1205.7795 395.4326)'
						className={S.Note}>
						{buildingLabel('Building 42')}
					</text>
				</g>
			</g>
			<g id='BUILDING-43-STATUS' className={buildingStyle('Building 43')}>
				<polygon
					className={S.BackgroundColor}
					points='1463.9163,456.1204 1463.9163,478.035 1466.7998,478.035 1466.7998,523.479 1463.9163,523.479
			1463.9163,545.3936 1436.412,545.3936 1436.412,537.7427 1425.1621,537.7427 1425.1621,545.3936 1370.1449,545.3936
			1370.1449,537.7427 1364.2023,537.7427 1364.2023,545.3936 1336.6963,545.3936 1336.6963,537.7427 1325.6237,537.7427
			1325.6237,545.3936 1297.0193,545.3936 1297.0193,524.2864 1293.6744,524.2864 1293.6744,477.2277 1297.0193,477.2277
			1297.0193,456.1204 1325.6237,456.1204 1325.6237,463.7713 1336.6963,463.7713 1336.6963,456.1204 1364.0319,456.1204
			1364.0319,463.7713 1370.1449,463.7713 1370.1449,456.1204 1425.1621,456.1204 1425.1621,463.7713 1436.412,463.7713
			1436.412,456.1204 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1343.8698 502.9689)'
						className={S.Note}>
						{buildingLabel('Building 43')}
					</text>
				</g>
			</g>
			<g id='BUILDING-44-STATUS' className={buildingStyle('Building 44')}>
				<polygon
					className={S.BackgroundColor}
					points='1648.0737,456.1204 1648.0737,477.2277 1651.4186,477.2277 1651.4186,524.2864 1648.0737,524.2864
			1648.0737,545.3936 1620.4694,545.3936 1620.4694,537.7427 1609.3967,537.7427 1609.3967,545.3936 1582.0612,545.3936
			1582.0612,537.7427 1575.9481,537.7427 1575.9481,545.3936 1520.9309,545.3936 1520.9309,537.7427 1509.681,537.7427
			1509.681,545.3936 1481.1768,545.3936 1481.1768,523.479 1478.2932,523.479 1478.2932,478.035 1481.1768,478.035
			1481.1768,456.1204 1509.681,456.1204 1509.681,463.7713 1520.9309,463.7713 1520.9309,456.1204 1575.9481,456.1204
			1575.9481,463.7713 1582.0612,463.7713 1582.0612,456.1204 1609.3967,456.1204 1609.3967,463.7713 1620.4694,463.7713
			1620.4694,456.1204 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1528.4882 502.969)'
						className={S.Note}>
						{buildingLabel('Building 44')}
					</text>
				</g>
			</g>
			<g id='BUILDING-45-STATUS' className={buildingStyle('Building 45')}>
				<polygon
					className={S.BackgroundColor}
					points='1383.582,299.9274 1412.1863,299.9274 1412.1863,307.5783 1423.2589,307.5783 1423.2589,299.9274
			1450.5945,299.9274 1450.5945,307.5783 1456.7075,307.5783 1456.7075,299.9274 1511.7247,299.9274 1511.7247,307.5783
			1522.9747,307.5783 1522.9747,299.9274 1550.479,299.9274 1550.479,321.842 1553.3624,321.842 1553.3624,367.286
			1550.479,367.286 1550.479,389.2006 1522.9747,389.2006 1522.9747,381.5497 1511.7247,381.5497 1511.7247,389.2006
			1456.7075,389.2006 1456.7075,381.5497 1450.5945,381.5497 1450.5945,389.2006 1423.2589,389.2006 1423.2589,381.5497
			1412.1863,381.5497 1412.1863,389.2006 1383.582,389.2006 1383.582,368.0934 1380.2372,368.0934 1380.2372,321.0346
			1383.582,321.0346 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1430.432 346.7761)'
						className={S.Note}>
						{buildingLabel('Building 45')}
					</text>
				</g>
			</g>
			<g id='BUILDING-46-STATUS' className={buildingStyle('Building 46')}>
				<polygon
					className={S.BackgroundColor}
					points='1735.5482,321.0346 1738.8931,321.0346 1738.8931,368.0934 1735.4313,368.0934 1735.4313,389.2006
			1707.9438,389.2006 1707.9438,381.5497 1696.8712,381.5497 1696.8712,389.2006 1669.5356,389.2006 1669.5356,381.5497
			1663.4226,381.5497 1663.4226,389.2006 1608.4054,389.2006 1608.4054,381.5497 1597.1555,381.5497 1597.1555,389.2006
			1568.6512,389.2006 1568.6512,367.286 1565.7677,367.286 1565.7677,321.842 1568.6512,321.842 1568.6512,299.9274
			1597.1555,299.9274 1597.1555,307.5783 1608.4054,307.5783 1608.4054,299.9274 1663.4226,299.9274 1663.4226,307.5783
			1669.5356,307.5783 1669.5356,299.9274 1696.8712,299.9274 1696.8712,307.5783 1707.9438,307.5783 1707.9438,299.9274
			1735.5482,299.9274 		'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1615.9623 346.7758)'
						className={S.Note}>
						{buildingLabel('Building 46')}
					</text>
				</g>
			</g>
			<g id='BUILDING-47-STATUS' className={buildingStyle('Building 47')}>
				<rect
					x='1155.2858'
					y='168.6252'
					className={S.BackgroundColor}
					width='135.5256'
					height='66.8517'
				/>
				<g>
					<text transform='matrix(1 0 0 1 1186.681 204.263)' className={S.Note}>
						{buildingLabel('Building 47')}
					</text>
				</g>
			</g>
			<g id='BUILDING-48-STATUS' className={buildingStyle('Building 48')}>
				<rect
					x='1310.4944'
					y='168.6252'
					className={S.BackgroundColor}
					width='107.191'
					height='66.8517'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1327.7222 204.263)'
						className={S.Note}>
						{buildingLabel('Building 48')}
					</text>
				</g>
			</g>
			<g id='BUILDING-49-STATUS' className={buildingStyle('Building 49')}>
				<rect
					x='1437.1317'
					y='168.6252'
					className={S.BackgroundColor}
					width='135.5256'
					height='66.8517'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1468.5267 204.263)'
						className={S.Note}>
						{buildingLabel('Building 49')}
					</text>
				</g>
			</g>
			<g id='BUILDING-50-STATUS' className={buildingStyle('Building 50')}>
				<rect
					x='1589.6937'
					y='168.6252'
					className={S.BackgroundColor}
					width='135.5256'
					height='66.8517'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1621.0887 204.263)'
						className={S.Note}>
						{buildingLabel('Building 50')}
					</text>
				</g>
			</g>
			<g id='BUILDING-51-STATUS' className={buildingStyle('Building 51')}>
				<rect
					x='1744.0845'
					y='168.6252'
					className={S.BackgroundColor}
					width='135.5256'
					height='66.8517'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1775.4795 204.263)'
						className={S.Note}>
						{buildingLabel('Building 51')}
					</text>
				</g>
			</g>
			<g id='BUILDING-52-STATUS' className={buildingStyle('Building 52')}>
				<rect
					x='1898.6698'
					y='168.6252'
					className={S.BackgroundColor}
					width='107.2611'
					height='66.8517'
				/>
				<g>
					<text
						transform='matrix(1 0 0 1 1915.9326 204.263)'
						className={S.Note}>
						{buildingLabel('Building 52')}
					</text>
				</g>
			</g>
		</g>
	);
};

export default BuildingStatus;
