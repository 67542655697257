import React from 'react';
import SCSS from '../Sales.module.scss';

const Building3 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-3'>
			<g
				onClick={() => click('B1')}
				className={checkHandler('110')}
				id='B1-110'>
				<rect
					x='843.25'
					y='593.34'
					className={SCSS.UnitBase}
					width='27.28'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 852.7204 619.2469)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 848.6574 630.5758)'
					className={SCSS.UnitNumber}>
					110
				</text>
				<circle cx='856.89' cy='638.73' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('111')} id='B-111'>
				<rect
					x='870.53'
					y='593.34'
					className={SCSS.UnitBase}
					width='27'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 881.8877 619.2469)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 875.7969 630.5758)'
					className={SCSS.UnitNumber}>
					111
				</text>
				<circle cx='884.03' cy='638.73' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('112')} id='B-112'>
				<rect
					x='897.53'
					y='593.34'
					className={SCSS.UnitBase}
					width='26.94'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 908.8591 619.2469)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 902.7683 630.5758)'
					className={SCSS.UnitNumber}>
					112
				</text>
				<circle cx='911' cy='638.73' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('B1')}
				className={checkHandler('113')}
				id='B1-113'>
				<rect
					x='924.47'
					y='593.34'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 933.8566 619.2469)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 929.7936 630.5758)'
					className={SCSS.UnitNumber}>
					113
				</text>
				<circle cx='938.03' cy='638.73' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-3-OUTLINE'>
				<path
					d='M951.58,593.34v67.01H843.25v-67.01H951.58 M953.58,591.34h-2H843.25h-2v2v67.01v2h2h108.33h2v-2v-67.01V591.34
				L953.58,591.34z'
				/>
			</g>
			<g id='BUILDING-LABEL-3' className={SCSS.BuildingLabel}>
				<rect x='869.71' y='664.58' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 877.2119 671.7123)'>BUILDING 3</text>
			</g>
		</g>
	);
};

export default Building3;
