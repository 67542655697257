import React from 'react';
import SCSS from '../Sales.module.scss';

const Building24 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-24'>
			<g
				onClick={() => click('A2')}
				className={checkHandler('222')}
				id='A2-222'>
				<polygon
					className={SCSS.UnitBase}
					points='737.41,1095.31 709.52,1095.31 709.52,1117.15 707.55,1117.15 707.55,1129.56 709.52,1129.56 
				709.52,1145.94 711.42,1145.94 711.42,1148.13 725.96,1148.13 725.96,1145.94 737.41,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 719.5128 1112.4325)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 715.7301 1123.7614)'
					className={SCSS.UnitNumber}>
					222
				</text>
				<circle cx='723.96' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('223')} id='A-223'>
				<polygon
					className={SCSS.UnitBase}
					points='763.69,1095.31 737.41,1095.31 737.41,1145.94 748.28,1145.94 748.28,1148.13 761.81,1148.13 
				761.81,1145.94 763.69,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 748.1293 1112.4325)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 742.3187 1123.7614)'
					className={SCSS.UnitNumber}>
					223
				</text>
				<circle cx='750.55' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('224')} id='A-224'>
				<polygon
					className={SCSS.UnitBase}
					points='790.69,1095.31 763.69,1095.31 763.69,1145.94 775.37,1145.94 775.37,1148.13 788.9,1148.13 
				788.9,1145.94 790.69,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 774.7684 1112.4325)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 768.9578 1123.7614)'
					className={SCSS.UnitNumber}>
					224
				</text>
				<circle cx='777.19' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('225')} id='A-225'>
				<polygon
					className={SCSS.UnitBase}
					points='817.64,1095.31 790.69,1095.31 790.69,1145.94 792.54,1145.94 792.54,1148.13 806.07,1148.13 
				806.07,1145.94 817.64,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 801.7399 1112.4325)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 795.9293 1123.7614)'
					className={SCSS.UnitNumber}>
					225
				</text>
				<circle cx='804.16' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A1')}
				className={checkHandler('226')}
				id='A1-226'>
				<polygon
					className={SCSS.UnitBase}
					points='847.57,1116.15 844.74,1116.15 844.74,1095.31 817.64,1095.31 817.64,1145.94 828.85,1145.94 
				828.85,1148.13 842.39,1148.13 842.39,1145.94 844.74,1145.94 844.74,1129.56 847.57,1129.56 			'
				/>
				<text
					transform='matrix(1 0 0 1 826.7372 1112.4325)'
					className={SCSS.PlanName}>
					A1
				</text>
				<text
					transform='matrix(1 0 0 1 822.9545 1123.7614)'
					className={SCSS.UnitNumber}>
					226
				</text>
				<circle cx='831.19' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-24-OUTLINE'>
				<path
					d='M844.74,1095.31v20.84h2.83v13.4h-2.83v16.38h-2.36v2.19h-13.53v-2.19h-22.78v2.19h-13.53v-2.19h-3.64v2.19h-13.53v-2.19
				h-13.56v2.19h-13.53v-2.19h-22.32v2.19h-14.53v-2.19h-1.91v-16.38h-1.97v-12.4h1.97v-21.84H844.74 M846.74,1093.31h-2H709.52h-2
				v2v19.84h-1.97v2v12.4v2h1.97v14.38v2h1.91v0.19v2h2h14.53h2v-2v-0.19h18.32v0.19v2h2h13.53h2v-2v-0.19h9.56v0.19v2h2h13.53h2
				h1.64h13.53h2v-2v-0.19h18.78v0.19v2h2h13.53h2v-2v-0.19h0.36h2v-2v-14.38h0.83h2v-2v-13.4v-2h-2h-0.83v-18.84V1093.31
				L846.74,1093.31z'
				/>
			</g>
			<g id='BUILDING-LABEL-24' className={SCSS.BuildingLabel}>
				<rect x='749.85' y='1080.29' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 755.1951 1087.4181)'>BUILDING 24</text>
			</g>
		</g>
	);
};

export default Building24;
