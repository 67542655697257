import React from 'react';
import SCSS from '../Sales.module.scss';

const Building37 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-37_1_'>
			<g
				onClick={() => click('E1')}
				className={checkHandler('476')}
				id='E1-476'>
				<polygon
					className={SCSS.UnitBase}
					points='1731.14,537.12 1726.6,533.88 1717.43,546.71 1738.5,561.78 1736.9,564.01 1753.59,575.94 
				1755.19,573.71 1769.95,584.26 1778.6,572.16 1775.13,569.68 1778.65,564.75 1734.14,532.92 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1741.5824 557.2089)'
					className={SCSS.PlanName}>
					E1
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1747.0372 566.3365)'
					className={SCSS.UnitNumber}>
					476
				</text>
				<circle cx='1762.89' cy='566.05' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('477')} id='D-477'>
				<polygon
					className={SCSS.UnitBase}
					points='1732.6,525.49 1737.14,528.73 1734.14,532.92 1778.65,564.75 1782.51,559.35 1785.98,561.83 
				1793.84,550.84 1741.32,513.29 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1754.7426 538.7728)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1759.424 548.9849)'
					className={SCSS.UnitNumber}>
					477
				</text>
				<circle cx='1775.28' cy='548.7' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('478')} id='E-478'>
				<polygon
					className={SCSS.UnitBase}
					points='1754.83,503.99 1750.29,500.74 1741.32,513.29 1793.84,550.84 1802.42,538.85 1798.82,536.28 
				1802.04,531.78 1757.65,500.04 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1767.0591 521.5995)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1771.3361 532.3785)'
					className={SCSS.UnitNumber}>
					478
				</text>
				<circle cx='1787.19' cy='532.09' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('479')} id='D-479'>
				<polygon
					className={SCSS.UnitBase}
					points='1756.29,492.36 1760.83,495.6 1757.65,500.04 1802.04,531.78 1806.21,525.95 1809.8,528.52 
				1817.12,518.28 1764.6,480.73 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1778.1029 506.1469)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1782.7843 516.3589)'
					className={SCSS.UnitNumber}>
					479
				</text>
				<circle cx='1798.64' cy='516.07' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('480')} id='E-480'>
				<polygon
					className={SCSS.UnitBase}
					points='1778.08,471.46 1773.54,468.22 1764.6,480.73 1817.12,518.28 1825.52,506.53 1821.93,503.96 
				1825.73,498.65 1781.34,466.91 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1790.54 488.8076)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1794.817 499.5865)'
					className={SCSS.UnitNumber}>
					480
				</text>
				<circle cx='1810.67' cy='499.3' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('481')} id='D-481'>
				<polygon
					className={SCSS.UnitBase}
					points='1832.91,496.2 1840.51,485.57 1787.99,448.02 1779.54,459.83 1784.08,463.08 1781.34,466.91 
				1825.73,498.65 1829.32,493.63 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1801.7516 473.1469)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1806.433 483.3589)'
					className={SCSS.UnitNumber}>
					481
				</text>
				<circle cx='1822.29' cy='483.07' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('482')} id='E-482'>
				<polygon
					className={SCSS.UnitBase}
					points='1849.52,472.97 1845.93,470.4 1849.09,465.98 1804.7,434.24 1801.96,438.07 1797.42,434.82 
				1787.99,448.02 1840.51,485.57 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1813.8198 456.2861)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1818.0968 467.065)'
					className={SCSS.UnitNumber}>
					482
				</text>
				<circle cx='1833.95' cy='466.78' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('D1')}
				className={checkHandler('483')}
				id='D1-483'>
				<polygon
					className={SCSS.UnitBase}
					points='1852.52,461.18 1856.11,463.75 1864.5,452.01 1849.74,441.46 1851.77,438.62 1835.08,426.68 
				1833.05,429.53 1811.98,414.46 1802.9,427.16 1807.44,430.41 1804.7,434.24 1849.09,465.98 			'
				/>
				<g>
					<g>
						<text
							transform='matrix(0.5807 -0.8141 0.8141 0.5807 1824.1832 441.6978)'
							className={SCSS.PlanName}>
							D1
						</text>
					</g>
					<g>
						<text
							transform='matrix(0.5807 -0.8141 0.8141 0.5807 1830.0419 450.2593)'
							className={SCSS.UnitNumber}>
							483
						</text>
					</g>
				</g>
				<circle cx='1845.9' cy='449.97' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-37-OUTLINE_1_'>
				<path
					d='M1811.98,414.46l21.07,15.06l2.03-2.84l16.69,11.93l-2.03,2.84l14.76,10.55l-8.39,11.74l-3.59-2.57l-3.43,4.79
				l-3.16,4.43l3.59,2.57l-9.01,12.6l-7.6,10.63l-3.59-2.57l-3.58,5.01l-3.8,5.32l3.59,2.57l-8.4,11.75l-7.32,10.24l-3.59-2.57
				l-4.17,5.83l-3.22,4.5l3.59,2.57l-8.57,11.99l-7.86,10.99l-3.47-2.48l-3.86,5.4l-3.53,4.93l3.47,2.48l-8.65,12.1l-14.76-10.55
				l-1.59,2.23l-16.69-11.93l1.59-2.23l-21.07-15.06l9.18-12.84l4.54,3.24l3-4.19l3-4.19l-4.54-3.24l8.72-12.19l8.97-12.55
				l4.54,3.24l2.82-3.94l3.18-4.44l-4.54-3.24l8.31-11.62l8.94-12.51l4.54,3.24l3.26-4.56l2.74-3.83l-4.54-3.24l8.45-11.81
				l9.43-13.2l4.54,3.24l2.74-3.83l2.74-3.83l-4.54-3.24L1811.98,414.46 M1811.52,411.67l-1.16,1.63l-9.08,12.7l-1.16,1.63
				l1.63,1.16l2.91,2.08l-1.58,2.2l-1.58,2.2l-2.91-2.08l-1.63-1.16l-1.16,1.63l-9.43,13.2l-8.45,11.81l-1.16,1.63l1.63,1.16
				l2.91,2.08l-1.58,2.2l-2.09,2.93l-2.91-2.08l-1.63-1.16l-1.16,1.63l-8.94,12.51l-8.31,11.62l-1.16,1.63l1.63,1.16l2.91,2.08
				l-2.01,2.82l-1.66,2.32l-2.91-2.08l-1.63-1.16l-1.16,1.63l-8.97,12.55l-8.72,12.19l-1.16,1.63l1.63,1.16l2.91,2.08l-1.84,2.57
				l-1.84,2.57l-2.91-2.08l-1.63-1.16l-1.16,1.63l-9.18,12.84l-1.16,1.63l1.63,1.16l19.44,13.9l-0.43,0.6l-1.16,1.63l1.63,1.16
				l16.69,11.93l1.63,1.16l1.16-1.63l0.43-0.6l13.13,9.39l1.63,1.16l1.16-1.63l8.65-12.1l1.16-1.63l-1.63-1.16l-1.85-1.32l2.36-3.3
				l2.7-3.77l1.85,1.32l1.63,1.16l1.16-1.63l7.86-10.99l8.57-11.99l1.16-1.63l-1.63-1.16l-1.96-1.4l2.05-2.87l3-4.2l1.96,1.4
				l1.63,1.16l1.16-1.63l7.32-10.24l8.4-11.75l1.16-1.63l-1.63-1.16l-1.96-1.4l2.64-3.69l2.42-3.39l1.96,1.4l1.63,1.16l1.16-1.63
				l7.6-10.63l9.01-12.6l1.16-1.63l-1.63-1.16l-1.96-1.4l2-2.8l2.26-3.16l1.96,1.4l1.63,1.16l1.16-1.63l8.39-11.74l1.16-1.63
				l-1.63-1.16l-13.13-9.39l0.87-1.22l1.16-1.63l-1.63-1.16l-16.69-11.93l-1.63-1.16l-1.16,1.63l-0.87,1.22l-19.44-13.9
				L1811.52,411.67L1811.52,411.67z'
				/>
			</g>
			<g id='BUILDING-LABEL-37' className={SCSS.BuildingLabel}>
				<rect
					x='1795.39'
					y='524.77'
					transform='matrix(0.5816 -0.8135 0.8135 0.5816 331.9799 1704.6289)'
					width='55.42'
					height='9.64'
				/>
				<text transform='matrix(0.5816 -0.8135 0.8135 0.5816 1811.9343 549.1515)'>
					BUILDING 37
				</text>
			</g>
		</g>
	);
};

export default Building37;
