import React from 'react';
import SCSS from '../Sales.module.scss';

const Building23 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-23'>
			<g
				onClick={() => click('A2')}
				className={checkHandler('218')}
				id='A2-218'>
				<polygon
					className={SCSS.UnitBase}
					points='612.5,1095.08 584.75,1095.08 584.75,1116.92 582.78,1116.92 582.78,1129.33 584.75,1129.33 
				584.75,1145.71 586.66,1145.71 586.66,1147.9 601.19,1147.9 601.19,1145.71 612.5,1145.71 			'
				/>
				<text
					transform='matrix(1 0 0 1 594.7466 1112.2046)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 590.9639 1123.5334)'
					className={SCSS.UnitNumber}>
					218
				</text>
				<circle cx='599.2' cy='1131.69' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('219')} id='A-219'>
				<polygon
					className={SCSS.UnitBase}
					points='639.35,1095.08 612.5,1095.08 612.5,1145.71 624.02,1145.71 624.02,1147.9 637.55,1147.9 
				637.55,1145.71 639.35,1145.71 			'
				/>
				<text
					transform='matrix(1 0 0 1 623.4219 1112.2046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 617.6114 1123.5334)'
					className={SCSS.UnitNumber}>
					219
				</text>
				<circle cx='625.85' cy='1131.69' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('220')} id='A-220'>
				<polygon
					className={SCSS.UnitBase}
					points='666.29,1095.08 639.35,1095.08 639.35,1145.71 641.19,1145.71 641.19,1147.9 654.73,1147.9 
				654.73,1145.71 666.29,1145.71 			'
				/>
				<text
					transform='matrix(1 0 0 1 650.3934 1112.2046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 644.5829 1123.5334)'
					className={SCSS.UnitNumber}>
					220
				</text>
				<circle cx='652.82' cy='1131.69' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A2')}
				className={checkHandler('221')}
				id='A2-221'>
				<polygon
					className={SCSS.UnitBase}
					points='697.23,1115.92 694.4,1115.92 694.4,1095.08 666.29,1095.08 666.29,1145.71 677.51,1145.71 
				677.51,1147.9 692.04,1147.9 692.04,1145.71 694.4,1145.71 694.4,1129.33 697.23,1129.33 			'
				/>
				<text
					transform='matrix(1 0 0 1 675.3908 1112.2046)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 671.6081 1123.5334)'
					className={SCSS.UnitNumber}>
					221
				</text>
				<circle cx='679.84' cy='1131.69' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-23-OUTLINE'>
				<path
					d='M694.4,1095.08v20.84h2.83v13.4h-2.83v16.38h-2.36v2.19h-14.53v-2.19h-22.78v2.19h-13.53v-2.19h-3.64v2.19h-13.53v-2.19
				h-22.83v2.19h-14.53v-2.19h-1.91v-16.38h-1.97v-12.4h1.97v-21.84H694.4 M696.4,1093.08h-2H584.75h-2v2v19.84h-1.97v2v12.4v2h1.97
				v14.38v2h1.91v0.19v2h2h14.53h2v-2v-0.19h18.83v0.19v2h2h13.53h2h1.64h13.53h2v-2v-0.19h18.78v0.19v2h2h14.53h2v-2v-0.19h0.36h2
				v-2v-14.38h0.83h2v-2v-13.4v-2h-2h-0.83v-18.84V1093.08L696.4,1093.08z'
				/>
			</g>
			<g id='BUILDING-LABEL-23' className={SCSS.BuildingLabel}>
				<rect x='612.29' y='1080.29' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 617.6389 1087.4181)'>BUILDING 23</text>
			</g>
		</g>
	);
};

export default Building23;
