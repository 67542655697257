import React from 'react';
import SCSS from '../Sales.module.scss';

const Building7 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-7'>
			<g
				onClick={() => click('B1')}
				className={checkHandler('128')}
				id='B1-128'>
				<rect
					x='465.67'
					y='764.98'
					className={SCSS.UnitBase}
					width='26.9'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 474.9517 790.8925)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 470.8888 802.2214)'
					className={SCSS.UnitNumber}>
					128
				</text>
				<circle cx='479.12' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('129')} id='B-129'>
				<rect
					x='492.57'
					y='764.98'
					className={SCSS.UnitBase}
					width='26.28'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 503.5681 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 497.4773 802.2214)'
					className={SCSS.UnitNumber}>
					129
				</text>
				<circle cx='505.71' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('130')} id='B-130'>
				<rect
					x='518.85'
					y='764.98'
					className={SCSS.UnitBase}
					width='27'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 530.2073 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 524.1165 802.2214)'
					className={SCSS.UnitNumber}>
					130
				</text>
				<circle cx='532.35' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('131')} id='B-131'>
				<rect
					x='545.85'
					y='764.98'
					className={SCSS.UnitBase}
					width='26.94'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 557.1787 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 551.0879 802.2214)'
					className={SCSS.UnitNumber}>
					131
				</text>
				<circle cx='559.32' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('132')} id='B-132'>
				<rect
					x='572.79'
					y='764.98'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 584.204 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 578.1132 802.2214)'
					className={SCSS.UnitNumber}>
					132
				</text>
				<circle cx='586.35' cy='810.37' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('B1')}
				className={checkHandler('133')}
				id='B1-133'>
				<rect
					x='599.9'
					y='764.98'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 609.2837 790.8925)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 605.2207 802.2214)'
					className={SCSS.UnitNumber}>
					133
				</text>
				<circle cx='613.46' cy='810.37' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-7-OUTLINE'>
				<path
					d='M627.01,764.98v67.01H465.67v-67.01H627.01 M629.01,762.98h-2H465.67h-2v2v67.01v2h2h161.34h2v-2v-67.01V762.98
				L629.01,762.98z'
				/>
			</g>
			<g id='BUILDING-LABEL-7' className={SCSS.BuildingLabel}>
				<rect x='518.63' y='749.85' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 526.136 756.979)'>BUILDING 7</text>
			</g>
		</g>
	);
};

export default Building7;
