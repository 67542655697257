import React from 'react';
import SCSS from '../Rentals.module.scss';

const Building46 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'disable':
				classes = SCSS.Disable;
				break;
			default:
				classes = SCSS.Availabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-46'>
			<g id='UNIT-344'>
				<polygon
					className={SCSS.UnitBase}
					points='1602.98,307.58 1597.16,307.58 1597.16,299.93 1568.65,299.93 1568.65,321.84 1565.77,321.84 
				1565.77,344.56 1602.98,344.56 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('344 C')}
					id='C-344'>
					<text
						transform='matrix(1 0 0 1 1580.986 315.5383)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1575.0469 325.0408)'
						className={SCSS.UnitNumber}>
						344
					</text>
					<circle cx='1583.28' cy='332.2' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-345'>
				<polygon
					className={SCSS.UnitBase}
					points='1635.74,299.93 1608.41,299.93 1608.41,307.58 1602.98,307.58 1602.98,344.56 1635.74,344.56 			
				'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('345 C')}
					id='C-345'>
					<text
						transform='matrix(1 0 0 1 1617.4913 315.5383)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1611.5522 325.0408)'
						className={SCSS.UnitNumber}>
						345
					</text>
					<circle cx='1619.79' cy='332.2' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-346'>
				<polygon
					className={SCSS.UnitBase}
					points='1666.88,307.58 1663.42,307.58 1663.42,299.93 1635.74,299.93 1635.74,344.56 1666.88,344.56 			
				'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('346 C')}
					id='C-346'>
					<text
						transform='matrix(1 0 0 1 1650.8239 315.5383)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1644.8848 325.0408)'
						className={SCSS.UnitNumber}>
						346
					</text>
					<circle cx='1653.12' cy='332.2' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-347'>
				<polygon
					className={SCSS.UnitBase}
					points='1702.41,307.58 1696.87,307.58 1696.87,299.93 1669.54,299.93 1669.54,307.58 1666.88,307.58 
				1666.88,344.56 1702.41,344.56 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('347 C')}
					id='C-347'>
					<text
						transform='matrix(1 0 0 1 1682.7732 315.5383)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1676.8341 325.0408)'
						className={SCSS.UnitNumber}>
						347
					</text>
					<circle cx='1685.07' cy='332.2' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-348'>
				<polygon
					className={SCSS.UnitBase}
					points='1738.89,344.56 1702.41,344.56 1702.41,307.58 1707.94,307.58 1707.94,299.93 1735.55,299.93 
				1735.55,321.03 1738.89,321.03 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('348 C')}
					id='C-348'>
					<text
						transform='matrix(1 0 0 1 1717.2598 315.5383)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1711.3208 325.0408)'
						className={SCSS.UnitNumber}>
						348
					</text>
					<circle cx='1719.56' cy='332.2' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-334'>
				<polygon
					className={SCSS.UnitBase}
					points='1602.98,381.55 1597.16,381.55 1597.16,389.2 1568.65,389.2 1568.65,367.29 1565.77,367.29 
				1565.77,344.56 1602.98,344.56 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('334 C')}
					id='C-334'>
					<text
						transform='matrix(1 0 0 1 1580.986 356.8272)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1575.0469 366.3297)'
						className={SCSS.UnitNumber}>
						334
					</text>
					<circle cx='1583.28' cy='373.49' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-335'>
				<polygon
					className={SCSS.UnitBase}
					points='1635.74,389.2 1608.41,389.2 1608.41,381.55 1602.98,381.55 1602.98,344.56 1635.74,344.56 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('335 C')}
					id='C-335'>
					<text
						transform='matrix(1 0 0 1 1617.4913 356.8272)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1611.5522 366.3297)'
						className={SCSS.UnitNumber}>
						335
					</text>
					<circle cx='1619.79' cy='373.49' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-336'>
				<polygon
					className={SCSS.UnitBase}
					points='1666.88,381.55 1663.42,381.55 1663.42,389.2 1635.74,389.2 1635.74,344.56 1666.88,344.56 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('336 C')}
					id='C-336'>
					<text
						transform='matrix(1 0 0 1 1650.8239 356.8272)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1644.8848 366.3297)'
						className={SCSS.UnitNumber}>
						336
					</text>
					<circle cx='1653.12' cy='373.49' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-337'>
				<polygon
					className={SCSS.UnitBase}
					points='1702.41,381.55 1696.87,381.55 1696.87,389.2 1669.54,389.2 1669.54,381.55 1666.88,381.55 
				1666.88,344.56 1702.41,344.56 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('337 C')}
					id='C-337'>
					<text
						transform='matrix(1 0 0 1 1682.7732 356.8272)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1676.8341 366.3297)'
						className={SCSS.UnitNumber}>
						337
					</text>
					<circle cx='1685.07' cy='373.49' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-338'>
				<polygon
					className={SCSS.UnitBase}
					points='1738.89,344.56 1702.41,344.56 1702.41,381.55 1707.94,381.55 1707.94,389.2 1735.55,389.2 
				1735.55,368.09 1738.89,368.09 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('338 C')}
					id='C-338'>
					<text
						transform='matrix(1 0 0 1 1717.2598 356.8272)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1711.3208 366.3297)'
						className={SCSS.UnitNumber}>
						338
					</text>
					<circle cx='1719.56' cy='373.49' r='3.45' className="dot" />
				</g>
			</g>
			<g id='RENTAL-BUILDING-6-OUTLINE'>
				<path
					d='M1735.55,299.93v21.11h3.34v47.06h-3.34v21.11h-27.6v-7.65h-11.07v7.65h-27.34v-7.65h-6.11v7.65h-55.02v-7.65h-11.25
				v7.65h-28.5v-21.91h-2.88v-45.44h2.88v-21.91h28.5v7.65h11.25v-7.65h55.02v7.65h6.11v-7.65h27.34v7.65h11.07v-7.65H1735.55
				 M1737.55,297.93h-2h-27.6h-2v2v5.65h-7.07v-5.65v-2h-2h-27.34h-2v2v5.65h-2.11v-5.65v-2h-2h-55.02h-2v2v5.65h-7.25v-5.65v-2h-2
				h-28.5h-2v2v19.91h-0.88h-2v2v45.44v2h2h0.88v19.91v2h2h28.5h2v-2v-5.65h7.25v5.65v2h2h55.02h2v-2v-5.65h2.11v5.65v2h2h27.34h2
				v-2v-5.65h7.07v5.65v2h2h27.6h2v-2v-19.11h1.34h2v-2v-47.06v-2h-2h-1.34v-19.11V297.93L1737.55,297.93z'
				/>
			</g>
			<g id='BUILDING-LABEL-46' className={SCSS.BuildingLabel}>
				<rect x='1621.96' y='393.41' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1627.3049 400.5446)'>BUILDING 46</text>
			</g>
		</g>
	);
};

export default Building46;
