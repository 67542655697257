import React from 'react';
import SCSS from '../Sales.module.scss';

const Building25 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-25'>
			<g
				onClick={() => click('A1')}
				className={checkHandler('227')}
				id='A1-227'>
				<polygon
					className={SCSS.UnitBase}
					points='897.9,1095.31 870.01,1095.31 870.01,1117.15 868.04,1117.15 868.04,1129.56 870.01,1129.56 
				870.01,1145.94 871.91,1145.94 871.91,1148.13 886.45,1148.13 886.45,1145.94 897.9,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 880.0027 1112.4325)'
					className={SCSS.PlanName}>
					A1
				</text>
				<text
					transform='matrix(1 0 0 1 876.22 1123.7614)'
					className={SCSS.UnitNumber}>
					227
				</text>
				<circle cx='884.45' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('228')} id='A-228'>
				<polygon
					className={SCSS.UnitBase}
					points='924.18,1095.31 897.9,1095.31 897.9,1145.94 908.77,1145.94 908.77,1148.13 922.3,1148.13 
				922.3,1145.94 924.18,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 908.6191 1112.4325)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 902.8086 1123.7614)'
					className={SCSS.UnitNumber}>
					228
				</text>
				<circle cx='911.04' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('229')} id='A-229'>
				<polygon
					className={SCSS.UnitBase}
					points='924.18,1095.31 951.18,1095.31 951.18,1145.94 939.51,1145.94 939.51,1148.13 925.98,1148.13 
				925.98,1145.94 924.18,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 935.2582 1112.4325)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 929.4476 1123.7614)'
					className={SCSS.UnitNumber}>
					229
				</text>
				<circle cx='937.68' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('230')} id='A-230'>
				<polygon
					className={SCSS.UnitBase}
					points='978.13,1095.31 951.18,1095.31 951.18,1145.94 953.03,1145.94 953.03,1148.13 966.56,1148.13 
				966.56,1145.94 978.13,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 962.2297 1112.4325)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 956.4191 1123.7614)'
					className={SCSS.UnitNumber}>
					230
				</text>
				<circle cx='964.65' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A2')}
				className={checkHandler('231')}
				id='A2-231'>
				<polygon
					className={SCSS.UnitBase}
					points='1008.06,1116.15 1005.23,1116.15 1005.23,1095.31 978.13,1095.31 978.13,1145.94 989.34,1145.94 
				989.34,1148.13 1002.87,1148.13 1002.87,1145.94 1005.23,1145.94 1005.23,1129.56 1008.06,1129.56 			'
				/>
				<text
					transform='matrix(1 0 0 1 987.2271 1112.4325)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 983.4443 1123.7614)'
					className={SCSS.UnitNumber}>
					231
				</text>
				<circle cx='991.68' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-25-OUTLINE'>
				<path
					d='M1005.23,1095.31v20.84h2.83v13.4h-2.83v16.38h-2.36v2.19h-13.53v-2.19h-22.78v2.19h-13.53v-2.19h-13.52v2.19h-13.53
				v-2.19h-3.68v2.19h-13.53v-2.19h-22.32v2.19h-14.53v-2.19h-1.91v-16.38h-1.97v-12.4h1.97v-21.84H1005.23 M1007.23,1093.31h-2
				H870.01h-2v2v19.84h-1.97v2v12.4v2h1.97v14.38v2h1.91v0.19v2h2h14.53h2v-2v-0.19h18.32v0.19v2h2h13.53h2h1.68h13.53h2v-2v-0.19
				h9.52v0.19v2h2h13.53h2v-2v-0.19h18.78v0.19v2h2h13.53h2v-2v-0.19h0.36h2v-2v-14.38h0.83h2v-2v-13.4v-2h-2h-0.83v-18.84V1093.31
				L1007.23,1093.31z'
				/>
			</g>
			<g id='BUILDING-LABEL-25' className={SCSS.BuildingLabel}>
				<rect x='910.34' y='1080.29' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 915.6849 1087.4181)'>BUILDING 25</text>
			</g>
		</g>
	);
};

export default Building25;
