import React from 'react';
import SCSS from '../Sales.module.scss';

const Building35 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-35_1_'>
			<g
				onClick={() => click('E2')}
				className={checkHandler('456')}
				id='E2-456'>
				<polygon
					className={SCSS.UnitBase}
					points='1433.4,953.6 1429.39,950.74 1419.1,965.12 1439.96,980.04 1438.38,982.24 1455.07,994.17 
			1456.65,991.97 1471.27,1002.42 1480.05,990.13 1476.36,987.49 1482.77,978.53 1438.31,946.74 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1445.0353 972.0526)'
					className={SCSS.PlanName}>
					E2
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1450.4901 981.1802)'
					className={SCSS.UnitNumber}>
					456
				</text>
				<circle cx='1466.35' cy='980.89' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('457')} id='D-457'>
				<polygon
					className={SCSS.UnitBase}
					points='1447.18,934.33 1443.17,931.46 1434.29,943.87 1438.31,946.74 1438.31,946.74 1482.77,978.53 
			1482.77,978.53 1482.83,978.57 1486.46,981.17 1494.81,969.49 1491.11,966.85 1494.5,962.12 1450.04,930.33 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1458.9767 952.4574)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1463.6581 962.6695)'
					className={SCSS.UnitNumber}>
					457
				</text>
				<circle cx='1479.51' cy='962.38' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('458')} id='E-458'>
				<polygon
					className={SCSS.UnitBase}
					points='1448.88,923.47 1452.9,926.33 1450.04,930.33 1494.5,962.12 1498,957.22 1501.69,959.86 
			1509.96,948.3 1457.79,911.01 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1471.2253 935.4257)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1475.5023 946.2047)'
					className={SCSS.UnitNumber}>
					458
				</text>
				<circle cx='1491.36' cy='945.92' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('459')} id='D-459'>
				<polygon
					className={SCSS.UnitBase}
					points='1470.52,901.68 1466.51,898.81 1457.79,911.01 1509.96,948.3 1518.29,936.64 1514.6,934 
			1517.65,929.74 1473.19,897.95 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1482.4852 919.6635)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1487.1666 929.8755)'
					className={SCSS.UnitNumber}>
					459
				</text>
				<circle cx='1503.02' cy='929.59' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('460')} id='E-460'>
				<polygon
					className={SCSS.UnitBase}
					points='1472.22,890.82 1476.24,893.69 1473.19,897.95 1517.65,929.74 1521.49,924.37 1525.18,927.01 
			1533.77,914.99 1481.61,877.69 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1494.7151 902.5068)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1498.9921 913.2857)'
					className={SCSS.UnitNumber}>
					460
				</text>
				<circle cx='1514.85' cy='913' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('461')} id='D-461'>
				<polygon
					className={SCSS.UnitBase}
					points='1494.25,868.5 1490.23,865.63 1481.61,877.69 1533.77,914.99 1541.61,904.03 1537.92,901.39 
			1541.22,896.78 1496.75,864.99 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1506.1107 886.5961)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1510.7921 896.8082)'
					className={SCSS.UnitNumber}>
					461
				</text>
				<circle cx='1526.65' cy='896.52' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('462')} id='E-462'>
				<polygon
					className={SCSS.UnitBase}
					points='1495.95,857.63 1499.96,860.5 1496.75,864.99 1541.22,896.78 1545.01,891.47 1548.7,894.11 
			1556.87,882.68 1504.71,845.39 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1518.073 869.8554)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1522.35 880.6343)'
					className={SCSS.UnitNumber}>
					462
				</text>
				<circle cx='1538.21' cy='880.35' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('463')} id='D-463'>
				<polygon
					className={SCSS.UnitBase}
					points='1520.47,831.81 1517.52,835.95 1513.51,833.08 1504.71,845.39 1556.87,882.68 1565.13,871.13 
			1561.44,868.49 1564.94,863.6 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1529.2987 854.1859)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1533.9801 864.398)'
					className={SCSS.UnitNumber}>
					463
				</text>
				<circle cx='1549.84' cy='864.11' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('464')} id='E-464'>
				<polygon
					className={SCSS.UnitBase}
					points='1568.53,858.57 1572.22,861.21 1579.84,850.55 1527.68,813.26 1519.22,825.08 1523.23,827.95 
			1520.47,831.81 1564.94,863.6 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1541.2732 837.3779)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1545.5502 848.1568)'
					className={SCSS.UnitNumber}>
					464
				</text>
				<circle cx='1561.41' cy='847.87' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('D2')}
				className={checkHandler('465')}
				id='D2-465'>
				<polygon
					className={SCSS.UnitBase}
					points='1579.85,850.55 1576.16,847.91 1580.44,841.92 1584.13,844.55 1592.31,833.11 1577.7,822.66 
			1579.3,820.42 1562.61,808.49 1561.01,810.73 1540.15,795.81 1531.3,808.19 1535.31,811.05 1531.69,816.12 1527.68,813.25 
			1527.68,813.26 1579.84,850.55 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1551.9869 822.4536)'
					className={SCSS.PlanName}>
					D2
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1557.8456 831.0152)'
					className={SCSS.UnitNumber}>
					465
				</text>
				<circle cx='1573.7' cy='830.73' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-35-OUTLINE_1_'>
				<path
					d='M1540.15,795.81l20.86,14.91l1.6-2.24l16.69,11.93l-1.6,2.24l14.61,10.45l-8.18,11.45l-3.69-2.64l-4.28,5.99l3.69,2.64
			l0,0.01l-7.62,10.65l-3.69-2.64l-3.6,5.03l-3.5,4.89l3.69,2.64l-8.26,11.55l-8.17,11.42l-3.69-2.64l-3.8,5.31l-3.3,4.61
			l3.69,2.64l-7.84,10.96l-8.59,12.02l-3.69-2.64l-3.84,5.37l-3.05,4.26l3.69,2.64l-8.34,11.66l-8.26,11.56l-3.69-2.64l-3.5,4.9
			l-3.38,4.73l3.69,2.64l-8.35,11.67l-3.63-2.59l-0.07-0.05l-6.41,8.96l3.69,2.64l-8.79,12.29l-14.61-10.45l-1.58,2.21
			l-16.69-11.93l1.58-2.21l-20.86-14.91l10.29-14.39l4.01,2.87l4.91-6.86l-4.01-2.87l8.87-12.41l4.01,2.87l2.86-4l2.86-4
			l-4.01-2.87l8.91-12.46l8.72-12.19l4.01,2.87l2.67-3.73l3.05-4.27l-4.01-2.87l9.38-13.12l8.63-12.07l4.01,2.87l2.51-3.51
			l3.21-4.49l-4.01-2.87l8.75-12.24l8.8-12.31l4.01,2.87l2.96-4.14l2.76-3.86l-4.01-2.87l8.45-11.83l0-0.01l4.01,2.87l3.62-5.07
			l-4.01-2.87L1540.15,795.81 M1539.69,793.02l-1.16,1.63l-8.85,12.37l-1.16,1.63l1.63,1.16l2.38,1.71l-1.29,1.81l-2.38-1.71
			l-1.63-1.16l-1.16,1.63l-8.46,11.83l-1.16,1.63l1.63,1.16l2.38,1.7l-1.6,2.23l-1.79,2.51l-2.38-1.7l-1.63-1.16l-1.16,1.63
			l-8.8,12.31l-8.75,12.24l-1.16,1.63l1.63,1.16l2.38,1.7l-2.05,2.86l-1.34,1.88l-2.38-1.7l-1.63-1.16l-1.16,1.63l-8.63,12.07
			l-9.38,13.12l-1.16,1.63l1.63,1.16l2.38,1.7l-1.89,2.64l-1.5,2.1l-2.38-1.7l-1.63-1.16l-1.16,1.63l-8.72,12.19l-8.91,12.46
			l-1.16,1.63l1.63,1.16l2.38,1.7l-1.7,2.37l-1.7,2.37l-2.38-1.7l-1.63-1.16l-1.16,1.63l-8.87,12.41l-1.16,1.63l1.63,1.16l2.38,1.7
			l-2.58,3.61l-2.38-1.7l-1.63-1.16l-1.16,1.63l-10.29,14.39l-1.16,1.63l1.63,1.16l19.23,13.75l-0.41,0.58l-1.16,1.63l1.63,1.16
			l16.69,11.93l1.63,1.16l1.16-1.63l0.41-0.58l12.99,9.29l1.63,1.16l1.16-1.63l8.79-12.29l1.16-1.63l-1.63-1.16l-2.06-1.48
			l4.08-5.71l2.06,1.48l1.63,1.16l1.16-1.63l8.35-11.67l1.16-1.63l-1.63-1.16l-2.06-1.48l2.22-3.11l2.34-3.27l2.06,1.48l1.63,1.16
			l1.16-1.63l8.26-11.56l8.34-11.66l1.16-1.63l-1.63-1.16l-2.06-1.48l1.88-2.63l2.68-3.75l2.06,1.48l1.63,1.16l1.16-1.63
			l8.59-12.02l7.84-10.96l1.16-1.63l-1.63-1.16l-2.06-1.48l2.13-2.98l2.63-3.68l2.06,1.48l1.63,1.16l1.16-1.63l8.17-11.42
			l8.26-11.55l1.16-1.63l-1.63-1.16l-2.06-1.48l2.33-3.26l2.43-3.4l2.06,1.48l1.63,1.16l1.16-1.63l7.62-10.65l1.16-1.63l-1.62-1.17
			l-2.06-1.48l1.96-2.74l2.06,1.48l1.63,1.16l1.16-1.63l8.18-11.45l1.16-1.63l-1.63-1.16l-12.99-9.29l0.44-0.61l1.16-1.63
			l-1.63-1.16l-16.69-11.93l-1.63-1.16l-1.16,1.63l-0.44,0.61l-19.23-13.75L1539.69,793.02L1539.69,793.02z'
				/>
			</g>
			<g id='BUILDING-LABEL-35' className={SCSS.BuildingLabel}>
				<rect
					x='1504.18'
					y='929.75'
					transform='matrix(0.5816 -0.8135 0.8135 0.5816 -119.3046 1637.1729)'
					width='55.42'
					height='9.64'
				/>
				<text transform='matrix(0.5816 -0.8135 0.8135 0.5816 1520.7163 954.13)'>
					BUILDING 35
				</text>
			</g>
		</g>
	);
};

export default Building35;
