import React from 'react';
import SCSS from '../Rentals.module.scss';

const Building44 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'disable':
				classes = SCSS.Disable;
				break;
			default:
				classes = SCSS.Availabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-44'>
			<g id='UNIT-324'>
				<polygon
					className={SCSS.UnitBase}
					points='1515.51,463.77 1509.68,463.77 1509.68,456.12 1481.18,456.12 1481.18,478.04 1478.29,478.04 
				1478.29,500.76 1515.51,500.76 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('324 C')}
					id='C-324'>
					<text
						transform='matrix(1 0 0 1 1494.6061 471.7315)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1488.667 481.234)'
						className={SCSS.UnitNumber}>
						324
					</text>
					<circle cx='1496.9' cy='488.4' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-325'>
				<polygon
					className={SCSS.UnitBase}
					points='1548.27,456.12 1520.93,456.12 1520.93,463.77 1515.51,463.77 1515.51,500.76 1548.27,500.76 			
				'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('325 C')}
					id='C-325'>
					<text
						transform='matrix(1 0 0 1 1529.5924 471.7315)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1523.6533 481.234)'
						className={SCSS.UnitNumber}>
						325
					</text>
					<circle cx='1531.89' cy='488.4' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-326'>
				<polygon
					className={SCSS.UnitBase}
					points='1579.41,463.77 1575.95,463.77 1575.95,456.12 1548.27,456.12 1548.27,500.76 1579.41,500.76 			
				'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('326 C')}
					id='C-326'>
					<text
						transform='matrix(1 0 0 1 1561.5414 471.7315)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1555.6023 481.234)'
						className={SCSS.UnitNumber}>
						326
					</text>
					<circle cx='1563.84' cy='488.4' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-327'>
				<polygon
					className={SCSS.UnitBase}
					points='1614.93,463.77 1609.4,463.77 1609.4,456.12 1582.06,456.12 1582.06,463.77 1579.41,463.77 
				1579.41,500.76 1614.93,500.76 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('327 C')}
					id='C-327'>
					<text
						transform='matrix(1 0 0 1 1594.8748 471.7315)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1588.9357 481.234)'
						className={SCSS.UnitNumber}>
						327
					</text>
					<circle cx='1597.17' cy='488.4' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-328'>
				<polygon
					className={SCSS.UnitBase}
					points='1651.42,500.76 1614.93,500.76 1614.93,463.77 1620.47,463.77 1620.47,456.12 1648.07,456.12 
				1648.07,477.23 1651.42,477.23 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('328 C')}
					id='C-328'>
					<text
						transform='matrix(1 0 0 1 1630.8804 471.7315)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1624.9414 481.234)'
						className={SCSS.UnitNumber}>
						328
					</text>
					<circle cx='1633.18' cy='488.4' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-314'>
				<polygon
					className={SCSS.UnitBase}
					points='1515.51,537.74 1509.68,537.74 1509.68,545.39 1481.18,545.39 1481.18,523.48 1478.29,523.48 
				1478.29,500.76 1515.51,500.76 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('314 C')}
					id='C-314'>
					<text
						transform='matrix(1 0 0 1 1494.6061 516.3679)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1488.667 525.8705)'
						className={SCSS.UnitNumber}>
						314
					</text>
					<circle cx='1496.9' cy='533.03' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-315'>
				<polygon
					className={SCSS.UnitBase}
					points='1548.27,545.39 1520.93,545.39 1520.93,537.74 1515.51,537.74 1515.51,500.76 1548.27,500.76 			
				'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('315 C')}
					id='C-315'>
					<text
						transform='matrix(1 0 0 1 1529.5924 516.3679)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1523.6533 525.8705)'
						className={SCSS.UnitNumber}>
						315
					</text>
					<circle cx='1531.89' cy='533.03' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-316'>
				<polygon
					className={SCSS.UnitBase}
					points='1579.41,537.74 1575.95,537.74 1575.95,545.39 1548.27,545.39 1548.27,500.76 1579.41,500.76 			
				'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('316 C')}
					id='C-316'>
					<text
						transform='matrix(1 0 0 1 1561.5414 516.3679)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1555.6023 525.8705)'
						className={SCSS.UnitNumber}>
						316
					</text>
					<circle cx='1563.84' cy='533.03' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-317'>
				<polygon
					className={SCSS.UnitBase}
					points='1614.93,537.74 1609.4,537.74 1609.4,545.39 1582.06,545.39 1582.06,537.74 1579.41,537.74 
				1579.41,500.76 1614.93,500.76 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('317 C')}
					id='C-317'>
					<text
						transform='matrix(1 0 0 1 1594.8748 516.3679)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1588.9357 525.8705)'
						className={SCSS.UnitNumber}>
						317
					</text>
					<circle cx='1597.17' cy='533.03' r='3.45' className="dot" />
				</g>
			</g>
			<g id='UNIT-318'>
				<polygon
					className={SCSS.UnitBase}
					points='1651.42,500.76 1614.93,500.76 1614.93,537.74 1620.47,537.74 1620.47,545.39 1648.07,545.39 
				1648.07,524.29 1651.42,524.29 			'
				/>
				<g
					onClick={() => click('C')}
					className={checkHandler('318 C')}
					id='C-318'>
					<text
						transform='matrix(1 0 0 1 1630.8804 516.3679)'
						className={SCSS.PlanName}>
						C
					</text>
					<text
						transform='matrix(1 0 0 1 1624.9414 525.8705)'
						className={SCSS.UnitNumber}>
						318
					</text>
					<circle cx='1633.18' cy='533.03' r='3.45' className="dot" />
				</g>
			</g>
			<g id='RENTAL-BUILDING-4-OUTLINE'>
				<path
					d='M1648.07,456.12v21.11h3.34v47.06h-3.34v21.11h-27.6v-7.65h-11.07v7.65h-27.34v-7.65h-6.11v7.65h-55.02v-7.65h-11.25
				v7.65h-28.5v-21.91h-2.88v-45.44h2.88v-21.91h28.5v7.65h11.25v-7.65h55.02v7.65h6.11v-7.65h27.34v7.65h11.07v-7.65H1648.07
				 M1650.07,454.12h-2h-27.6h-2v2v5.65h-7.07v-5.65v-2h-2h-27.34h-2v2v5.65h-2.11v-5.65v-2h-2h-55.02h-2v2v5.65h-7.25v-5.65v-2h-2
				h-28.5h-2v2v19.91h-0.88h-2v2v45.44v2h2h0.88v19.91v2h2h28.5h2v-2v-5.65h7.25v5.65v2h2h55.02h2v-2v-5.65h2.11v5.65v2h2h27.34h2
				v-2v-5.65h7.07v5.65v2h2h27.6h2v-2v-19.11h1.34h2v-2v-47.06v-2h-2h-1.34v-19.11V454.12L1650.07,454.12z'
				/>
			</g>
			<g id='BUILDING-LABEL-44' className={SCSS.BuildingLabel}>
				<rect x='1536.12' y='549.77' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1541.4678 556.905)'>BUILDING 44</text>
			</g>
		</g>
	);
};

export default Building44;
