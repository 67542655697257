import React from 'react';
import _ from 'lodash';
import Building1 from './Buildings/Building1';
import Building2 from './Buildings/Building2';
import Building3 from './Buildings/Building3';
import Building4 from './Buildings/Building4';
import Building5 from './Buildings/Building5';
import Building6 from './Buildings/Building6';
import Building7 from './Buildings/Building7';
import Building8 from './Buildings/Building8';
import Building9 from './Buildings/Building9';
import Building10 from './Buildings/Building10';

import Building11 from './Buildings/Building11';
import Building12 from './Buildings/Building12';
import Building13 from './Buildings/Building13';
import Building14 from './Buildings/Building14';
import Building15 from './Buildings/Building15';
import Building16 from './Buildings/Building16';
import Building17 from './Buildings/Building17';
import Building18 from './Buildings/Building18';
import Building19 from './Buildings/Building19';
import Building20 from './Buildings/Building20';

import Building21 from './Buildings/Building21';
import Building22 from './Buildings/Building22';
import Building23 from './Buildings/Building23';
import Building24 from './Buildings/Building24';
import Building25 from './Buildings/Building25';
import Building26 from './Buildings/Building26';
import Building27 from './Buildings/Building27';
import Building28 from './Buildings/Building28';
import Building29 from './Buildings/Building29';
import Building30 from './Buildings/Building30';

import Building31 from './Buildings/Building31';
import Building32 from './Buildings/Building32';
import Building33 from './Buildings/Building33';
import Building34 from './Buildings/Building34';
import Building35 from './Buildings/Building35';
import Building36 from './Buildings/Building36';
import Building37 from './Buildings/Building37';
import Building38 from './Buildings/Building38';

const Sales = (props: any) => {
	const {DATA, click} = props;
	const checkSalesStatus = (name: string) => {
		const unit = _.find(DATA, {title: name});
		return unit ? unit.unitStatus : '';
	};

	return (
		<g id='UNITS-SALE'>
			<Building1 check={checkSalesStatus} click={click} />
			<Building2 check={checkSalesStatus} click={click} />
			<Building3 check={checkSalesStatus} click={click} />
			<Building4 check={checkSalesStatus} click={click} />
			<Building5 check={checkSalesStatus} click={click} />
			<Building6 check={checkSalesStatus} click={click} />
			<Building7 check={checkSalesStatus} click={click} />
			<Building8 check={checkSalesStatus} click={click} />
			<Building9 check={checkSalesStatus} click={click} />
			<Building10 check={checkSalesStatus} click={click} />

			<Building11 check={checkSalesStatus} click={click} />
			<Building12 check={checkSalesStatus} click={click} />
			<Building13 check={checkSalesStatus} click={click} />
			<Building14 check={checkSalesStatus} click={click} />
			<Building15 check={checkSalesStatus} click={click} />
			<Building16 check={checkSalesStatus} click={click} />
			<Building17 check={checkSalesStatus} click={click} />
			<Building18 check={checkSalesStatus} click={click} />
			<Building19 check={checkSalesStatus} click={click} />
			<Building20 check={checkSalesStatus} click={click} />

			<Building21 check={checkSalesStatus} click={click} />
			<Building22 check={checkSalesStatus} click={click} />
			<Building23 check={checkSalesStatus} click={click} />
			<Building24 check={checkSalesStatus} click={click} />
			<Building25 check={checkSalesStatus} click={click} />
			<Building26 check={checkSalesStatus} click={click} />
			<Building27 check={checkSalesStatus} click={click} />
			<Building28 check={checkSalesStatus} click={click} />
			<Building29 check={checkSalesStatus} click={click} />
			<Building30 check={checkSalesStatus} click={click} />

			<Building31 check={checkSalesStatus} click={click} />
			<Building32 check={checkSalesStatus} click={click} />
			<Building33 check={checkSalesStatus} click={click} />
			<Building34 check={checkSalesStatus} click={click} />
			<Building35 check={checkSalesStatus} click={click} />
			<Building36 check={checkSalesStatus} click={click} />
			<Building37 check={checkSalesStatus} click={click} />
			<Building38 check={checkSalesStatus} click={click} />
		</g>
	);
};

export default Sales;
