import React from 'react';
import SCSS from '../Sales.module.scss';

const Building27 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-27'>
			<g
				onClick={() => click('A2')}
				className={checkHandler('238')}
				id='A2-238'>
				<polygon
					className={SCSS.UnitBase}
					points='1232.67,1095.31 1204.77,1095.31 1204.77,1117.15 1202.8,1117.15 1202.8,1129.56 1204.77,1129.56 
			1204.77,1145.94 1206.67,1145.94 1206.67,1148.13 1221.21,1148.13 1221.21,1145.94 1232.67,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 1214.7642 1112.4325)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 1210.9814 1123.7614)'
					className={SCSS.UnitNumber}>
					238
				</text>
				<circle cx='1219.22' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('239')} id='A-239'>
				<polygon
					className={SCSS.UnitBase}
					points='1258.94,1095.31 1232.67,1095.31 1232.67,1145.94 1243.53,1145.94 1243.53,1148.13 
			1257.06,1148.13 1257.06,1145.94 1258.94,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 1243.3806 1112.4325)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1237.5701 1123.7614)'
					className={SCSS.UnitNumber}>
					239
				</text>
				<circle cx='1245.8' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('240')} id='A-240'>
				<polygon
					className={SCSS.UnitBase}
					points='1285.94,1095.31 1258.94,1095.31 1258.94,1145.94 1270.62,1145.94 1270.62,1148.13 
			1284.15,1148.13 1284.15,1145.94 1285.94,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 1270.0198 1112.4325)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1264.2092 1123.7614)'
					className={SCSS.UnitNumber}>
					240
				</text>
				<circle cx='1272.44' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('241')} id='A-241'>
				<polygon
					className={SCSS.UnitBase}
					points='1312.89,1095.31 1285.94,1095.31 1285.94,1145.94 1287.79,1145.94 1287.79,1148.13 
			1301.32,1148.13 1301.32,1145.94 1312.89,1145.94 			'
				/>
				<text
					transform='matrix(1 0 0 1 1296.9912 1112.4325)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1291.1807 1123.7614)'
					className={SCSS.UnitNumber}>
					241
				</text>
				<circle cx='1299.42' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A1')}
				className={checkHandler('242')}
				id='A1-242'>
				<polygon
					className={SCSS.UnitBase}
					points='1342.83,1116.15 1339.99,1116.15 1339.99,1095.31 1312.89,1095.31 1312.89,1145.94 1324.1,1145.94 
			1324.1,1148.13 1337.64,1148.13 1337.64,1145.94 1339.99,1145.94 1339.99,1129.56 1342.83,1129.56 			'
				/>
				<text
					transform='matrix(1 0 0 1 1321.9885 1112.4325)'
					className={SCSS.PlanName}>
					A1
				</text>
				<text
					transform='matrix(1 0 0 1 1318.2058 1123.7614)'
					className={SCSS.UnitNumber}>
					242
				</text>
				<circle cx='1326.44' cy='1131.91' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-27-OUTLINE'>
				<path
					d='M1339.99,1095.31v20.84h2.83v13.4h-2.83v16.38h-2.36v2.19h-13.53v-2.19h-22.78v2.19h-13.53v-2.19h-3.64v2.19h-13.53
			v-2.19h-13.56v2.19h-13.53v-2.19h-22.32v2.19h-14.53v-2.19h-1.91v-16.38h-1.97v-12.4h1.97v-21.84H1339.99 M1341.99,1093.31h-2
			h-135.23h-2v2v19.84h-1.97v2v12.4v2h1.97v14.38v2h1.91v0.19v2h2h14.53h2v-2v-0.19h18.32v0.19v2h2h13.53h2v-2v-0.19h9.56v0.19v2h2
			h13.53h2h1.64h13.53h2v-2v-0.19h18.78v0.19v2h2h13.53h2v-2v-0.19h0.36h2v-2v-14.38h0.83h2v-2v-13.4v-2h-2h-0.83v-18.84V1093.31
			L1341.99,1093.31z'
				/>
			</g>
			<g id='BUILDING-LABEL-27' className={SCSS.BuildingLabel}>
				<rect x='1245.1' y='1080.29' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1250.4465 1087.4181)'>BUILDING 27</text>
			</g>
		</g>
	);
};

export default Building27;
