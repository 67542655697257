import React from 'react';
import SCSS from '../Sales.module.scss';

const Building5 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-5'>
			<g
				onClick={() => click('B1')}
				className={checkHandler('119')}
				id='B1-119'>
				<rect
					x='1125.19'
					y='593.34'
					className={SCSS.UnitBase}
					width='26.28'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1134.161 619.2469)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 1130.0981 630.5758)'
					className={SCSS.UnitNumber}>
					119
				</text>
				<circle cx='1138.33' cy='638.73' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('120')} id='B-120'>
				<rect
					x='1151.47'
					y='593.34'
					className={SCSS.UnitBase}
					width='27'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1162.828 619.2469)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 1156.7372 630.5758)'
					className={SCSS.UnitNumber}>
					120
				</text>
				<circle cx='1164.97' cy='638.73' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('121')} id='B-121'>
				<rect
					x='1178.47'
					y='593.34'
					className={SCSS.UnitBase}
					width='26.94'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1189.7994 619.2469)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 1183.7086 630.5758)'
					className={SCSS.UnitNumber}>
					121
				</text>
				<circle cx='1191.94' cy='638.73' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('B1')}
				className={checkHandler('122')}
				id='B1-122'>
				<rect
					x='1205.42'
					y='593.34'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1214.7969 619.2469)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 1210.7339 630.5758)'
					className={SCSS.UnitNumber}>
					122
				</text>
				<circle cx='1218.97' cy='638.73' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-5-OUTLINE'>
				<path
					d='M1232.52,593.34v67.01h-107.33v-67.01H1232.52 M1234.52,591.34h-2h-107.33h-2v2v67.01v2h2h107.33h2v-2v-67.01V591.34
				L1234.52,591.34z'
				/>
			</g>
			<g id='BUILDING-LABEL-5' className={SCSS.BuildingLabel}>
				<rect x='1151.15' y='664.58' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1158.6523 671.7123)'>BUILDING 5</text>
			</g>
		</g>
	);
};

export default Building5;
