import React from 'react';
import SCSS from '../Sales.module.scss';

const Building9 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-9'>
			<g
				onClick={() => click('B1')}
				className={checkHandler('140')}
				id='B1-140'>
				<rect
					x='869.86'
					y='764.98'
					className={SCSS.UnitBase}
					width='26.9'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 879.1353 790.8925)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 875.0723 802.2214)'
					className={SCSS.UnitNumber}>
					140
				</text>
				<circle cx='883.31' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('141')} id='B-141'>
				<rect
					x='896.76'
					y='764.98'
					className={SCSS.UnitBase}
					width='26.28'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 907.7517 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 901.6609 802.2214)'
					className={SCSS.UnitNumber}>
					141
				</text>
				<circle cx='909.9' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('142')} id='B-142'>
				<rect
					x='923.03'
					y='764.98'
					className={SCSS.UnitBase}
					width='27'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 934.3908 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 928.3 802.2214)'
					className={SCSS.UnitNumber}>
					142
				</text>
				<circle cx='936.53' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('143')} id='B-143'>
				<rect
					x='950.03'
					y='764.98'
					className={SCSS.UnitBase}
					width='26.94'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 961.3622 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 955.2714 802.2214)'
					className={SCSS.UnitNumber}>
					143
				</text>
				<circle cx='963.51' cy='810.37' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('144')} id='B-144'>
				<rect
					x='976.98'
					y='764.98'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 988.3875 790.8925)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 982.2967 802.2214)'
					className={SCSS.UnitNumber}>
					144
				</text>
				<circle cx='990.53' cy='810.37' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('B1')}
				className={checkHandler('145')}
				id='B1-145'>
				<rect
					x='1004.09'
					y='764.98'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1013.4672 790.8925)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 1009.4042 802.2214)'
					className={SCSS.UnitNumber}>
					145
				</text>
				<circle cx='1017.64' cy='810.37' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-9-OUTLINE'>
				<path
					d='M1031.19,764.98v67.01H869.86v-67.01H1031.19 M1033.19,762.98h-2H869.86h-2v2v67.01v2h2h161.34h2v-2v-67.01V762.98
					L1033.19,762.98z'
				/>
			</g>
			<g id='BUILDING-LABEL-9' className={SCSS.BuildingLabel}>
				<rect x='909.26' y='749.85' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 916.7657 756.979)'>BUILDING 9</text>
			</g>
		</g>
	);
};

export default Building9;
