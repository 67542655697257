import React from 'react';
import SCSS from '../Sales.module.scss';

const Building29 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-29'>
			<g
				onClick={() => click('D2')}
				className={checkHandler('401')}
				id='D2-401'>
				<polygon
					className={SCSS.UnitBase}
					points='1512.62,1071.33 1509.18,1068.87 1500.27,1081.34 1514.1,1091.23 1512.42,1093.58 1529.3,1105.65 
			1530.98,1103.3 1552.43,1118.63 1561.63,1105.77 1557.42,1102.76 1560.31,1098.72 1515.79,1066.89 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1522.2938 1090.0532)'
					className={SCSS.PlanName}>
					D2
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1528.1525 1098.6147)'
					className={SCSS.UnitNumber}>
					401
				</text>
				<circle cx='1544.01' cy='1098.33' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('402')} id='E-402'>
				<polygon
					className={SCSS.UnitBase}
					points='1528.04,1049.76 1524.6,1047.3 1515.53,1059.99 1518.97,1062.45 1515.79,1066.89 1560.31,1098.72 
			1563.77,1093.89 1567.98,1096.89 1576.76,1084.6 1572.56,1081.59 1572.56,1081.59 1528.04,1049.76 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1535.8511 1071.0917)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1540.1281 1081.8706)'
					className={SCSS.UnitNumber}>
					402
				</text>
				<circle cx='1555.98' cy='1081.58' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('403')} id='D-403'>
				<polygon
					className={SCSS.UnitBase}
					points='1527.57,1043.15 1531.01,1045.61 1528.04,1049.76 1572.56,1081.59 1575.53,1077.44 
			1579.74,1080.44 1587.88,1069.05 1535.71,1031.76 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1547.1842 1055.2562)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1551.8656 1065.4683)'
					className={SCSS.UnitNumber}>
					403
				</text>
				<circle cx='1567.72' cy='1065.18' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('404')} id='E-404'>
				<polygon
					className={SCSS.UnitBase}
					points='1551.19,1017.38 1548.03,1021.8 1544.59,1019.34 1535.71,1031.76 1587.88,1069.05 1597.07,1056.2 
			1592.86,1053.19 1595.71,1049.21 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1559.3928 1038.2235)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1563.6698 1049.0024)'
					className={SCSS.UnitNumber}>
					404
				</text>
				<circle cx='1579.53' cy='1048.71' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('405')} id='D-405'>
				<polygon
					className={SCSS.UnitBase}
					points='1599.21,1044.31 1603.42,1047.32 1611.52,1035.99 1559.35,998.7 1550.94,1010.47 1554.38,1012.93 
			1551.19,1017.38 1595.71,1049.21 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1570.6747 1022.4105)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1575.3561 1032.6226)'
					className={SCSS.UnitNumber}>
					405
				</text>
				<circle cx='1591.21' cy='1032.33' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('E2')}
				className={checkHandler('406')}
				id='E2-406'>
				<polygon
					className={SCSS.UnitBase}
					points='1611.52,1035.99 1607.31,1032.98 1613.66,1024.11 1617.86,1027.11 1626.66,1014.81 1605.21,999.48 
			1606.91,997.11 1590.02,985.04 1588.33,987.41 1574.49,977.52 1565.72,989.79 1569.16,992.26 1562.79,1001.16 1559.35,998.7 
			1559.35,998.7 1611.52,1035.99 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1584.1749 1003.5497)'
					className={SCSS.PlanName}>
					E2
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1589.6298 1012.6773)'
					className={SCSS.UnitNumber}>
					406
				</text>
				<circle cx='1605.49' cy='1012.39' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-29-OUTLINE_1_'>
				<path
					d='M1574.49,977.52l13.83,9.89l1.7-2.37l16.88,12.07l-1.7,2.37l21.45,15.34l-8.79,12.3l-4.21-3.01l-6.35,8.88l4.21,3.01
			l-8.1,11.33l-4.21-3.01l-3.5,4.9l-2.85,3.98l4.21,3.01l-9.19,12.86l-8.14,11.39l-4.21-3.01l-2.97,4.16l4.21,3.01l-8.79,12.29
			l-4.21-3.01l-3.46,4.84l-2.89,4.04l4.21,3.01l-9.19,12.86l-21.45-15.34l-1.68,2.35l-16.88-12.07l1.68-2.35l-13.83-9.89
			l8.91-12.46l3.44,2.46l3.17-4.44l3.17-4.44l-3.44-2.46l9.07-12.69l3.44,2.46l2.97-4.16l-3.44-2.46l8.14-11.39l8.88-12.41
			l3.44,2.46l3.16-4.42l3.19-4.46l-3.44-2.46l8.42-11.77l3.44,2.46l6.36-8.9l-3.44-2.46L1574.49,977.52 M1574.03,974.73l-1.16,1.63
			l-8.78,12.28l-1.16,1.63l1.63,1.16l1.81,1.3l-4.04,5.65l-1.81-1.3l-1.63-1.16l-1.16,1.63l-8.42,11.77l-1.16,1.63l1.63,1.16
			l1.81,1.3l-2.02,2.83l-2,2.8l-1.81-1.3l-1.63-1.16l-1.16,1.63l-8.88,12.41l-8.14,11.39l-1.16,1.63l1.63,1.16l1.81,1.3l-0.65,0.91
			l-1.81-1.3l-1.63-1.16l-1.16,1.63l-9.07,12.69l-1.16,1.63l1.63,1.16l1.81,1.3l-2.01,2.81l-2.01,2.81l-1.81-1.3l-1.63-1.16
			l-1.16,1.63l-8.91,12.46l-1.16,1.63l1.63,1.16l12.21,8.73l-0.52,0.73l-1.16,1.63l1.63,1.16l16.88,12.07l1.63,1.16l1.16-1.63
			l0.52-0.73l19.82,14.17l1.63,1.16l1.16-1.63l9.19-12.86l1.16-1.63l-1.63-1.16l-2.58-1.84l1.73-2.42l2.29-3.21l2.58,1.84
			l1.63,1.16l1.16-1.63l8.79-12.29l1.16-1.63l-1.63-1.16l-2.58-1.84l0.65-0.9l2.58,1.84l1.63,1.16l1.16-1.63l8.14-11.39l9.19-12.86
			l1.16-1.63l-1.63-1.16l-2.58-1.84l1.68-2.35l2.34-3.27l2.58,1.84l1.63,1.16l1.16-1.63l8.1-11.33l1.16-1.63l-1.63-1.16l-2.58-1.84
			l4.02-5.62l2.58,1.84l1.63,1.16l1.16-1.63l8.79-12.3l1.16-1.63l-1.63-1.16L1608,999.01l0.53-0.74l1.16-1.63l-1.63-1.16
			l-16.88-12.07l-1.63-1.16l-1.16,1.63l-0.53,0.74l-12.21-8.73L1574.03,974.73L1574.03,974.73z'
				/>
			</g>
			<g id='BUILDING-LABEL-29' className={SCSS.BuildingLabel}>
				<rect
					x='1504.62'
					y='1011.09'
					transform='matrix(0.5816 -0.8135 0.8135 0.5816 -185.2885 1671.5641)'
					width='55.42'
					height='9.64'
				/>
				<text transform='matrix(0.5816 -0.8135 0.8135 0.5816 1521.157 1035.4698)'>
					BUILDING 29
				</text>
			</g>
		</g>
	);
};

export default Building29;
