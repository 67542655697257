import React from 'react';
import SCSS from '../Sales.module.scss';

const Building30 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-30_1_'>
			<g
				onClick={() => click('D1')}
				className={checkHandler('407')}
				id='D1-407'>
				<polygon
					className={SCSS.UnitBase}
					points='1595.7,955.13 1592.23,952.64 1583.44,964.93 1596.72,974.43 1595.04,976.78 1611.73,988.71 
				1613.41,986.36 1635.6,1002.23 1644.87,989.27 1640.7,986.29 1643.4,982.51 1598.88,950.69 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1605.0551 974.4126)'
					className={SCSS.PlanName}>
					D1
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1610.9137 982.9742)'
					className={SCSS.UnitNumber}>
					407
				</text>
				<circle cx='1626.77' cy='982.69' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('408')} id='E-408'>
				<polygon
					className={SCSS.UnitBase}
					points='1598.58,943.76 1602.05,946.25 1598.88,950.69 1643.4,982.51 1647.05,977.41 1651.22,980.39 
				1658.97,969.55 1606.8,932.26 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1618.7471 955.2509)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1623.024 966.0298)'
					className={SCSS.UnitNumber}>
					408
				</text>
				<circle cx='1638.88' cy='965.74' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('409')} id='D-409'>
				<polygon
					className={SCSS.UnitBase}
					points='1618.71,922.94 1615.24,920.46 1606.8,932.26 1658.97,969.55 1667.73,957.29 1663.56,954.31 
				1666.41,950.33 1621.89,918.5 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1629.8639 939.641)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1634.5453 949.8531)'
					className={SCSS.UnitNumber}>
					409
				</text>
				<circle cx='1650.4' cy='949.57' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('410')} id='E-410'>
				<polygon
					className={SCSS.UnitBase}
					points='1621.59,911.58 1625.06,914.06 1621.89,918.5 1666.41,950.33 1669.91,945.43 1674.08,948.42 
				1682.38,936.81 1630.22,899.51 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1642.1289 922.5351)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1646.4059 933.314)'
					className={SCSS.UnitNumber}>
					410
				</text>
				<circle cx='1662.26' cy='933.03' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('411')} id='D-411'>
				<polygon
					className={SCSS.UnitBase}
					points='1642.29,889.96 1638.82,887.48 1630.22,899.51 1682.38,936.81 1691.26,924.38 1687.09,921.4 
				1689.98,917.35 1645.47,885.53 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1653.3956 906.7133)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1658.077 916.9254)'
					className={SCSS.UnitNumber}>
					411
				</text>
				<circle cx='1673.93' cy='916.64' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('412')} id='E-412'>
				<polygon
					className={SCSS.UnitBase}
					points='1645.16,878.6 1648.64,881.09 1645.47,885.53 1689.98,917.35 1693.44,912.52 1697.61,915.5 
				1705.78,904.07 1653.62,866.78 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1665.6304 889.6728)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1669.9073 900.4517)'
					className={SCSS.UnitNumber}>
					412
				</text>
				<circle cx='1685.76' cy='900.16' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('413')} id='D-413'>
				<polygon
					className={SCSS.UnitBase}
					points='1668.92,852.73 1665.65,857.29 1662.18,854.81 1653.62,866.78 1705.78,904.07 1714.68,891.63 
				1710.51,888.65 1713.43,884.55 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1676.8156 874.0004)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1681.4969 884.2125)'
					className={SCSS.UnitNumber}>
					413
				</text>
				<circle cx='1697.35' cy='883.92' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('414')} id='E-414'>
				<polygon
					className={SCSS.UnitBase}
					points='1716.86,879.77 1721.03,882.75 1729.56,870.81 1677.4,833.52 1668.52,845.93 1672,848.41 
				1668.92,852.73 1713.43,884.55 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1689.4158 856.3974)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1693.6927 867.1763)'
					className={SCSS.UnitNumber}>
					414
				</text>
				<circle cx='1709.55' cy='866.89' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('D2')}
				className={checkHandler('415')}
				id='D2-415'>
				<polygon
					className={SCSS.UnitBase}
					points='1729.56,870.81 1725.38,867.82 1728.83,863 1733.01,865.99 1741.4,854.25 1719.21,838.38 
				1720.92,835.99 1704.23,824.06 1702.52,826.45 1689.24,816.96 1677.4,833.52 1729.56,870.81 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1699.8263 841.7788)'
					className={SCSS.PlanName}>
					D2
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1705.6849 850.3404)'
					className={SCSS.UnitNumber}>
					415
				</text>
				<circle cx='1721.54' cy='850.05' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-30-OUTLINE_1_'>
				<path
					d='M1689.24,816.96l13.28,9.49l1.71-2.39l16.69,11.93l-1.71,2.39l22.2,15.87l-8.39,11.73l-4.18-2.99l-3.45,4.83l4.18,2.99
				l-8.53,11.94l-4.17-2.98l-3.42,4.79l-2.93,4.09l4.17,2.98l-8.9,12.45l-8.17,11.43l-4.17-2.98l-3.46,4.84l-2.89,4.04l4.17,2.98
				l-8.88,12.43l-8.3,11.61l-4.17-2.98l-3.5,4.9l-2.85,3.98l4.17,2.98l-8.76,12.26l-7.75,10.84l-4.17-2.98l-3.65,5.11l-2.7,3.77
				l4.17,2.98l-9.27,12.96l-22.2-15.87l-1.68,2.35l-16.69-11.93l1.68-2.35l-13.28-9.49l8.79-12.29l3.48,2.48l3.17-4.44l3.17-4.44
				l-3.48-2.48l8.23-11.5l8.44-11.8l3.48,2.48l3.17-4.44l3.17-4.44l-3.48-2.48l8.63-12.07l8.6-12.03l3.48,2.48l3.17-4.44l3.17-4.44
				l-3.48-2.48l8.45-11.82l8.56-11.97l3.48,2.48l3.27-4.57l3.08-4.31l-3.48-2.48l8.87-12.41L1689.24,816.96 M1688.77,814.17
				l-1.16,1.63l-11.84,16.56l-8.87,12.41l-1.16,1.63l1.63,1.16l1.85,1.32l-1.92,2.68l-2.1,2.94l-1.85-1.32l-1.63-1.16l-1.16,1.63
				l-8.56,11.97l-8.45,11.82l-1.16,1.63l1.63,1.16l1.85,1.32l-2.01,2.81l-2.01,2.81l-1.85-1.32l-1.63-1.16l-1.16,1.63l-8.6,12.03
				l-8.63,12.07l-1.16,1.63l1.63,1.16l1.85,1.32l-2.01,2.81l-2.01,2.81l-1.85-1.32l-1.63-1.16l-1.16,1.63l-8.44,11.8l-8.23,11.5
				l-1.16,1.63l1.63,1.16l1.85,1.32l-2.01,2.81l-2.01,2.81l-1.85-1.32l-1.63-1.16l-1.16,1.63l-8.79,12.29l-1.16,1.63l1.63,1.16
				l11.65,8.33l-0.52,0.73l-1.16,1.63l1.63,1.16l16.69,11.93l1.63,1.16l1.16-1.63l0.52-0.73l20.57,14.71l1.63,1.16l1.16-1.63
				l9.27-12.96l1.16-1.63l-1.63-1.16l-2.54-1.82l1.53-2.15l2.49-3.48l2.54,1.82l1.63,1.16l1.16-1.63l7.75-10.84l8.76-12.26
				l1.16-1.63l-1.63-1.16l-2.54-1.82l1.68-2.35l2.34-3.27l2.54,1.82l1.63,1.16l1.16-1.63l8.3-11.61l8.88-12.43l1.16-1.63l-1.63-1.16
				l-2.54-1.82l1.73-2.42l2.29-3.21l2.54,1.82l1.63,1.16l1.16-1.63l8.17-11.43l8.9-12.45l1.16-1.63l-1.63-1.16l-2.54-1.82l1.76-2.46
				l2.26-3.16l2.54,1.82l1.63,1.16l1.16-1.63l8.53-11.94l1.16-1.63l-1.63-1.16l-2.56-1.83l1.12-1.57l2.56,1.83l1.63,1.16l1.16-1.63
				l8.39-11.73l1.16-1.63l-1.63-1.16L1722,837.92l0.55-0.77l1.16-1.63l-1.63-1.16l-16.69-11.93l-1.63-1.16l-1.16,1.63l-0.55,0.77
				l-11.65-8.33L1688.77,814.17L1688.77,814.17z'
				/>
			</g>
			<g id='BUILDING-LABEL-30' className={SCSS.BuildingLabel}>
				<rect
					x='1595'
					y='886.38'
					transform='matrix(0.5816 -0.8135 0.8135 0.5816 -46.0276 1692.9122)'
					width='55.42'
					height='9.64'
				/>
				<text transform='matrix(0.5816 -0.8135 0.8135 0.5816 1611.5405 910.7648)'>
					BUILDING 30
				</text>
			</g>
		</g>
	);
};

export default Building30;
