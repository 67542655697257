import React from 'react';
import SCSS from '../Sales.module.scss';

const Building6 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-6'>
			<g
				onClick={() => click('B1')}
				className={checkHandler('123')}
				id='B1-123'>
				<rect
					x='1252.4'
					y='593.34'
					className={SCSS.UnitBase}
					width='26.9'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1261.6764 619.2469)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 1257.6134 630.5758)'
					className={SCSS.UnitNumber}>
					123
				</text>
				<circle cx='1265.85' cy='638.73' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('124')} id='B-124'>
				<rect
					x='1279.3'
					y='593.34'
					className={SCSS.UnitBase}
					width='26.28'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1290.2927 619.2469)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 1284.202 630.5758)'
					className={SCSS.UnitNumber}>
					124
				</text>
				<circle cx='1292.44' cy='638.73' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('125')} id='B-125'>
				<rect
					x='1305.58'
					y='593.34'
					className={SCSS.UnitBase}
					width='27'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1316.9319 619.2469)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 1310.8411 630.5758)'
					className={SCSS.UnitNumber}>
					125
				</text>
				<circle cx='1319.08' cy='638.73' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('126')} id='B-126'>
				<rect
					x='1332.58'
					y='593.34'
					className={SCSS.UnitBase}
					width='26.94'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1343.9033 619.2469)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 1337.8125 630.5758)'
					className={SCSS.UnitNumber}>
					126
				</text>
				<circle cx='1346.05' cy='638.73' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('B1')}
				className={checkHandler('127')}
				id='B1-127'>
				<rect
					x='1359.52'
					y='593.34'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 1368.9008 619.2469)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 1364.8378 630.5758)'
					className={SCSS.UnitNumber}>
					127
				</text>
				<circle cx='1373.07' cy='638.73' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-6-OUTLINE'>
				<path
					d='M1386.63,593.34v67.01H1252.4v-67.01H1386.63 M1388.63,591.34h-2H1252.4h-2v2v67.01v2h2h134.23h2v-2v-67.01V591.34
				L1388.63,591.34z'
				/>
			</g>
			<g id='BUILDING-LABEL-6' className={SCSS.BuildingLabel}>
				<rect x='1291.8' y='664.58' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 1299.3069 671.7123)'>BUILDING 6</text>
			</g>
		</g>
	);
};

export default Building6;
