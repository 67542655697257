import React from 'react';
import SCSS from '../Sales.module.scss';

const Building1 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-1'>
			<g
				onClick={() => click('B1')}
				className={checkHandler('101')}
				id='B1-101'>
				<rect
					x='565.81'
					y='593.34'
					className={SCSS.UnitBase}
					width='26.9'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 575.0878 619.2469)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 571.0248 630.5758)'
					className={SCSS.UnitNumber}>
					101
				</text>
				<circle cx='579.26' cy='638.73' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('102')} id='B-102'>
				<rect
					x='592.71'
					y='593.34'
					className={SCSS.UnitBase}
					width='26.28'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 603.7042 619.2469)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 597.6134 630.5758)'
					className={SCSS.UnitNumber}>
					102
				</text>
				<circle cx='605.85' cy='638.73' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('103')} id='B-103'>
				<rect
					x='618.99'
					y='593.34'
					className={SCSS.UnitBase}
					width='27'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 630.3434 619.2469)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 624.2526 630.5758)'
					className={SCSS.UnitNumber}>
					103
				</text>
				<circle cx='632.49' cy='638.73' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('B')} className={checkHandler('104')} id='B-104'>
				<rect
					x='645.99'
					y='593.34'
					className={SCSS.UnitBase}
					width='26.94'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 657.3148 619.2469)'
					className={SCSS.PlanName}>
					B
				</text>
				<text
					transform='matrix(1 0 0 1 651.224 630.5758)'
					className={SCSS.UnitNumber}>
					104
				</text>
				<circle cx='659.46' cy='638.73' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('B1')}
				className={checkHandler('105')}
				id='B1-105'>
				<rect
					x='672.93'
					y='593.34'
					className={SCSS.UnitBase}
					width='27.11'
					height='67.01'
				/>
				<text
					transform='matrix(1 0 0 1 682.3122 619.2469)'
					className={SCSS.PlanName}>
					B1
				</text>
				<text
					transform='matrix(1 0 0 1 678.2492 630.5758)'
					className={SCSS.UnitNumber}>
					105
				</text>
				<circle cx='686.48' cy='638.73' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-1-OUTLINE'>
				<path
					d='M700.04,593.34v67.01H565.81l0.73-67.01H700.04 M702.04,591.34h-2h-133.5h-1.98l-0.02,1.98l-0.73,67.01l-0.02,2.02h2.02
				h134.23h2v-2v-67.01V591.34L702.04,591.34z'
				/>
			</g>
			<g id='BUILDING-LABEL-1' className={SCSS.BuildingLabel}>
				<rect x='605.21' y='664.58' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 612.7183 671.7123)'>BUILDING 1</text>
			</g>
		</g>
	);
};

export default Building1;
