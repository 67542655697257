import React from 'react';
import SCSS from '../Sales.module.scss';

const Building28 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-28'>
			<g
				onClick={() => click('A1')}
				className={checkHandler('245')}
				id='A1-245'>
				<polygon
					className={SCSS.UnitBase}
					points='1399.01,1108.92 1399.01,1136.82 1420.85,1136.82 1420.85,1138.79 1434.26,1138.79 
				1434.26,1136.82 1450.64,1136.82 1450.64,1134.91 1452.83,1134.91 1452.83,1120.38 1450.64,1120.38 1450.64,1108.92 			'
				/>
				<text
					transform='matrix(1 0 0 1 1411.8523 1119.8289)'
					className={SCSS.PlanName}>
					A1
				</text>
				<text
					transform='matrix(1 0 0 1 1408.0696 1131.1578)'
					className={SCSS.UnitNumber}>
					245
				</text>
				<circle cx='1437.41' cy='1123.92' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('244')} id='A-244'>
				<polygon
					className={SCSS.UnitBase}
					points='1399.01,1082.64 1399.01,1108.92 1450.64,1108.92 1450.64,1098.06 1452.83,1098.06 
				1452.83,1084.53 1450.64,1084.53 1450.64,1082.64 			'
				/>
				<text
					transform='matrix(1 0 0 1 1413.8804 1093.4102)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 1408.0698 1104.7391)'
					className={SCSS.UnitNumber}>
					244
				</text>
				<circle cx='1437.41' cy='1097.1' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A1')}
				className={checkHandler('243')}
				id='A1-243_1_'>
				<polygon
					className={SCSS.UnitBase}
					points='1419.85,1052.71 1419.85,1055.54 1399.01,1055.54 1399.01,1082.64 1450.64,1082.64 
				1450.64,1071.43 1452.83,1071.43 1452.83,1057.89 1450.64,1057.89 1450.64,1055.54 1434.26,1055.54 1434.26,1052.71 			'
				/>
				<text
					transform='matrix(1 0 0 1 1411.8528 1066.4712)'
					className={SCSS.PlanName}>
					A1
				</text>
				<text
					transform='matrix(1 0 0 1 1408.0701 1077.8)'
					className={SCSS.UnitNumber}>
					243
				</text>
				<circle cx='1437.41' cy='1070.14' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-28-OUTLINE'>
				<path
					d='M1434.26,1052.71v2.83h16.38v2.36h2.19v13.53h-2.19v13.12h2.19v13.53h-2.19v22.32h2.19v14.53h-2.19v1.91h-16.38v1.97
				h-13.4v-1.97h-21.84v-81.3h20.84v-2.83H1434.26 M1436.26,1050.71h-2h-14.4h-2v2v0.83h-18.84h-2v2v81.3v2h2h19.84v1.97h2h13.4h2
				v-1.97h14.38h2v-1.91h0.19h2v-2v-14.53v-2h-2h-0.19v-18.32h0.19h2v-2v-13.53v-2h-2h-0.19v-9.12h0.19h2v-2v-13.53v-2h-2h-0.19
				v-0.36v-2h-2h-14.38v-0.83V1050.71L1436.26,1050.71z'
				/>
			</g>
			<g id='BUILDING-LABEL-28' className={SCSS.BuildingLabel}>
				<rect
					x='1361.82'
					y='1092.3'
					transform='matrix(6.123234e-17 -1 1 6.123234e-17 292.4065 2486.6499)'
					width='55.42'
					height='9.64'
				/>
				<text transform='matrix(0 -1 1 0 1391.8392 1119.4897)'>
					BUILDING 28
				</text>
			</g>
		</g>
	);
};

export default Building28;
