import React from 'react';
import KeySalesHasButton from './KeySalesHasButton';
import AvailabilityBox from "./AvailabilityBox";

const index = () => {
	return (
		<g id='TOOLTIPS'>
			{/*<KeyRentalHasButton />*/}
			{/*<KeySalesHasButton />*/}
			<AvailabilityBox />
		</g>
	);
};

export default index;
