import React from 'react';
import SCSS from '../Sales.module.scss';

const Building36 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-36_1_'>
			<g
				onClick={() => click('D2')}
				className={checkHandler('466')}
				id='D2-466'>
				<polygon
					className={SCSS.UnitBase}
					points='1595.84,716.72 1600.4,719.99 1597.3,724.32 1592.74,721.06 1583.7,733.7 1605.32,749.15 
				1603.85,751.22 1620.54,763.15 1622.01,761.09 1635.88,771 1644.61,758.78 1641.48,756.54 1644.88,751.79 1595.84,716.72 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1608.1622 743.8911)'
					className={SCSS.PlanName}>
					D2
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1614.0209 752.4527)'
					className={SCSS.UnitNumber}>
					466
				</text>
				<circle cx='1629.88' cy='752.17' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('467')} id='E-467'>
				<polygon
					className={SCSS.UnitBase}
					points='1609.45,707.33 1604.89,704.07 1595.84,716.72 1644.88,751.79 1645.87,750.4 1649.01,752.64 
				1656.82,741.71 1653.71,739.5 1656.79,735.19 1612.28,703.37 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1621.8196 724.7275)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1626.0966 735.5064)'
					className={SCSS.UnitNumber}>
					467
				</text>
				<circle cx='1641.95' cy='735.22' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('468')} id='D-468'>
				<polygon
					className={SCSS.UnitBase}
					points='1610.38,696.38 1614.95,699.64 1612.28,703.37 1656.79,735.19 1660.48,730.04 1663.58,732.25 
				1672.01,720.46 1619.84,683.16 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1633.4471 708.5297)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1638.1285 718.7418)'
					className={SCSS.UnitNumber}>
					468
				</text>
				<circle cx='1653.98' cy='718.45' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('469')} id='E-469'>
				<polygon
					className={SCSS.UnitBase}
					points='1632.86,674.59 1628.3,671.32 1619.84,683.16 1672.01,720.46 1680.08,709.18 1676.98,706.96 
				1680.01,702.71 1635.5,670.89 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1645.3342 691.7949)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1649.6112 702.5738)'
					className={SCSS.UnitNumber}>
					469
				</text>
				<circle cx='1665.47' cy='702.28' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('470')} id='D-470'>
				<polygon
					className={SCSS.UnitBase}
					points='1633.58,663.93 1638.14,667.19 1635.5,670.89 1680.01,702.71 1684.04,697.09 1687.14,699.3 
				1694.61,688.84 1642.44,651.54 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1656.1473 676.6927)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1660.8287 686.9048)'
					className={SCSS.UnitNumber}>
					470
				</text>
				<circle cx='1676.68' cy='686.62' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('471')} id='E-471'>
				<polygon
					className={SCSS.UnitBase}
					points='1656.58,641.4 1652.02,638.14 1642.44,651.54 1694.61,688.84 1703.42,676.52 1700.32,674.31 
				1703.85,669.37 1659.34,637.54 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1668.502 659.5136)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1672.7789 670.2925)'
					className={SCSS.UnitNumber}>
					471
				</text>
				<circle cx='1688.63' cy='670.01' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('472')} id='D-472'>
				<polygon
					className={SCSS.UnitBase}
					points='1657.31,630.75 1661.87,634.01 1659.34,637.54 1703.85,669.37 1707.38,664.43 1710.48,666.65 
				1718.05,656.06 1665.88,618.76 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1679.984 643.4213)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1684.6654 653.6334)'
					className={SCSS.UnitNumber}>
					472
				</text>
				<circle cx='1700.52' cy='653.34' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('E')} className={checkHandler('473')} id='E-473'>
				<polygon
					className={SCSS.UnitBase}
					points='1682.33,605.38 1679.88,608.82 1675.32,605.56 1665.88,618.76 1718.05,656.06 1727,643.55 
				1723.9,641.33 1726.85,637.21 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1691.8032 626.9365)'
					className={SCSS.PlanName}>
					E
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1696.0802 637.7154)'
					className={SCSS.UnitNumber}>
					473
				</text>
				<circle cx='1711.94' cy='637.43' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('D')} className={checkHandler('474')} id='D-474'>
				<polygon
					className={SCSS.UnitBase}
					points='1730.96,631.46 1734.06,633.67 1741.9,622.71 1689.73,585.4 1680.83,597.84 1685.4,601.1 
				1682.33,605.38 1726.85,637.21 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1702.2535 612.3101)'
					className={SCSS.PlanName}>
					D
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1708.1122 620.8716)'
					className={SCSS.UnitNumber}>
					474
				</text>
				<circle cx='1723.97' cy='620.58' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('E2')}
				className={checkHandler('475')}
				id='E2-475'>
				<polygon
					className={SCSS.UnitBase}
					points='1741.9,622.71 1738.8,620.49 1745.85,610.63 1748.95,612.85 1756.93,601.69 1743.06,591.78 
				1744.77,589.39 1728.08,577.46 1726.37,579.84 1704.75,564.39 1694.64,578.54 1699.2,581.8 1694.29,588.67 1689.73,585.4 
				1689.73,585.4 1741.9,622.71 			'
				/>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1716.6561 592.1307)'
					className={SCSS.PlanName}>
					E2
				</text>
				<text
					transform='matrix(0.5807 -0.8141 0.8141 0.5807 1722.111 601.2584)'
					className={SCSS.UnitNumber}>
					475
				</text>
				<circle cx='1737.97' cy='600.97' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-36-OUTLINE_1_'>
				<path
					d='M1704.75,564.39l21.62,15.46l1.7-2.38l16.69,11.93l-1.7,2.38l13.86,9.91l-7.98,11.16l-3.1-2.22l-7.05,9.86l3.1,2.22
				l-7.84,10.97l-3.1-2.22l-4.11,5.75l-2.95,4.12l3.1,2.22l-8.95,12.51l-7.57,10.59l-3.1-2.22l-3.53,4.94l-3.53,4.94l3.1,2.22
				l-8.81,12.32l-7.48,10.46l-3.1-2.22l-4.02,5.63l-3.04,4.25l3.1,2.22l-8.07,11.28l-8.43,11.79l-3.1-2.22l-3.68,5.15l-3.08,4.31
				l3.1,2.22l-7.81,10.92l-3.13-2.24l-0.99,1.39l-3.4,4.76l3.13,2.24l-8.73,12.21l-13.86-9.91l-1.47,2.06l-16.69-11.93l1.47-2.06
				l-21.62-15.46l9.03-12.64l4.56,3.26l3.1-4.34l-4.56-3.26l9.05-12.66l4.56,3.26l2.83-3.96l2.66-3.73l-4.56-3.26l9.45-13.22
				l8.46-11.83l4.56,3.26l2.64-3.7l2.64-3.7l-4.56-3.26l8.86-12.39l9.58-13.4l4.56,3.26l2.76-3.86l2.53-3.53l-4.56-3.26l8.57-11.99
				l9.44-13.2l4.56,3.26l2.45-3.43l3.06-4.28l-4.56-3.26l8.89-12.44l4.56,3.26l4.91-6.87l-4.56-3.26L1704.75,564.39 M1704.29,561.6
				l-1.16,1.63l-10.12,14.15l-1.16,1.63l1.63,1.16l2.94,2.1l-2.58,3.61l-2.94-2.1l-1.63-1.16l-1.16,1.63l-8.89,12.44l-1.16,1.63
				l1.63,1.16l2.94,2.1l-1.9,2.65l-1.29,1.81l-2.94-2.1l-1.63-1.16l-1.16,1.63l-9.44,13.2l-8.57,11.99l-1.16,1.63l1.63,1.16
				l2.94,2.1l-1.36,1.91l-1.59,2.23l-2.94-2.1l-1.63-1.16l-1.16,1.63l-9.58,13.4l-8.86,12.39l-1.16,1.63l1.63,1.16l2.94,2.1
				l-1.48,2.07l-1.48,2.07l-2.93-2.1l-1.63-1.16l-1.16,1.63l-8.46,11.83l-9.45,13.22l-1.16,1.63l1.63,1.16l2.93,2.1l-1.5,2.1
				l-1.67,2.33l-2.93-2.1l-1.63-1.16l-1.16,1.63l-9.05,12.66l-1.16,1.63l1.63,1.16l2.93,2.1l-0.77,1.08l-2.93-2.1l-1.63-1.16
				l-1.16,1.63l-9.03,12.64l-1.16,1.63l1.63,1.16l19.99,14.29l-0.31,0.44l-1.16,1.63l1.63,1.16l16.69,11.93l1.63,1.16l1.16-1.63
				l0.31-0.44l12.24,8.75l1.63,1.16l1.16-1.63l8.73-12.21l1.16-1.63l-1.63-1.16l-1.51-1.08l2.07-2.89l1.51,1.08l1.63,1.16l1.16-1.63
				l7.81-10.92l1.16-1.63l-1.63-1.16l-1.47-1.05l1.92-2.68l2.52-3.53l1.47,1.05l1.63,1.16l1.16-1.63l8.43-11.79l8.07-11.28
				l1.16-1.63l-1.63-1.16l-1.47-1.05l1.87-2.62l2.86-4l1.47,1.05l1.63,1.16l1.16-1.63l7.48-10.46l8.81-12.32l1.16-1.63l-1.63-1.16
				l-1.47-1.05l2.37-3.31l2.37-3.31l1.47,1.05l1.63,1.16l1.16-1.63l7.57-10.59l8.95-12.51l1.16-1.63l-1.63-1.16l-1.47-1.05l1.78-2.5
				l2.95-4.13l1.47,1.05l1.63,1.16l1.16-1.63l7.84-10.97l1.16-1.63l-1.63-1.16l-1.47-1.05l4.72-6.61l1.47,1.05l1.63,1.16l1.16-1.63
				l7.98-11.16l1.16-1.63l-1.63-1.16l-12.24-8.75l0.54-0.76l1.16-1.63l-1.63-1.16l-16.69-11.93l-1.63-1.16l-1.16,1.63l-0.54,0.76
				l-19.99-14.29L1704.29,561.6L1704.29,561.6z'
				/>
			</g>
			<g id='BUILDING-LABEL-36' className={SCSS.BuildingLabel}>
				<rect
					x='1676.62'
					y='688.55'
					transform='matrix(0.5816 -0.8135 0.8135 0.5816 149.0476 1676.5356)'
					width='55.42'
					height='9.64'
				/>
				<text transform='matrix(0.5816 -0.8135 0.8135 0.5816 1693.158 712.9386)'>
					BUILDING 36
				</text>
			</g>
		</g>
	);
};

export default Building36;
