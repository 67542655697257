import React from 'react';
import SCSS from '../Sales.module.scss';

const Building12 = (props: any) => {
	const {check, click} = props;

	const checkHandler = (name: string) => {
		const status = check(name);
		let classes: any;
		switch (status) {
			case 'available':
				classes = SCSS.Availabe;
				break;
			case 'hold':
				classes = SCSS.Hold;
				break;
			case 'sold':
				classes = SCSS.Sold;
				break;
			case 'rented':
				classes = SCSS.Rented;
				break;
			case 'unavailable':
				classes = SCSS.Unavailable;
				break;
			default:
				classes = SCSS.Unavailabe;
				break;
		}
		return classes;
	};

	return (
		<g id='BUILDING-12'>
			<g
				onClick={() => click('A1')}
				className={checkHandler('157')}
				id='A1-157'>
				<polygon
					className={SCSS.UnitBase}
					points='492.57,891.48 464.67,891.48 464.67,914.32 462.71,914.32 462.71,926.73 464.67,926.73 
				464.67,944.11 466.58,944.11 466.58,947.3 480.11,947.3 480.11,944.11 492.57,944.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 474.6714 909.6046)'
					className={SCSS.PlanName}>
					A1
				</text>
				<text
					transform='matrix(1 0 0 1 470.8888 920.9335)'
					className={SCSS.UnitNumber}>
					157
				</text>
				<circle cx='479.12' cy='929.09' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('158')} id='A-158'>
				<polygon
					className={SCSS.UnitBase}
					points='518.85,891.48 492.57,891.48 492.57,944.11 504.44,944.11 504.44,947.3 516.97,947.3 
				516.97,944.11 518.85,944.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 503.2879 909.6046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 497.4773 920.9335)'
					className={SCSS.UnitNumber}>
					158
				</text>
				<circle cx='505.71' cy='929.09' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('159')} id='A-159'>
				<polygon
					className={SCSS.UnitBase}
					points='545.85,891.48 518.85,891.48 518.85,944.11 531.53,944.11 531.53,947.3 544.06,947.3 
				544.06,944.11 545.85,944.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 529.927 909.6046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 524.1165 920.9335)'
					className={SCSS.UnitNumber}>
					159
				</text>
				<circle cx='532.35' cy='929.09' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('160')} id='A-160'>
				<polygon
					className={SCSS.UnitBase}
					points='572.79,891.48 545.85,891.48 545.85,944.11 547.7,944.11 547.7,947.3 560.23,947.3 560.23,944.11 
				572.79,944.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 556.8984 909.6046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 551.0879 920.9335)'
					className={SCSS.UnitNumber}>
					160
				</text>
				<circle cx='559.32' cy='929.09' r='3.45' className="dot" />
			</g>
			<g onClick={() => click('A')} className={checkHandler('161')} id='A-161'>
				<polygon
					className={SCSS.UnitBase}
					points='599.9,891.48 572.79,891.48 572.79,944.11 574.61,944.11 574.61,947.3 587.14,947.3 587.14,944.11 
				599.9,944.11 			'
				/>
				<text
					transform='matrix(1 0 0 1 583.9237 909.6046)'
					className={SCSS.PlanName}>
					A
				</text>
				<text
					transform='matrix(1 0 0 1 578.1132 920.9335)'
					className={SCSS.UnitNumber}>
					161
				</text>
				<circle cx='586.35' cy='929.09' r='3.45' className="dot" />
			</g>
			<g
				onClick={() => click('A2')}
				className={checkHandler('162')}
				id='A2-162'>
				<polygon
					className={SCSS.UnitBase}
					points='628.84,914.32 627.01,914.32 627.01,891.48 599.9,891.48 599.9,944.11 612.12,944.11 612.12,947.3 
				624.65,947.3 624.65,944.11 627.01,944.11 627.01,926.73 628.84,926.73 			'
				/>
				<text
					transform='matrix(1 0 0 1 609.0034 909.6046)'
					className={SCSS.PlanName}>
					A2
				</text>
				<text
					transform='matrix(1 0 0 1 605.2207 920.9335)'
					className={SCSS.UnitNumber}>
					162
				</text>
				<circle cx='613.46' cy='929.09' r='3.45' className="dot" />
			</g>
			<g id='BUILDING-12-OUTLINE'>
				<path
					d='M627.01,891.48v22.84h1.83v12.4h-1.83v17.38h-2.36v3.19h-12.53v-3.19h-24.98v3.19h-12.53v-3.19h-14.38v3.19H547.7v-3.19
				h-3.64v3.19h-12.53v-3.19h-14.56v3.19h-12.53v-3.19h-24.32v3.19h-13.53v-3.19h-1.91v-17.38h-1.97v-12.4h1.97v-22.84H627.01
				 M629.01,889.48h-2H464.67h-2v2v20.84h-1.97v2v12.4v2h1.97v15.38v2h1.91v1.19v2h2h13.53h2v-2v-1.19h20.32v1.19v2h2h12.53h2v-2
				v-1.19h10.56v1.19v2h2h12.53h2h1.64h12.53h2v-2v-1.19h10.38v1.19v2h2h12.53h2v-2v-1.19h20.98v1.19v2h2h12.53h2v-2v-1.19h0.36h2
				v-2v-15.38h1.83v-2v-12.4v-2h-1.83v-20.84V889.48L629.01,889.48z'
				/>
			</g>
			<g id='BUILDING-LABEL-12' className={SCSS.BuildingLabel}>
				<rect x='518.06' y='876.29' width='55.42' height='9.64' />
				<text transform='matrix(1 0 0 1 523.4076 883.4219)'>BUILDING 12</text>
			</g>
		</g>
	);
};

export default Building12;
